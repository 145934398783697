import React, { useEffect, useState } from "react";
import { Div, Card, Image } from "qdm-component-library";
import { withStyles, Popover } from "@material-ui/core";
import SelectCard from "./selectCard";
import downArrow from "../../assets/img/svg/Group -1.svg";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import Group90507 from "../../assets/img/svg/Group 90507.svg";
import { dbName, urls, utcTOLocal } from "../../utils";
import { CircularProgress, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { urPK } from "@mui/x-data-grid";

const materialStyles = (theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    // width: "32%",
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});

const PatientCareTypeCard = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [state, setState] = useState({
    open: null,
  });
  const [careList, setcareList] = useState([]);
  const [selectOption, setselectOption] = React.useState({});

  const getfinancialLoading = useSelector(
    (state) => state?.patientDetails?.getfinancial?.loading
  );

  const styles = {
    rowFlex: {
      display: "flex",
      alignItems: "center",
    },
    cardStyle: {
      // boxShadow: "0px 10px 25px #0000000A",
      boxShadow: "none",
      borderRadius: 8,
      marginLeft: "5px",
      // padding: 10,
    },
  };

  const openPopover = (e) => {
    setState({
      open: e?.currentTarget ?? null,
    });
  };

  const closePopover = (e) => {
    setState({
      open: null,
    });
  };

  const handleChange = (val) => {
    setselectOption(val);
    closePopover();
  };

  const getFinCare = async () => {
   
    if(props?.encounterid && !props?.isReferral){
      let payload = {
        db_name: dbName,
        filter: {
          encounterid: props?.encounterid,
          tenantid: "",
          facilityid: "",
        },
        queryid: "626db8f2-5512-4695-b5d5-168d0fc53836",
      }
      let config = {
        method: 'post',
        url: urls.readQdmQueries,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : payload
      };
      axios.request(config)
      .then((response) => {
        let fin_data = response.data;
        if (fin_data) {
          const fin_DATA = fin_data?.map((v) => {
            return {
              pic: Group90507,
              name: null,
              care_type: v?.payer?.longdesc,
              title_name: v?.payertype?.longdesc,
              priority: v?.priority,
              policyexpiry: v?.policyexpiry? utcTOLocal(v?.policyexpiry,"DD-MM-YYYY") : "",
              policy:v?.policyid?.[0]?.plan?.longdesc,
              payer: v?.payer?._id
            };
          });
          let array2 = [];
            if (fin_data?.length > 0) {
              let finDoc = fin_data?.[1]?.documentref;
              if (finDoc?.length > 0) {
                finDoc.map((li) => {
                  array2.push(li);
                });
              }
            }
            
          setselectOption(fin_DATA[0]);
          const findata = fin_DATA//fin_DATA?.reverse();
          setcareList(findata);
        };
        })
      .catch((error) => {
        console.log(error);
      });
    } else {
       const fin_data = await dispatch(
      actions.GET_FINANCIAL({ patientId: location?.state?.patientid || props?.patientId }) 
    );
    const fin_DATA = fin_data?.payload?.data?.map((v) => {
      return {
        pic: Group90507,
        name: null,
        care_type: v?.payer?.longdesc,
        title_name: v?.payertype?.longdesc,
        priority: v?.priority,
        policyexpiry: v?.policyexpiry? utcTOLocal(v?.policyexpiry,"DD-MM-YYYY") : "",
        policy:v?.policyid?.[0]?.plan?.longdesc,
        payer: v?.payer?._id
      };
    });
    setselectOption(fin_DATA[0]);
    const findata = fin_DATA//fin_DATA?.reverse();
    setcareList(findata);
    }
  }

  useEffect(()=>{
    getFinCare()
  },[props?.encounterid])

  let { open } = state;
  const { classes, parent_id, padding } = props;

  return (
    <React.Fragment>
      <Card
        id={`${parent_id}_patienttype_parent_card`}
        style={{
          ...styles.cardStyle,
          cursor: careList?.length > 1 ? "pointer" : "auto",
          height: "100%",
          padding: padding ? 10 : 0,
        }}
        onClick={(e) => (careList?.length > 1 ? openPopover(e) : null)}
      >
        <Div id={`${parent_id}_patienttype_parent_div`} style={styles.rowFlex}>
        {getfinancialLoading ? (
          <Grid container justifyContent="center" alignItems="center" style={{marginTop:"20px"}}>
            <CircularProgress color="primary" size={20} />
          </Grid>
        ) : (
          <>
          {selectOption && (
            <SelectCard
              parent_id={"patient_care"}
              moreopen={true}
              pic={selectOption?.pic}
              title_name={selectOption?.care_type}
              care_type={selectOption?.title_name}
              noOfIteams={careList?.length}
              isDetailed={props?.isDetailed}
              policy={selectOption?.policy}
            />
          )}
          {careList?.length > 1 && (
            <Div
              id={`${parent_id}_patienttype_div`}
              style={{ ...styles.rowFlex, width: 24, height: 24 }}
            >
              <Image
                id={`${parent_id}_patienttype_group_downarrow_image`}
                src={downArrow}
                alt="downArrow"
              />
            </Div>
          )}
          </>
        )}
        </Div>
      </Card>
      <Popover
        anchorEl={open}
        open={Boolean(open)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closePopover}
        classes={{ paper: classes.muiPopovers }}
      >
        <Div id={`${parent_id}_patienttype_title_div`} style={{ padding: 5 }}>
          {careList.map((val, i) => {
            return (
              <Div
                id={`${parent_id}_${i}_patienttype_title_name_div`}
                style={{
                  ...styles.rowFlex,
                  padding: "5px 10px",
                  paddingBottom: 10,
                  cursor: "pointer",
                }}
                onClick={() => handleChange(val)}
              >
                <SelectCard
                  parent_id={"patient_care_" + i}
                  pic={val.pic}
                  title_name={val?.care_type}
                  care_type={val?.title_name}
                  showUpArrow={i === 0 ? true : false}
                  showDivider={i < careList.length - 1 ? true : false}
                  policyexpiry={val?.policyexpiry}
                  priority={val?.priority?.display}
                  policy={val?.policy}
                  isDetailed={props?.isDetailed}
                />
              </Div>
            );
          })}
        </Div>
      </Popover>
    </React.Fragment>
  );
};

PatientCareTypeCard.defaultProps = {
  careList: [],
};

export default withStyles(materialStyles)(PatientCareTypeCard);
