import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Text,
  TextInput,
  TapSelect,
  Div,
  Icons,
} from "qdm-component-library";
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Calendar from "react-calendar";
import { Popover } from "@material-ui/core";
//import { withAllContexts } from "../../../HOCs";
import { AlertProps, __tenantid__, baseURL, dateGiverForFollowUp, getFacilityId, urls } from "../../../utils";

import { UIColor } from "../../../themes/theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createVitalsJson } from "../createJson";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/actions";
import {
  Vitals,
  predialysis,
  intradialysis,
  postdialysis,
  treatmentChart,
} from "../vitalsJson";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useContext } from "react";
import { AlertContext, BackdropContext, DialogContext } from "../../../context";
import Aadddialysistype from "./adddialysistype";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import TreatmentProcedure from "../treatmentProcedureFlow/treatmentProcedure";
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router-dom";
function jsonconverter(data = []) {
  function removeSpaces(str = "") {
    return str.replace(/\s/g, "");
  }
  let result = {};
  let complicationresult = [];
  let objResult = {};
  data.map((v) => { 
    if (v?.groupname === "Complication") {
      let obj = {
        obsinternalid: v?._id,
        obscode: removeSpaces(v?.vitalscode),
        obsvalue: "",
        shortdesc: v?.shortdesc,
        isabnormal: true,
        abnormalmsg: '"Min"',
        type: v?.type,
        arrid: "table",
        obsuom: v?.units_allowed?.[0]?._id,
        time: Math.floor(Date.now() / 1000) % 10000000000,
        dropdownvalues: v?.dropdownvalues,
        groupname: v?.groupname,
        mandatory: v?.mandatory,
        validation_max: v?.VitalsCodesReferenceRanges?.[0]?.validation_max,
        validation_min: v?.VitalsCodesReferenceRanges?.[0]?.validation_min,
        default_unit: v?.default_unit,
      };

      if (obj.obscode === "ACTION") {
        objResult.action = obj;
        //complicationresult.push(obj)
        // result[removeSpaces(v?.vitalscode)]= actionresult
      } else if (obj.obscode === "COMPLICATION") {
        objResult.complication = obj;
        //complicationresult.push(obj)
        //   result[removeSpaces(v?.vitalscode)]= complicationresult
      } else if (obj.obscode === "TIME") {
        objResult.time = obj;
        //complicationresult.push(obj)
        // result[removeSpaces(v?.vitalscode)]= timeresult
      }
    }
    // else if(v?.shortdesc==="Last Visit Date"){
    //   result[removeSpaces(v?.vitalscode)] = {
    //     obsinternalid: v?._id,
    //     obscode: removeSpaces(v?.vitalscode),
    //     obsvalue: " 2024-03-08",
    //     shortdesc: v?.shortdesc,
    //     isabnormal: true,
    //     abnormalmsg: '"Min"',
    //     type: v?.type,
    //     obsuom: v?.units_allowed?.[0]?._id,
    //     time: Date.now(),
    //     dropdownvalues: v?.dropdownvalues,
    //     groupname: v?.groupname,
    //     mandatory: v?.mandatory,
    //     validation_max: v?.VitalsCodesReferenceRanges?.[0]?.validation_max,
    //     validation_min: v?.VitalsCodesReferenceRanges?.[0]?.validation_min,
    //     default_unit: v?.default_unit,
    //   };
    // }
    else {
      result[removeSpaces(v?.vitalscode)] = {
        obsinternalid: v?._id,
        obscode: removeSpaces(v?.vitalscode),
        obsvalue: "",
        shortdesc: v?.shortdesc,
        isabnormal: true,
        abnormalmsg: '"Min"',
        type: v?.type,
        obsuom: v?.units_allowed?.[0]?._id,
        time:  Math.floor(Date.now() / 1000) % 10000000000,
        dropdownvalues: v?.dropdownvalues,
        groupname: v?.groupname,
        mandatory: v?.mandatory,
        validation_max: v?.VitalsCodesReferenceRanges?.[0]?.validation_max,
        validation_min: v?.VitalsCodesReferenceRanges?.[0]?.validation_min,
        default_unit: v?.default_unit,
      };
    }
  });
  if (objResult?.complication?.groupname === "Complication") {
    complicationresult.push(objResult);
    result["COMPLICATION"] = complicationresult;
  }

  return result;
}

const styles = {
  vitalsHead: {
    padding: "6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    paddind: 10,
  },
  vitailsCard: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: 0,
    background: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: "1px solid #0071F2",
    color: "#0071F2",
    background: "#F5FAFF",
    fontSize: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  tapSelectStyle: {
    padding: "4px 8px",
    borderColor: UIColor.lineBorderFill.color,
    margin: "4px 10px 6px 0px",
    borderRadius: "8px",
    minWidth: "auto",
    "& span": {
      fontSize: "12px",
    },
  },
  TapSelectcol: {
    display: "flex",
    margin: "auto 0",
    marginBottom: "12px",
    paddingLeft: "4px",
  },
  CalendarStyle: {
    padding: 5,
    marginBottom: 10,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  CalendarStyleIcon: {
    position: "absolute",
    right: "14px",
    bottom: "8px",
    cursor: "pointer",
  },
};
export const Dialysis = (props) => {   

  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo = userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const facilityId = decodedUserinfo?.facilityid ?? "";
  const practitionerId = decodedUserinfo?.practitionerid ?? "";
  const location = useLocation();

  const dispatch = useDispatch();
  const dialog = useContext(DialogContext);
  const selector = useSelector((state) => state.VitalsApiSlice);
  const encounter = useSelector(
    (state) => state?.chiefCompilenceMastersSlice?.encounter_Details
  );
  const [hdDuration, setHdDuration] = useState({});
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const [dialysisData, setDialysisData] = useState({
      });
  const [isErr, setisErr] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [dialysisDateValue, setDialysisDateValue] = useState("");
  const [lastVisitDateValue, setLastVisitDateValue] = useState("");
  const [hdMachineNo, setHDMachineNo] = useState({});
  const [previousWeight, setpreviousWeight] = useState();
  const [frequency, setFrequency] = useState({});
  const [needle, setneedle] = useState({});
  const [shift, setShift] = useState({});
  const[id,setId]=useState()
  const[dialysisalldata,setdialysisalldata]=useState()
  const[dialysisstate,setdialysisstate]=useState()

  const [arrivedTimeValue, setArrivedTimeValue] = useState("");
  const [startTimeValue, setStartTimeValue] = useState("");
  const [endTimeValue, setEndTimeValue] = useState("");
  const [hepatitis, setHepatitis] = useState({});
  const [qb, setQb] = useState({});
  const [qd, setQd] = useState({});
  const [dialysateCa2, setDialysateCa2] = useState({});
  const [VascularAccess, setVascularAccess] = useState("");
  const [TargetDryWeight,setTargetDryWeight]= useState("");
  const [previous_post_hd_weight,setprevious_post_hd_weight]= useState("");
  const [SurfaceArea,setSurfaceArea]= useState("");
  const [Maintenance,setMaintenance]= useState({});
  const [Loadingval,setLoadingval]= useState({});
  const [DialyzerType,setDialyzerType]= useState({});
  const [Heparin,setHeparin]= useState({});
  const [heparinLoading,setHeparinLoading]= useState({});
  const [qd_baseline,setqd_baseline]=useState("");
  const [qb_baseline,setqb_baseline]=useState("");
  const [qd_firsthour,setqd_firsthour]=useState("");
  const [qb_firsthour,setqb_firsthour]=useState("");
  const [ConductivityFirsthour,setConductivityFirsthour]=useState("");
  const [qd_secondhour,setqd_secondhour]=useState("");
  const [qb_secondhour,setqb_secondhour]=useState("");
  const [ConductivitySecondhour,setConductivitySecondhour]=useState("");
  const [qd_thirdhour,setqd_thirdhour]=useState("");
  const [qb_thirdhour,setqb_thirdhour]=useState("");
  const [ConductivityThirdhour,setConductivityThirdhour]=useState("");
  const [Nursingnotes,setNursingnotes]=useState({})
  const [Complicationtime_baseline,setComplicationtime_baseline]=useState();
  const [Complicationtime_firsthour,setComplicationtime_firsthour]=useState();
  const [Complicationtime_secondhour,setComplicationtime_secondhour]=useState();
  const [Complicationtime_thirdhour,setComplicationtime_thirdhour]=useState()
  const [Complicationtext_baseline,setComplicationtext_baseline]=useState();
  const [Complicationaction_baseline,setComplicationaction_baseline]=useState();
  const [Complicationtext_firsthour,setComplicationtext_firsthour]=useState();
  const [Complicationaction_firsthour,setComplicationaction_firsthour]=useState();
  const [Complicationtext_secondhour,setComplicationtext_secondhour]=useState();
  const [Complicationaction_secondhour,setComplicationaction_secondhour]=useState();
  const [Complicationtext_thirdhour,setComplicationtext_thirdhour]=useState();
  const [Complicationaction_thirdhour,setComplicationaction_thirdhour]=useState();



  const [dailogComp, setDailogComp] = useState(<></>);
  var predialysisJSON = jsonconverter(predialysis?.result ?? []);
  var intradialysisJSON = jsonconverter(
    intradialysis?.result ? intradialysis?.result : []
  );
  var postdialysisJSON = jsonconverter(
    postdialysis?.result ? postdialysis?.result : []
  );
  var treatmentChartJSON = jsonconverter(
    treatmentChart?.result ? treatmentChart?.result : []
  );
  async function insertDialysisWithEncouterid(dialysisDatafromsave,orderlineData,ids,dialysisdataall) {
    backdrop.setBackDrop({
      ...backdrop,
      message: "inserting dialysis",
      open: true,
    });
    let data = JSON.parse(JSON.stringify(dialysisdataall ?? {}));
    if (data?._key) {
      data.component[0].predialysis = dialysisDatafromsave.predialysis;
      data.component[0].postdialysis = dialysisDatafromsave.postdialysis;
      data.component[0].intradialysis = dialysisDatafromsave.intradialysis;
      data.component[0].treatmentChart = dialysisDatafromsave.treatmentChart;
    } else {
      data = {
        facilityid:facilityId,
        orgid: facilityId,
        patientid: location?.state?.patientId ?? props?.patientgetData?.Patient?.[0]?._id,
        confirmedby: "",//Person/18359 //props?.patientgetData?.Patient?.[0]?.personInfo?.[0]?._id,
        encountertype: props?.patientgetData?.type ?? props?.patientgetData?.type?.longDesc,
        encounterid: props?.patientgetData?.encounterId ?? props?.patientgetData?._id,
        orderlineid: orderlineData,
        component: [
          {
            predialysis: dialysisDatafromsave?.predialysis ?? [],
            postdialysis: dialysisDatafromsave?.postdialysis ?? [],
            intradialysis: dialysisDatafromsave?.intradialysis ?? [],
            treatmentChart: dialysisDatafromsave?.treatmentChart ?? [],
          },
        ],
      };
    }
    let dataforupsert = {
      db_name: process.env.REACT_APP_DB,
      entity: "CA_DialysisVitalsSigns",
      filter:  {
        _id: ids,
      },
      is_metadata: true,
      metadataId: process.env.REACT_APP_METADATAID,
      metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      doc: {
        tenantid: process.env.REACT_APP_TENANTID,  
        ...data,
      },
    };

   const resp =  await dispatch(actions.UPSERT_DIALYSIS([dataforupsert]));
    props.getDialysisData(props?.patientgetData?.encounterId,orderlineData);
    //props?.readDialysisWithEncouterid(props?.patientgetData?._id,orderlineData);
    backdrop.setBackDrop({
      ...backdrop,
      message: "",
      open: false,
    });
    if(resp?.payload?.data?.statusCode === 201){
      return true 
    } else {
      return false
    }
  }
  // useEffect(() => {
  //   let data = props?.dialysisData?.component?.[0];
  //   setDialysisData({
  //     predialysis: data?.predialysis ?? [],
  //     intradialysis: data?.intradialysis ?? [],
  //     postdialysis: data?.postdialysis ?? [],
  //     treatmentChart: data?.treatmentChart ?? [],
  //   });
  // }, [props?.dialysisData]);
   function epochToDate(epoch) {
    const date = new Date(epoch * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const loadTreatmentChartDefaultValues = async () => {

    let payload = {
      screenid: process.env.REACT_APP_TREATMENT_CHART_SCREEN_ID,
      tenantid: __tenantid__,
      filtervalues: {
        encounterid: props?.patientgetData?.encounterId||props?.location?.state?.encounterId ||props?.location?.state?.encounter_id,
        patientid: location?.state?.patientId || props?.patientgetData?.Patient?.[0]?._id||props?.location?.state?.patientId,
        practitionerid: practitionerId,
      },
    };
    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    }).then((res) => {
      if (res?.data?.response?.screenId === process.env.REACT_APP_TREATMENT_CHART_SCREEN_ID) {
        const defaultValues =  res?.data?.response?.defaultValues;
        const stateNames = [
          "DialysisDate",
          "HDMachineNo",
          "previous_post_hd_weight",
          "LastVisitDate",
          "shift",
          "frequency",
          "arrived_time",
          "end_time",
          "start_time",
          "hd_duration",
          "needleSize",
          "hepatitis",
          "qd",
          "qb",
          "dialysate",
          "vascular_access",
          "TargetDryWeight",
          "previous_post_hd_weight",
          "SurfaceArea",
          "Maintenance",
          "Loading",
          "Heparin",
          "HeparinLoading",
          "DialyzerType",
          "qd_baseline",
          "Conductivity_firsthour",
          "qd_firsthour",
          "qd_secondhour",
          "Conductivity_secondhour",
          "qd_thirdhour",
          "Conductivity_thirdhour",
          "Nursingnotes",
          "Complicationtime_thirdhour",
          "Complicationtime_secondhour",
          "Complicationtime_firsthour",
          "Complicationtime_baseline",
          "Complicationtext_baseline",
          "Complicationaction_baseline",
          "Complicationtext_firsthour",
          "Complicationaction_firsthour",
          "Complicationtext_secondhour",
          "Complicationaction_secondhour",
          "Complicationtext_thirdhour",
          "Complicationaction_thirdhour",

        ];
        const stateValues = {};
        defaultValues.forEach((s) => {
          if (stateNames.includes(s.stateName)) {
            stateValues[s.stateName] = s.value;
          }
        }); 
        setArrivedTimeValue(stateValues?.arrived_time);
        setVascularAccess(stateValues?.vascular_access)
        setStartTimeValue(stateValues?.start_time);
        setEndTimeValue(stateValues?.end_time);
        setHdDuration(stateValues?.hd_duration)
        let stateval = stateValues?.LastVisitDate;
        let LastVisitDates = !stateval ? "" :epochToDate(stateValues?.LastVisitDate)??"";
        let DialysisDates =  epochToDate(stateValues?.DialysisDate)??"";
        setDialysisDateValue(DialysisDates)
        setLastVisitDateValue(LastVisitDates)
        setHDMachineNo(
          {
            _id:stateValues?.HDMachineNo?._id,
            value:stateValues?.HDMachineNo?.value
          }
        );
        setpreviousWeight(stateValues?.previous_post_hd_weight);
        setFrequency({
            _id:stateValues?.frequency?._id,
            value:stateValues?.frequency?.value
        })
        setShift({
            _id:stateValues?.shift?._id,
            value:stateValues?.shift?.value
        })
        setneedle({
            _id:stateValues?.needleSize?._id,
            value:stateValues?.needleSize?.value
        })
        setHepatitis(stateValues?.hepatitis)
        setQb(stateValues?.qb)
        setQd(stateValues?.qd)
        setDialysateCa2({
            _id:stateValues?.dialysate?._id,
            value:stateValues?.dialysate?.value
        })
        setLoadingval({
          _id:stateValues?.Loading?._id,
          value:stateValues?.Loading?.value
        })
        setMaintenance({
          _id:stateValues?.Maintenance?._id,
          value:stateValues?.Maintenance?.value
        })
        setSurfaceArea(stateValues?.SurfaceArea)
        setprevious_post_hd_weight(stateValues?.previous_post_hd_weight)
        setTargetDryWeight(stateValues?.TargetDryWeight)
        setDialyzerType({
          _id:stateValues?.DialyzerType?._id,
          value:stateValues?.DialyzerType?.value
        })
        setHeparin({ 
          _id:stateValues?.Heparin?._id,
          value:stateValues?.Heparin?.value
        })
        setHeparinLoading({ 
          _id:stateValues?.HeparinLoading?._id,
          value:stateValues?.HeparinLoading?.value
        })
        setqd_baseline(stateValues?.qd_baseline)
        setqb_baseline(stateValues?.qb)
        setqd_firsthour(stateValues?.qd_firsthour)
        setqb_firsthour(stateValues?.qb)
        setConductivityFirsthour(stateValues?.Conductivity_firsthour)
        setqd_secondhour(stateValues?.qd_secondhour)
        setqb_secondhour(stateValues?.qb)
        setConductivitySecondhour(stateValues?.Conductivity_secondhour)
        setqd_thirdhour(stateValues?.qd_thirdhour)
        setqb_thirdhour(stateValues?.qb)
        setConductivityThirdhour(stateValues?.Conductivity_thirdhour)
        setNursingnotes({ 
          _id:stateValues?.Nursingnotes?._id,
          value:stateValues?.Nursingnotes?.value
        })
          let datebase = new Date( stateValues?.Complicationtime_baseline * 1000);
          const hoursbaseline = datebase.getHours().toString().padStart(2, '0'); 
          const minutesbaseline = datebase.getMinutes().toString().padStart(2, '0');
          let timebaseline = `${hoursbaseline}:${minutesbaseline}`;
          setComplicationtime_baseline(timebaseline)
          let datefirst = new Date( stateValues?.Complicationtime_firsthour * 1000);
          const hoursfirst = datefirst.getHours().toString().padStart(2, '0');
          const minutesfirst = datefirst.getMinutes().toString().padStart(2, '0');
          let timefirst = `${hoursfirst}:${minutesfirst}`;
          setComplicationtime_firsthour(timefirst)
          let datesecond = new Date( stateValues?.Complicationtime_secondhour * 1000);
          const hourssecond = datesecond.getHours().toString().padStart(2, '0');
          const minutessecond = datesecond.getMinutes().toString().padStart(2, '0');
          let timesecond = `${hourssecond}:${minutessecond}`;
          setComplicationtime_secondhour(timesecond)
          let datethird = new Date( stateValues?.Complicationtime_thirdhour * 1000);
          const hoursthird = datethird.getHours().toString().padStart(2, '0');
          const minutesthird = datethird.getMinutes().toString().padStart(2, '0');
          let timethird = `${hoursthird}:${minutesthird}`;
          setComplicationtime_thirdhour(timethird)
          setComplicationtext_baseline(stateValues?.Complicationtext_baseline)
          setComplicationaction_baseline(stateValues?.Complicationaction_baseline)
          setComplicationtext_firsthour(stateValues?.Complicationtext_firsthour)
          setComplicationaction_firsthour(stateValues?.Complicationaction_firsthour)
          setComplicationtext_secondhour(stateValues?.Complicationtext_secondhour)
          setComplicationaction_secondhour(stateValues?.Complicationaction_secondhour)
          setComplicationtext_thirdhour(stateValues?.Complicationtext_thirdhour)
          setComplicationaction_thirdhour(stateValues?.Complicationaction_thirdhour)

      }
    }).catch((error) => {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please contact to admin",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    });
  };
  useEffect(() => {
    // let data = JSON.parse(localStorage.getItem("dialysis"));
    // if (Object.keys(data ?? {})?.length > 0) {
    //   setDialysisData(data);
    // }
    loadTreatmentChartDefaultValues();
  }, []);

  const handleDialog = (json, statekey, edit = false, editstate, intratype,orderlineData,_id,dialysisdataa,historyView) => {

    let vitalJSON = json;
    if (intratype) {
      vitalJSON.Type.obsvalue = intratype;
    }
    setOpen(true);
    // setDialysisData(dialysisData);
    let data = dialysisdataa?.component?.[0];
    let a;
    if(dialysisdataa===undefined){
       a = {
        predialysis: [],
        intradialysis: [],
        postdialysis: [],
        treatmentChart: [],
      }
    }else{
      setdialysisalldata(dialysisdataa);
      // setDialysisData({
      //   predialysis: data?.predialysis ?? [],
      //   intradialysis: data?.intradialysis ?? [],
      //   postdialysis: data?.postdialysis ?? [],
      //   treatmentChart: data?.treatmentChart ?? [],
      // });
    }
   
    setDailogComp(
      <Aadddialysistype
        encounterId={props?.patientgetData?.encounterId}
        data={vitalJSON}
        handlesave={handlesave}
        statekey={statekey}
        handlecancel={handleClose}
        edit={edit}
        editstate={editstate}
        handleEdit={handleEdit}
        isErr={isErr}
        errorField={errorField}
        setErrorFields={setErrorFields}
        practid={props?.patientgetData?.practitioner_details?.[0]?.resouceid}
        intratype={intratype}
        dialysisDateValue={dialysisDateValue}
        lastVisitDateValue={lastVisitDateValue}
        hdMachineNo={hdMachineNo}
        previousWeight={previousWeight}
        frequency={frequency}
        shift={shift}
        orderlineData={orderlineData}
        _id={_id} 
        dialysisData={dialysisdataa}
        historyView={historyView}
        dialysisdataall={dialysisdataa?.component?.[0] ? dialysisdataa : a}
        arrivedTimeValue={arrivedTimeValue}
        startTimeValue={startTimeValue}
        endTimeValue={endTimeValue}
        VascularAccess={VascularAccess}
        hdDuration={hdDuration}
        needle={needle}
        hepatitis={hepatitis}
        qb={qb}
        qd={qd}
        dialysateCa2={dialysateCa2}
        Loadingval={Loadingval}
        Maintenance={Maintenance}
        previous_post_hd_weight={previous_post_hd_weight}
        SurfaceArea={SurfaceArea}
        TargetDryWeight={TargetDryWeight}
        Heparin={Heparin}
        heparinLoading={heparinLoading}
        DialyzerType={DialyzerType}
        qd_baseline={qd_baseline}
        qb_baseline={qb_baseline}
        qd_firsthour={qd_firsthour}
        qb_firsthour={qb_firsthour}
        ConductivityFirsthour={ConductivityFirsthour}
        ConductivitySecondhour={ConductivitySecondhour}
        qb_secondhour={qb_secondhour}  
        qd_secondhour={qd_secondhour}
        ConductivityThirdhour={ConductivityThirdhour}
        qb_thirdhour={qb_thirdhour}
        qd_thirdhour={qd_thirdhour}
        Nursingnotes={Nursingnotes}
        Complicationtime_thirdhour={Complicationtime_thirdhour}
        Complicationtime_secondhour={Complicationtime_secondhour}
        Complicationtime_firsthour={Complicationtime_firsthour}
        Complicationtime_baseline={Complicationtime_baseline}
        Complicationaction_thirdhour={Complicationaction_thirdhour}
        Complicationtext_thirdhour={Complicationtext_thirdhour}
        Complicationaction_secondhour={Complicationaction_secondhour}
        Complicationtext_secondhour={Complicationtext_secondhour}
        Complicationaction_firsthour={Complicationaction_firsthour}
        Complicationtext_firsthour={Complicationtext_firsthour}
        Complicationaction_baseline={Complicationaction_baseline}
        Complicationtext_baseline={Complicationtext_baseline}
      />
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [errorField, setErrorFields] = React.useState({});

  const handlesave = async (statekey, value,orderlineData,id,dialysisdataall) => {
    try {
      value = { ...value, id: { id: uuidv4() } };
      let data = JSON.parse(JSON.stringify(dialysisdataall?.component?.[0] ? dialysisdataall?.component?.[0] : dialysisdataall));//dialysisdataa?.component?.[0]
      data[statekey].push(value);
      setOpen(false);
      let resp = await insertDialysisWithEncouterid(data,orderlineData,id,dialysisdataall);
      // setDialysisData({
      //   ...dialysisData,
      //   [statekey]: data[statekey],
      // })
      if (resp){
        await loadTreatmentChartDefaultValues()
      }
      setOpen(false);
      props?.setOrderlineId(orderlineData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async (statekey, value,orderlineData,id,dialysisdataall) => {
    try {
      let data = JSON.parse(JSON.stringify((dialysisdataall?.component?.[0] ? dialysisdataall?.component?.[0] : dialysisdataall)));
      let index = data[statekey]?.findIndex((v) => {
        return v?.id?.id === value?.id?.id;
      });
      data[statekey][index] = value;
      setOpen(false);
      let resp = await insertDialysisWithEncouterid(data,orderlineData,id,dialysisdataall);

      // setDialysisData({
      //   ...dialysisData,
      //   [statekey]: data[statekey],
      // });
      //localStorage.setItem("dialysis", JSON.stringify(data));
      if (resp){
        await loadTreatmentChartDefaultValues()
      }
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <TreatmentProcedure
          setTreatmentplan={props?.setTreatmentplan}
          treatmentPlan={props?.treatmentPlan}
          saveTreatment={props?.saveTreatment}
          handleDialog={handleDialog}
          treatmentChartJSON={treatmentChartJSON}
          predialysisJSON={predialysisJSON}
          intradialysisJSON={intradialysisJSON}
          postdialysisJSON={postdialysisJSON}
          dialysisData={props?.dialysisData}
          location={props?.location}
          patientid= {location?.state?.patientId}
          encounterid= {props?.patientgetData?.encounterId}
          setOrderlineId={props?.setOrderlineId}
          setload={true}
          patientgetData={props?.patientgetData}
          settreatmentprocedure={props?.settreatmentprocedure}
          />
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {dailogComp}
      </Dialog>
    </>
  );
};