import * as React from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@mui/material/Button";
import {
  Typography,
  IconButton,
  DialogContent,
  Paper,
  Grid,
  TextField,
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  Divider,
  InputAdornment,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import theme from "../../../utils/theme";
import PrintIcon from "@mui/icons-material/Print";
import { Padding, Print } from "@mui/icons-material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import IntraDialysisTable from "../intraDialysisTable";
import { AlertProps, __tenantid__, dbName, fetchData, getFacilityId, personRequests, urls } from "../../../utils";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import { useSelector } from "react-redux";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { duration } from "moment";
import TreatmentChart from "./treatmentchart";
import Predialysis from "./predialysis";
import { AlertContext } from "../../../context";

// Schema validation using Yup
const bpSchema = yup
  .string()
  .required('Blood pressure is required')
  .test('valid-bp', 'Invalid BP format. Ensure format is 90/120 and values are between 40 and 250', value => {
    if (!value) return false;

    // Remove all non-numeric and non-slash characters
    value = value.replace(/[^0-9/]/g, '');

    // Count slashes
    const numberOfSlashes = (value.match(/\//g) || []).length;

    // Ensure exactly one slash
    if (numberOfSlashes !== 1) return false;

    // Split the value by slash
    const parts = value.split('/');

    // Ensure exactly one slash
    if (parts.length !== 2) return false;

    // Check if each part is a valid number within the range
    const [value1, value2] = parts.map(Number);
    const isValidRange = (val) => val >= 40 && val <= 250;
    return isValidRange(value1) && isValidRange(value2);
  });

const schema = yup.object().shape({
  BPSit: bpSchema,
  BPStnd: bpSchema,
  EndedBySN: yup.string().required('EndedBySN is required'),
  EndTime: yup.string().required('EndTime is required'),
  //id: yup.string().required('ID is required'),
  Ktv: yup.number().required('Ktv is required').typeError('It must be a number'),
  NN: yup.mixed().required('NN is required'),
  PHDW: yup.number().required('PHDW is required').typeError('It must be a number'),
  PULSESIT: yup
    .number()
    .required('Pulse sitting is required')
    .min(50, 'Pulse should be between 50 and 250')
    .max(250, 'Pulse should be between 50 and 250')
    .typeError('It must be a number'),
  PULSESTND: yup
    .number()
    .required('Pulse standing is required')
    .min(50, 'Pulse should be between 50 and 250')
    .max(250, 'Pulse should be between 50 and 250')
    .typeError('It must be a number'),
  StaffID: yup.mixed().required('Staff ID is required'),
  TEMP: yup
    .number()
    .required('Temperature is required')
    .min(35, 'Temperature should be between 35 and 42')
    .max(42, 'Temperature should be between 35 and 42')
    .typeError('It must be a number'),
  TWL: yup.number().required('TWL is required').typeError('It must be a number'),
});

const Postdialysis = (props) => {
  const alertContext = React.useContext(AlertContext);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors},
    setValue,
    control,
    getValues,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      BPSit: "",
      BPStnd: "",
      EndedBySN: "",
      EndTime: "",
      //id: yup.string().required('ID is required'),
      Ktv: null,
      NN: "",
      PHDW: null,
      PULSESIT: null,
      PULSESTND: null,
      TEMP: null,
    }
  });
  // const [open, setopen] = React.useState(true);
  const [DropDownVal, setDropDownVal] = React.useState([]);
  const onSubmit = data => {
    alertContext.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "Postdialysis Saved",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
    console.log(data,"onsubmit");
    props?.setPostDialysis(data)
    props?.setisMasterSave(true)
    // props?.setisPostDialysis(true)
    // setopen(true);
  };

  const dropdown = useSelector(
    (state) =>
    state?.dialysis?.shiftTypeDropdown ?? []
);

  React.useEffect(() => {
    if(props?.PostDialysis){
        Object.keys(props?.PostDialysis).forEach(key => {
            setValue(key, props?.PostDialysis[key]);
          });
    } else {
        if (props?.defaultValues) {
          const data = {
            EndTime: props?.defaultValues?.end_time?.value,
            EndedBySN: props?.defaultValues?.Endedby_sn,
          };
          Object.keys(data).forEach(key => {
            setValue(key, data[key]);
          });
        }
    }
  }, [props?.defaultValues,props?.PostDialysis]);
  React.useEffect(() => {
    const getDropDownValues = async () => {
        const payload = {
          db_name: dbName,
          filter: {
            type: [
              "NURSINGNOTES",
            ],
            tenantid: "1234",
            facilityid: "",
            lang: "en",
          },
          queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
        };
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: urls?.readQdmQueries,
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };
        axios.request(config)
        .then((response) => {
          const transformedData = response?.data?.reduce((acc, curr) => {
            const key = Object.keys(curr)[0];
            acc[key] = curr[key]; 
            return acc;
          }, {});
          console.log(transformedData,"transformedData()()()")
          setDropDownVal(transformedData)
        })
        .catch((error) => {
          console.log(error);
        });
      };
      getDropDownValues();
  }, []);

  const getTotalWeightLoss = (value) => {
    setValue('TWL',props?.PrevHDWeight-getValues('PHDW'),{shouldDirty:true,shouldTouch:true});
    trigger('TWL');
  };

  console.log(errors,"errors")

  return (
    <Paper
    sx={{
      padding: "10px",
      margin: "20px",
      backgroundColor: theme.palette.primary.light,
    }}
  >
    <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 6 }}
          margin={1}
        >
          <Grid item xs={2.4} marginLeft={2}>
            <Typography variant="h6">POST DIALYSIS</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Controller
              name="EndTime"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="End Time"
                  variant="outlined"
                  size="small"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: 180, backgroundColor: "#FFFFFF" }}
                  error={!!errors.EndTime}
                  helperText={
                    errors?.EndTime?.type === "required" || errors?.EndTime?.type === "optionality" ? "" : errors?.EndTime?.message
                  }
                  // disabled={open}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="EndedBySN"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="End By SN"
                  variant="outlined"
                  size="small"
                  sx={{ width: 300, backgroundColor: "#FFFFFF" }}
                  InputLabelProps={{shrink:getValues('EndedBySN')?true:false}}
                  error={!!errors.EndedBySN}
                  helperText={
                    errors?.EndedBySN?.type === "required" || errors?.EndedBySN?.type === "optionality" ? "" : errors?.EndedBySN?.message
                  }
                  // disabled={open}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                padding: "10px",
                margin: "10px",
              }}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 1, md: 6 }}
                spacing={{ xs: 6, sm: 1, md: 6 }}
                margin={1}
              >
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="TEMP"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-basic"
                        label="Temperature"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">°C</InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: getValues('TEMP') ? true : false }}
                        fullWidth
                        error={!!errors.TEMP}
                        helperText={
                          errors?.TEMP?.type === "required" || errors?.TEMP?.type === "optionality" ? "" : errors?.TEMP?.message
                        }
                        // disabled={open}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="BPStnd"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-basic"
                        label="Blood Pressure (Standing)"
                        variant="outlined"
                        size="small"
                        placeholder="Eg: 80/120"
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mmHg</InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={!!errors.BPStnd}
                        helperText={
                          errors?.BPStnd?.type === "required" || errors?.BPStnd?.type === "optionality" ? "" : errors?.BPStnd?.message
                        }
                        // disabled={open}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="BPSit"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-basic"
                        label="Blood Pressure (Sitting)"
                        variant="outlined"
                        size="small"
                        placeholder="Eg: 80/120"
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mmHg</InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={!!errors.BPSit}
                        helperText={
                          errors?.BPSit?.type === "required" || errors?.BPSit?.type === "optionality" ? "" : errors?.BPSit?.message
                        }
                        // disabled={open}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="PULSESTND"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-basic"
                        label="Pulse (Standing)"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Bpm</InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: getValues('BPStnd') ? true : false }}
                        fullWidth
                        error={!!errors.PULSESTND}
                        helperText={
                          errors?.PULSESTND?.type === "required" || errors?.PULSESTND?.type === "optionality" ? "" : errors?.PULSESTND?.message
                        }
                        // disabled={open}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="PULSESIT"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-basic"
                        label="Pulse (Sitting)"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Bpm</InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: getValues('BPSit') ? true : false }}
                        fullWidth
                        error={!!errors.PULSESIT}
                        helperText={
                          errors?.PULSESIT?.type === "required" || errors?.PULSESIT?.type === "optionality" ? "" : errors?.PULSESIT?.message
                        }
                        // disabled={open}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="PHDW"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-basic"
                        label="Post HD Weight"
                        variant="outlined"
                        size="small"
                          onBlurCapture={()=>getTotalWeightLoss()}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: getValues('PHDW') ? true : false }}
                        fullWidth
                        error={!!errors.PHDW}
                        helperText={
                          errors?.PHDW?.type === "required" || errors?.PHDW?.type === "optionality" ? "" : errors?.PHDW?.message
                        }
                        // disabled={open}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="TWL"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-basic"
                        label="Total Weight Loss"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                        InputLabelProps={{shrink:getValues('TWL')?true:false}}
                        fullWidth
                        error={!!errors.TWL}
                        helperText={
                          errors?.TWL?.type === "required" || errors?.TWL?.type === "optionality" ? "" : errors?.TWL?.message
                        }
                        disabled={true}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="Ktv"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-basic"
                        label="K/tv"
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={!!errors.Ktv}
                        InputLabelProps={{ shrink: getValues('Ktv') ? true : false }}
                        helperText={
                          errors?.Ktv?.type === "required" || errors?.Ktv?.type === "optionality" ? "" : errors?.Ktv?.message
                        }
                        // disabled={open}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                 <Controller
  name="NN"
  control={control}
  render={({ field }) => (
    <Autocomplete
      {...field}
      disablePortal
      id="nursing_notes"
      options={DropDownVal?.NURSINGNOTES?.Value || []}
      size="small"
      fullWidth
      getOptionLabel={v => v?.name || v?.display || v?.value || v}
      value={field.value ? field.value : null} // Set the value correctly
      onChange={(_, data) => field.onChange(data?.name || data?.display || data?.value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Nursing Notes"
          error={!!errors.NN}
          helperText={
            errors?.NN?.type === "required" || errors?.NN?.type === "optionality" ? "" : errors?.NN?.message
          }
        />
      )}
    />
  )}
/>

                </Grid>
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="StaffID"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        size="small"
                        id="staffId"
                        options={props?.striptestList || []}
                        isOptionEqualToValue={(Option, value) =>
                          Option?._id === value?._id
                        }
                        getOptionLabel={(v) => v?.name || v?.display || v?.value || v}
                        fullWidth
                        value={field.value !== undefined?._id ? field.value : null}
                        onChange={(_, data) => field.onChange(data?.name || data?.display || data?.value)}
                        // disabled={open}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Staff Id"
                            error={!!errors.StaffID}
                            helperText={
                              errors?.StaffID?.type === "required" || errors?.StaffID?.type === "optionality" ? "" : errors?.StaffID?.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          gap={2}
          style={{ marginRight: "10px" }}
        >
          {/* {open ? (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setopen(false);
                //   props?.setisMasterSave(false);
                }}
              >
                Open
              </Button>
            </Grid>
          ) : ( */}
            <>
              <Grid item>
                <Button variant="outlined" onClick={() => {reset();
                }}>
                  Clear
                </Button>
              </Grid>
              {/* <Grid item>
                <Button variant="outlined" onClick={() => setopen(true)} // disabled={open}>
                  Cancel
                </Button>
              </Grid> */}
              <Grid item>
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </Grid>
            </>
          {/* )} */}
        </Grid>
    </form>
  </Paper>
  );
};

export default Postdialysis;
