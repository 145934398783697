/**
 * @author PRAKASH P
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-08-17
 * @desc LAB ORDER COMPONENT
 */
import React, { useEffect, useState } from "react";
import { Row, Col, Text, Div, Image, Icons, Divider } from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  TextField,
  Typography,
  Button,
  Tooltip,
  InputAdornment,
  Switch,
  makeStyles,
  Popover,
  Chip,
  Grid,
  IconButton
} from "@material-ui/core";
import { CalendarIcon, Recurrent } from "../../../components";
import { LabOrderList } from "./labOrderList";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import { getCurrentMinTime, getData, getUserInfo, AlertProps, formatDateFromEpoch, __tenantid__, getUtcTime, utcTOLocal, LabOrder_Json_Generator, getReccuranceDates, getFacilityId, getTenantId } from "../../../utils";
import { UIColor } from "../../../themes/theme";
import { AlertContext } from "../../../context";
import withAllContexts from "../../../hoc/withAllContexts";
import Tick from "../../../assets/img/icons8-double-tick.svg";
import Addicon from "../../../assets/img/icons8-add.svg";
import AlertsRestriction from "../alertsRestriction";
import CustomDatePicker from "../../../components/customDatePicker";
import { setHours, setMinutes } from "date-fns";
import AddResult from "./addResult";
import backIcon from "../../../assets/img/svg/icons8-left.svg";
import HistoryAccordion from "../historyAccordion";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import icons8eye from "../../../assets/img/svg/icons8-eye.svg";
import printIcon from "../../../assets/img/svg/print-icon-noframe.svg"
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";


//import { AlertProps } from "../../../utils";

const useStyles = makeStyles({
  // autocomplete: {
  //   width: 120,
  //   "& input": {
  //     fontFamily: "pc_regular",
  //     fontSize: 14,
  //     color: UIColor.secondaryText.color,
  //     "&::placeholder": {
  //       fontSize: 14,
  //       fontFamily: "pc_regular !important",
  //     },
  //   },
  // },
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
  input: {
    fontFamily: "pc_regular",
    color: UIColor.secondaryText.color,
    fontSize: 14,
    "&::placeholder": {
      fontSize: 14,
      fontFamily: "pc_regular !important",
    },
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  switch: {
    "& .Mui-checked": {
      color: "#fff",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
  closeButton: {
    position: 'absolute',
    right: "8px",
    top: "8px",
    color: UIColor.primaryColor.color,
  },
});

const styles = {
  vitalsHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "0px 0px 0px 10px",
  },
  vitalsHead1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "10px 10px 12px 20px",
  },
  vitalsfieldHead: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
  },
  vitailsCard: {
    //border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    //marginBottom: "14px !important",
    backgroundColor: "#fff",
  },
  vitailsCarderr: {
    fontSize: "12px",
    textAlign: "right",
    justifyContent: "right",
    float: "right",
    color: UIColor.error.color,
    marginBottom: "24px !important",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  infoBell: {
    width: "50px",
    display: "flex",
    padding: "12px",
    borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
  },
  icons: {
    cursor: "pointer",
  },
  flexFlow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  floatingLable: {
    backgroundImage: `linear-gradient(#fff, ${UIColor.greyBackground.color})`,
    position: "absolute",
    top: "-8px",
    fontSize: 10,
  },
  filledBtn: {
    fontSize: 12,
    margin: 0,
    cursor: "pointer",
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    borderRadius: 8,
    color: "#fff",
    padding: "4px 10px"
  },
  cancelBtn: {
    fontSize: 12,
    margin: 0,
    cursor: "pointer",
    marginRight: "15px",
    padding: "8px",
    borderRadius: 8,
    border: `1px solid ${UIColor.primaryColor.color}`,
    color: UIColor.primaryColor.color,
    padding: "4px 10px"
  },
  eye: {
    cursor: "pointer",
    marginRight: "10px"
  },
  uploadIcon: {
    // width: "24px",
    // height: "24px",
    //border: "1px solid #E0E0E0",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 10px 0px auto",
    cursor: "pointer"
  },
  img: {
    width: "16px",
    height: "16px",
  },
  // flexRow: {
  //   display: "flex",
  //   alignItems: "center",
  // },
  editAllergyIcon: {
    padding: "6px 10px",
    background: UIColor.greyBackground.color,
    borderRadius: "50px",
    cursor: "pointer",
  },
  
};

// let BodyData = [
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12321",
//     "ordercatlogid": "OrderCatalog/10693",
//     "ordercatlogname": "Full Blood Picture",
//     "parentordercatid": "OrderCatalog/10029",
//     "parentordercatname": "17 Hydroxy Progesterone",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   },
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12321",
//     "ordercatlogid": "OrderCatalog/10694",
//     "ordercatlogname": "Total RBC",
//     "parentordercatid": "OrderCatalog/10693",
//     "parentordercatname": "Full Blood Picture",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   },
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12321",
//     "ordercatlogid": "OrderCatalog/10695",
//     "ordercatlogname": "Total WBC",
//     "parentordercatid": "OrderCatalog/10693",
//     "parentordercatname": "Full Blood Picture",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   },
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12321",
//     "ordercatlogid": "OrderCatalog/10696",
//     "ordercatlogname": "Haemoglobin(hb)",
//     "parentordercatid": "OrderCatalog/10693",
//     "parentordercatname": "Full Blood Picture",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   },
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12322",
//     "ordercatlogid": "OrderCatalog/10697",
//     "ordercatlogname": "Lymphocytes",
//     "parentordercatid": "OrderCatalog/10029",
//     "parentordercatname": "17 Hydroxy Progesterone",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   },
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12323",
//     "ordercatlogid": "OrderCatalog/10698",
//     "ordercatlogname": "Monocytes",
//     "parentordercatid": "OrderCatalog/10029",
//     "parentordercatname": "17 Hydroxy Progesterone",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   }
// ]
const LabOrder = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const info = getUserInfo();
  const location = useLocation();

  // Lab Result
  const [tableHeader, settableHeader] = React.useState([
    // {
    //   field: 'id',
    //   headerName: 'SNo',
    //   width: 50,
    //   align: "center",
      
    // },
    {
      field: 'Test',
      headerName: 'Test',
      width: 250,
      editable: false,
      align: "left",
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'Result',
      headerName: 'Result',
      width: 125,
      editable: true,
      type: 'number',
      align: "center",
      headerAlign:"center",
      headerClassName: 'super-app-theme--header',
  
    },
    {
      field: 'Unit',
      headerName: 'Unit',
      width: 125,
      editable: false,
      align: "center",
      headerAlign:"center",
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'NormalRange',
      headerName: 'Normal Range',
      width: 200,
      editable: false,
      align: "center",
      headerAlign:"center",
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'Notes',
      headerName: 'Notes',
      width: 200,
      editable: true,
      align: "left",
      headerClassName: 'super-app-theme--header',
    },
  ]);
  const [tableBody, settableBody] = React.useState([]);
  const [compData, setcompData] = React.useState([]);
  const[BodyData , setBodyData] = React.useState([]);
  const [Resulted, setResulted] = React.useState(false);
  const [labResult, setlabResult] = React.useState([]);
  const [Result, setResult] = React.useState(false);
  const [buttonShow, setButtonShow] = useState(true);
  const [editId, setEditId] = useState("")
  
  //pagination for history
  const [currentPage, setCurrentPage] = useState(1);
  const [historyResult, setHistoryResult] = React.useState(false);
  const[labHistory , setLabHistory] = React.useState([]);

  // Assuming treatmentPlanHistory and pageSize are defined somewhere in your component's scope
  const pageSize = 2; // Number of items per page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleItems = labHistory.slice(startIndex, endIndex);
  const [currentCurPage, setCurrentCurPage] = useState(1);


  const [list, setList] = React.useState([])
  const [isHovered, setIsHovered] = useState(false);
  const storeData = useSelector((state) => state?.LabOrderApiSlice);
  const [alertData, setAlertData] = useState({
    open: false,
    data: null,
  });
  const [labOrderEntry, setlabOrderEntry] = useState({
    orderCatalog: {},
    priority: {},
    nature: {},
    dateTime: "",
    headerShow: true,
    recurrent: false,
    recurrentData: {
      every: {
        value: "",
        periodicity: {},
      },
      startsOn: {
        dateTime: "",
      },
      endsAfter: {
        endsAfter: "",
        endsBy: "",
      },
      preferrence: {
        preferredDays: [],
        preferredWeeks: [],
      }
    },
    orders: [],
    error: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [index, setIndex] = useState(null);

  const searchPractioner = useSelector(
    (state) => state?.personSlice?.searchPractitioner
  );

  const labOrderReadLoading = useSelector(
    (state) => state?.LabOrderApiSlice?.readorder?.loading
  );

  const handleHover = () => setIsHovered(true);
  const handleLeave = () => setIsHovered(false);
  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update current page when page is changed
  };

  //pagination for Current
  
  const handleCurrentPageChange = (event, value) => {
    setCurrentCurPage(value); // Update current page when page is changed
  };
  // Assuming treatmentPlanHistory and pageSize are defined somewhere in your component's scope
  const currentPageSize = 5; // Number of items per page
  const startCurrentIndex = (currentCurPage - 1) * currentPageSize;
  const endCurrentIndex = startCurrentIndex + currentPageSize;
  const visibleCurrentItems = list.slice(startCurrentIndex, endCurrentIndex);
  if(visibleCurrentItems.length === 0 && list.length > 4){
    setCurrentCurPage(1)
  }
  const closeAlertModal = (data) => {
    setAlertData({
      open: false,
      data: null,
    });

    if (data.type === 1) {
      setlabOrderEntry({
        ...labOrderEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !labOrderEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          }
        },
        orders: [],
      });
    } else if (data.type) {
      AddLabOrder(true, data.type);
    }
  };
  const loadDefaultValues = async () => {
      let payload = {
        screenid: process.env.REACT_APP_LAB_ORDER_SCREEN_ID,
        tenantid: getTenantId(),
      };
  
      let defaultValue = await dispatch(
        actions.GET_SCREEN_DEFAULT_VALUE(payload)
      );
  
      if (
        defaultValue?.payload?.data?.response?.screenId ===
        process.env.REACT_APP_LAB_ORDER_SCREEN_ID
      ) {
        defaultValue = defaultValue?.payload?.data?.response?.defaultValues;
        let obj = {};
        defaultValue.forEach((v) => {
          obj[v?.stateName] = v?.value;
        });
        let epochDate = obj?.dateTime * 1000;
        let defaultDate = props?.patientgetData?.encounterStart ? new Date(props?.patientgetData?.encounterStart * 1000) : new Date(epochDate);
        setlabOrderEntry({
          ...labOrderEntry,
          orderCatalog: {},
          priority: {
            value: obj?.priority?._id,
            label: obj?.priority?.value,
          },
          nature: {
            value: obj?.nature?._id,
            label: obj?.nature?.value,
          },
          recurrentData:{
            every: {
              value: "",
              periodicity: {},
            },
            startsOn: {
              dateTime: defaultDate,
            },
            endsAfter: {
              endsAfter: "",
              endsBy: "",
            },
            preferrence: {
              preferredDays: [],
              preferredWeeks: [],
            },
          },
          dateTime: defaultDate,
          error: false,
          qty:1   //default value for qty
        });
      }
  };
  const getLabHistory = async () => {
    if(props?.patientgetData?.encounterId){
      const response = await dispatch(actions.LABORDER_READ_HISTORY({
        patientId: location?.state?.patientId ?? location?.state?.pId,
        encounterId: props?.patientgetData?.encounterId, //?? location?.state?.encounterId ?? location?.state?.encounter_id,
        startdate: getUtcTime(moment().startOf("day")),
      }));
      setLabHistory(response?.payload?.data)
    }
  }
  const getLabOrderRead = async () => {
    if(props?.patientgetData?.encounterId){
      let labordersData = await dispatch(
        actions.LABORDER_READ({
          encounter_id:  props?.patientgetData?.encounterId, //?? location?.state?.encounterId ?? location?.state?.encounter_id,
          order_id: "LAB",
          patientid:location?.state?.patientId ?? location?.state?.pId,
          startdate: props?.patientgetData?.encounterStart ? props?.patientgetData?.encounterStart : getUtcTime(moment().startOf("day")),
        })
      )
      setList(labordersData?.payload?.data)
      let defaultDate = new Date(props?.patientgetData?.encounterStart * 1000);
      setlabOrderEntry({
        ...labOrderEntry,
        headerShow: labordersData?.payload?.data?.length > 0 ? false : true ,
        orderCatalog:{},
        recurrentData:{
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: defaultDate,
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          }
        },
        dateTime: defaultDate,
      });
    }
  }
  const getAllLabResult = async () => {
   if(props?.patientgetData?.encounterId){
     const data = await dispatch(actions.GET_ALL_LAB_RESULT({
       patientid:location?.state?.patientId ?? location?.state?.pId,
       encounterid: props?.patientgetData?.encounterId,// ?? location?.state?.encounterId ?? location?.state?.encounter_id
     }))
     if (data?.payload?.data?.length > 0) {
       setlabResult(data?.payload?.data?.[0]?.components)
       setBodyData(data?.payload?.data)
       let HeaderData = []
       let componentdata = []
       data?.payload?.data?.map(val => {
         
         val?.components.map(li => {
           let obj = {
             id: HeaderData?.length + 1,
             Test: li?.ordercatlogid?.longdesc,
             Result: li?.ordervalue,
             Unit: li?.orderuom?.UOM,
             NormalRange: `${li?.ordermin}-${li?.ordermax}`,
             Notes: li?.remarks,
             ordercatlogid: li?.ordercatlogid?._id,
             parentordercatid: li?.parentordercatid?._id,
             parentordercatname: li?.parentordercatid?.longdesc,
           }
           HeaderData.push(obj)
           let Obj1 = {
             orderlineid: li?.orderlineid ? li?.orderlineid : "",
             ordervalue: li?.ordervalue ? li?.ordervalue : "",
             flag: li?.flag ? li?.flag : "",
             ordermax: li?.ordermax ? li?.ordermax : 0,
             method: li?.method ? li?.method : "",
             ordermin: li?.ordermin ? li?.ordermin : 0,
             orderuom: li?.orderuom?._id ? li?.orderuom?._id : "",
             parentordercatid: li?.parentordercatid?._id ? li?.parentordercatid?._id : "",
             ordercatlogid: li?.ordercatlogid?._id ? li?.ordercatlogid?._id : "",
             remarks: li?.remarks ? li?.remarks : "",
             type: ""
   
           }
           componentdata.push(Obj1)
         })
       })
       setcompData(componentdata)
       settableBody(HeaderData)
       setResulted(true)
       setEditId(data?.payload?.data?.[0]?._id)
     }
   }
  }
  const handleResultClose = () => {
    getAllLabResult()
    setResult(false)
  }
  const handleOpenDoc = async(docURL) => {
    if(docURL.length === 0)
    {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "No record found",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    else {
      const response = await axios.get(docURL, {
        method: "GET",
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], {
        type: "application/pdf",
      });
      const pdfDataUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfDataUrl, "_blank");
    }
    
  }
  const handleViewOpen = async (val,historyView) => {
    
    const data = await dispatch(actions.GET_ALL_LAB_RESULT({
      patientid: location?.state?.patientId ?? location?.state?.pId,
      encounterid: props?.patientgetData?.encounterId, //?? location?.state?.encounterId ?? location?.state?.encounter_id,
      orderlineid:val?.editData ?? val?._id ?? ""     //val?._id is for history
    }))
    if (data?.payload?.data?.length > 0) {
      setlabResult(data?.payload?.data?.[0]?.components)
      setBodyData(data?.payload?.data)
      let HeaderData = []
      let componentdata = []
      data?.payload?.data?.map(val => {
        
        val?.components.map(li => {
        let obj = {
          id: HeaderData?.length + 1,
          Test: li?.ordercatlogid?.longdesc,
          Result: li?.ordervalue,
          Unit: li?.orderuom?.UOM,
          NormalRange: `${li?.ordermin}-${li?.ordermax}`,
          Notes: li?.remarks,
          ordercatlogid: val?.ordercatlogid,
          parentordercatid: li?.parentordercatid?._id,
          parentordercatname:li?.parentordercatid?.longdesc
        }
        HeaderData.push(obj)
        let Obj1 = {
          orderlineid: li?.orderlineid ? li?.orderlineid : val?.orderlineid ?? "",
          ordervalue: li?.ordervalue ? li?.ordervalue : "",
          flag: li?.flag ? li?.flag : "",
          ordermax: li?.ordermax ? li?.ordermax : 0,
          method: li?.method ? li?.method : "",
          ordermin: li?.ordermin ? li?.ordermin : 0,
          orderuom: li?.orderuom?._id ? li?.orderuom?._id : "",
          parentordercatid: li?.parentordercatid?._id ? li?.parentordercatid?._id : "",
          ordercatlogid: li?.ordercatlogid?._id ? li?.ordercatlogid?._id : val.ordercatlogid ?? "",
          remarks: li?.remarks ? li?.remarks : "",
          type: ""

        }
        componentdata.push(Obj1)
      })

      })
      setcompData(componentdata)
      settableBody(HeaderData)
      let HeaderDataa = [
        // {
        //   field: 'id',
        //   headerName: 'SNo',
        //   width: 50,
        //   align: "center",
          
        // },
        {
          field: 'Test',
          headerName: 'Test',
          width: 250,
          editable: false,
          align: "left",
          headerClassName: 'super-app-theme--header',
        },
        {
          field: 'Result',
          headerName: 'Result',
          width: 125,
          editable: false,
          type: 'number',
          align: "center",
          headerAlign:"center",
          headerClassName: 'super-app-theme--header',
      
        },
        {
          field: 'Unit',
          headerName: 'Unit',
          width: 125,
          editable: false,
          align: "center",
          headerAlign:"center",
          headerClassName: 'super-app-theme--header',
        },
        {
          field: 'NormalRange',
          headerName: 'Normal Range',
          width: 200,
          editable: false,
          align: "center",
          headerAlign:"center",
          headerClassName: 'super-app-theme--header',
        },
        {
          field: 'Notes',
          headerName: 'Notes',
          width: 200,
          editable: false,
          align: "left",
          headerClassName: 'super-app-theme--header',
        },
      ]
      settableHeader(HeaderDataa)
      setResulted(false)
      if(historyView){
        setHistoryResult(true)
      }else{
        setResult(true)
      }
      
      setButtonShow(false)
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Failed Added Lab Result",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  }
  const saveLabOrders = async (
    lists,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    //debugger
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message: "Adding New LabOrder",
      });
      let personDetails = searchPractioner?.data?.[0];
      let search_Practioner = personDetails?.practitioner?.[0];
      let data = LabOrder_Json_Generator(
        lists,
        props?.patientgetData,//encounterDataaaa,
        personDetails,
        search_Practioner,
      );
      if (data?.length > 0) {
        let dateArr = [];
          if (
            lists?.[0]?.orderCatalog?._id &&
            lists?.[0]?.dateTime &&
            lists?.[0]?.nature?.value &&
            lists?.[0]?.priority?.value &&
            lists?.[0]?.recurrentData?.every?.value &&
            lists?.[0]?.recurrentData?.every?.periodicity?.value &&
            (lists?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              lists?.[0]?.recurrentData?.endsAfter?.endsBy) &&
              lists?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              lists?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
                lists?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(lists?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                  lists?.[0]?.recurrentData?.endsAfter?.endsBy,
                    "DD-MM-YYYY"
                  ), // // [by => Date(DD-MM-YYYY), on => number(5)]
                  lists?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(lists?.[0]?.recurrentData?.every?.value || "{}"), // number
              lists?.[0]?.recurrentData?.preferrence?.preferredDays || [], // []
              lists?.[0]?.recurrentData?.preferrence?.preferredWeeks || [] // []
            );
          } else {
            dateArr = [];
          }
          const upsertdata = await dispatch(
            actions.SET_LAB_ORDERS_SERVICE({
              list: data,
              dateArr: dateArr?.[0],
              isupdate: false,
              // childIds: childIds,
              deleteArr,
            })
          );
          getLabOrderRead();
        if (upsertdata?.payload?.data?.[0]?.error) {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Lab Order Save Failed",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
        loadDefaultValues();
        props?.backDrop?.setBackDrop({
          ...props?.backDrop,
          open: false,
          message:"",
        });
        
      }
  };
  const handleInputChange = (e, name) => {
    if (name === "dateTime") {
      let Data = {
        every: {
          value: "",
          periodicity: {},
        },
        startsOn: {
          dateTime: e,
        },
        endsAfter: {
          endsAfter: "",
          endsBy: "",
        },
      };
      const hours = e.getHours().toString().padStart(2, '0');
      const minutes = e.getMinutes().toString().padStart(2, '0');
      const seconds = e.getSeconds().toString().padStart(2, '0');
      const timePart = `${hours}:${minutes}:${seconds}`;
  
      if (timePart === "00:00:00" || timePart === "0:0:0") {
        const currentDateTime = new Date();
        const currentHours = currentDateTime.getHours().toString().padStart(2, '0');
        const currentMinutes = currentDateTime.getMinutes().toString().padStart(2, '0');
        const currentSeconds = currentDateTime.getSeconds().toString().padStart(2, '0');
        const currentTime = `${currentHours}:${currentMinutes}:${currentSeconds}`;
        
        setlabOrderEntry({
          ...labOrderEntry,
          [name]:props?.patientgetData?.encounterStart ? new Date(props?.patientgetData?.encounterStart * 1000) : new Date(e.toDateString() + " " + currentTime),
          recurrentData: Data,
          error: false,
        });
      } else {
        setlabOrderEntry({
          ...labOrderEntry,
          [name]: e,
          recurrentData: Data,
          error: false,
        });
      }
    } else {
      setlabOrderEntry({
        ...labOrderEntry,
        [name]: e,
        error: false,
      });
      setIndex(null);
      setAnchorEl(null);
    }
  };
  const checkDuplicateOrder = () => {
    let arr = [];
    if (list.length > 0) {
      list.map((v) => {
        arr.push(v?.orderCatalog?.value);
      });
    }
    if (arr.length > 0) {
      return arr.includes(labOrderEntry?.orderCatalog?.label);
    } else {
      return false;
    }
  };
  const AddLabOrder = async (ruleValidated, ruleType) => {
    if (
      labOrderEntry.orderCatalog.label &&
      labOrderEntry.dateTime &&
      labOrderEntry.nature.label &&
      labOrderEntry.priority.label &&
      (!labOrderEntry.recurrent ||
        (labOrderEntry.recurrentData?.every?.value &&
          labOrderEntry.recurrentData?.every?.periodicity?.value &&
          (labOrderEntry.recurrentData?.endsAfter?.endsAfter ||
            labOrderEntry.recurrentData?.endsAfter?.endsBy) &&
            labOrderEntry.recurrentData?.startsOn.dateTime))
    ) {
      let datalist = {
        ...labOrderEntry,
        createdName: info.name,
        createdAt: getData(),
        currentStatus: "New",
      };

      if (!ruleValidated) {
        if (checkDuplicateOrder()) {
          setAlertData({
            open: true,
            // data: "Duplicate order is entered. Do you still want to continue?"
            data: {
              type: 3,
              alertText:
                "Duplicate order is entered. Do you still want to continue?",
            },
          });
          return false;
        }
      }
      await saveLabOrders([datalist], null);
      setlabOrderEntry({
        orderCatalog: {},
        // priority: {},
        // nature: {},
        // dateTime: "",
        recurrent: false,
        headerShow: !labOrderEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          }
        },
        orders: [],
      });
      loadDefaultValues();

    } else {
      setlabOrderEntry({
        ...labOrderEntry,
        recurrent: false,
        headerShow: true,
        error: true,
      });
      setAnchorEl(null);
    }
  };
  const saveLabResult = async (components) => {
    let doc = {
      tenantid: process.env.REACT_APP_TENANTID,
      encounterid: props?.patientgetData?.encounterId,// ?? location?.state?.encounterId ?? location?.state?.encounter_id,
      facilityid: getFacilityId(),
      patientid: location?.state?.patientId ?? location?.state?.pId,
      components: components
    }
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: "Adding Lab Result",
    });
    let data = await dispatch(actions.SAVE_RESULT_DATA({ doc: doc, _id: editId }))
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });

    if (data?.payload?.data?.error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Failed Added Lab Result",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
    else if (!data?.payload?.data?.error) {
      const datass = await dispatch(actions.GET_ALL_LAB_RESULT({
        patientid: location?.state?.patientId ?? location?.state?.pId,
        encounterid: props?.patientgetData?.encounterId //?? location?.state?.encounterId ?? location?.state?.encounter_id
      }))

      if (datass?.payload?.data?.length > 0) {
        setlabResult(datass?.payload?.data)
        setResulted(true)
        setBodyData(datass?.payload?.data)

      }
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        // msg: `Please fill the ${err[Object.keys(state?.error)?.[0]]} !`,
        msg: "Lab Result Added Succesfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
    setResult(false)
    settableBody([])
  }
  const handleClick = (index, event) => {
    setAnchorEl(event.currentTarget);
    setIndex(index);
  };
  const handleCloseRec = () => {
    setlabOrderEntry({
      ...labOrderEntry,
      recurrent: false,
      // recurrentData: {
      //   every: {
      //     value: "",
      //     periodicity: labOrderEntry?.recurrentData?.every?.periodicity,
      //   },
      //   startsOn: {
      //     dateTime: new Date(props?.encData?.encStartDate * 1000) ??  new Date(),
      //   },
      //   endsAfter: {
      //     endsAfter: "",
      //     endsBy: "",
      //   },
      //   preferrence: {
      //     preferredDays: [],
      //     preferredWeeks: [],
      //   },
      // },
    });
    setIndex(0);
    setAnchorEl(null);
  };
  const saveRecurrentData = (data) => {
    if(data?.endsAfter?.endsAfter && data?.every?.value && data?.every?.periodicity?.value && data?.startsOn?.dateTime 
      && data?.every?.periodicity?.value === "CodingMaster/11059" ? data?.preferrence?.preferredDays?.length > 0 : 
      data?.every?.periodicity?.value === "CodingMaster/11060" ? (data?.preferrence?.preferredDays?.length > 0 &&
         data?.preferrence?.preferredWeeks?.length > 0) :true ){
      setlabOrderEntry({
        ...labOrderEntry,
        recurrentData: data,
        dateTime: data.startsOn.dateTime,
      });
      setAnchorEl(null);
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "please fill all the recurrent fields!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  
  };

  React.useEffect(()=>{
    getLabHistory()
    getLabOrderRead()
    getAllLabResult()
  },[props?.patientgetData])
  React.useEffect(()=>{
    loadDefaultValues()
    dispatch(actions.GET_ORDERCATALOG());
  },[])

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { parent_id } = props;

  return (
    <div
      id={`${parent_id}_laborder_parent_div`}
      style={{ width: "100%", marginBottom: "0px" }}
    >
      <Row
        id={`${parent_id}_laborder_parent_row`}
        inLineStyles={styles.vitailsCard}
      >
        {/* Add a new treatment procedures data's */}
        <Col
          id={`${parent_id}_laborder_parent_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          {labOrderEntry.headerShow ? (
            <div>
              <Grid container justifyContent="space-between" direction="column">
                <Grid item container>
                  <Grid item style={{ marginTop: "15px" }}>
                    <Text
                      id={`${parent_id}_laborder_headerShow_text`}
                      inLineStyles={{
                        fontWeight: 600,
                        fontSize: "14px",
                        cursor: "pointer",
                        color: UIColor.primaryColor.color,
                        alignItems: "center",
                      }}
                    >
                      Lab Order
                    </Text>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider orientation="horizontal" variant="fullWidth" />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                style={{ display: "flex", gap: "10px", padding: "15px" }}
              >
                <Grid item>
                  {" "}
                  <div
                    id={`${parent_id}_laborder_title_div`}
                    style={styles.vitalsfieldHead}
                  >
                    <div
                      id={`${parent_id}_laborder_title_sub_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <Autocomplete
                        id={`${parent_id}_laborder_autocomplete`}
                        className={classes.autocomplete}
                        style={{ width: 160 }}
                        fullWidth
                        size="small"
                        options={storeData?.getordercatalog?.data ?? []}
                        popupIcon={
                          <ExpandMoreIcon
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            id={`${parent_id}_laborder_Search_textfield`}
                            {...params}
                            variant="standard"
                            placeholder="Search Lab orders"
                            error={labOrderEntry?.error && !labOrderEntry?.orderCatalog?.value}
                            style={{ width: "100%", fontSize: 14 }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${parent_id}_laborder_Search_${option.label.replaceAll(
                              " ",
                              "_"
                            )}_typography`}
                            className="pc_regular"
                            style={{
                              width: "100%",
                              fontSize: 14,
                              color: UIColor.secondaryText.color,
                            }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={labOrderEntry.orderCatalog}
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "orderCatalog")
                        }
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_Priority_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <Autocomplete
                        id={`${parent_id}_laborder_Priority_autocomplete`}
                        style={{ width: "100px" }}
                        className={classes.autocomplete}
                        size="small"
                        options={storeData?.getpriority?.data ?? []}
                        popupIcon={
                          <ExpandMoreIcon
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            id={`${parent_id}_laborder_Priority_textfield`}
                            {...params}
                            variant="standard"
                            placeholder="Priority"
                            error={labOrderEntry?.error && !labOrderEntry?.priority?.value}
                            style={{ width: "100%", fontSize: 14 }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${parent_id}_laborder_Priority_${option.label.replaceAll(
                              " ",
                              "_"
                            )}_typography`}
                            className="pc_regular"
                            style={{
                              width: "100%",
                              fontSize: 14,
                              color: UIColor.secondaryText.color,
                            }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        // classes={{popper: "muiPopperDropdown"}}
                        value={labOrderEntry.priority}
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "priority")
                        }
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_Nature_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <Autocomplete
                        className={classes.autocomplete}
                        style={{ width: "100px" }}
                        id={`${parent_id}_laborder_Nature_autocomplete`}
                        size="small"
                        options={storeData?.getordernature?.data ?? []}
                        popupIcon={
                          <ExpandMoreIcon
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            id={`${parent_id}_laborder_Nature_textfield`}
                            {...params}
                            variant="standard"
                            placeholder="Nature"
                            error={labOrderEntry?.error && !labOrderEntry?.nature?.value}
                            style={{ width: "100%", fontSize: 14 }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${parent_id}_laborder_Nature_${option.label.replaceAll(
                              " ",
                              "_"
                            )}_typography`}
                            className="pc_regular"
                            style={{
                              width: "100%",
                              fontSize: 14,
                              color: UIColor.secondaryText.color,
                            }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        // classes={{popper: "muiPopperDropdown"}}
                        value={labOrderEntry.nature}
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "nature")
                        }
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_calender_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <TextField
                        id={`${parent_id}_laborder_calender_textfield`}
                        className={classes.autocomplete}
                        style={{ width: 170 }}
                        autoComplete={"off"}
                        value={
                          labOrderEntry?.dateTime
                            ? moment(labOrderEntry?.dateTime).format(
                                "YYYY-MM-DD, hh:mm A"
                              )
                            : ""
                        }
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "dateTime")
                        }
                        error={labOrderEntry?.error && !labOrderEntry?.dateTime}
                        onClick={(event) => handleClick(1, event)}
                        placeholder={"Start Date"}
                        InputProps={{
                          classes: { input: classes.input },
                          endAdornment: (
                            <InputAdornment
                              id={`${parent_id}_laborder_calender_inputadornment`}
                              position="end"
                            >
                              <Div
                                id={`${parent_id}_laborder_calendericon_div`}
                                aria-describedby={id}
                                style={{ cursor: "pointer" }}
                                onClick={(event) => handleClick(1, event)}
                              >
                                <CalendarIcon
                                  id={`${parent_id}_laborder_calendericon`}
                                  color={UIColor.secondaryText.color}
                                  width={"16"}
                                  height={"16"}
                                />
                              </Div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_Recurrent_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      {labOrderEntry.orderCatalog?.value && (
                        <Div
                          id={`${parent_id}_laborder_Recurrent_switch_div`}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Text
                            id={`${parent_id}_laborder_Recurrent_text`}
                            className="pc_regular"
                            inLineStyles={{
                              color: UIColor.secondaryText.color,
                              fontSize: 12,
                            }}
                          >
                            {"Recurrent"}
                          </Text>
                          <Switch
                            id={`${parent_id}_laborder_Recurrent_switch`}
                            aria-describedby={id}
                            checked={labOrderEntry.recurrent}
                            onChange={(event) => {
                              handleInputChange(
                                event.target.checked,
                                "recurrent"
                              );
                              event.target.checked && handleClick(0, event);
                            }}
                            className={classes.switch}
                          />
                        </Div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  {" "}
                  <Button
                    id={`${parent_id}_laborder_add_button`}
                    onClick={() => {
                      if (
                        labOrderEntry.orderCatalog?.value &&
                        labOrderEntry.dateTime &&
                        labOrderEntry.nature.label &&
                        labOrderEntry.priority.label
                      ) {
                        AddLabOrder();
                      } else {
                        let fld = "";
                        if (!labOrderEntry.orderCatalog?.value) {
                          fld =
                            fld +
                            (fld == "" ? "" : ",") +
                            "\nLab Order";
                        }
                        if (!labOrderEntry.priority.label) {
                          fld = fld + (fld == "" ? "" : ",") + "\nPriority";
                        }
                        if (!labOrderEntry.nature.value) {
                          fld = fld + (fld == "" ? "" : ",") + "\nNature";
                        }
                        if (!labOrderEntry.dateTime) {
                          fld = fld + (fld == "" ? "" : ",") + "\nDate";
                        }
                        setlabOrderEntry({
                          ...labOrderEntry,
                          recurrent: false,
                          headerShow: true,
                          error: true,
                        });
                        props.alert.setSnack({
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: "Please Enter the Missing Fields" + fld,
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.right,
                        });
                        return;
                      }
                    }}
                    variant={"text"}
                    style={{
                      color: UIColor.primaryColor.color,
                      fontWeight: 600,
                      minWidth: "1%",
                      padding: "5px",
                      marginLeft: "3px",
                    }}
                    size={"small"}
                  >
                    <img src={Tick} alt="Add" />
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              id={`${parent_id}_laborder_headerShow_div`}
              style={{
                display: "flex",
                width: "99%",
                justifyContent: "space-between",
              }}
            >
              <div className="icon" style={{ display: "flex" }}>
                {Result && (
                  <div
                    style={styles.uploadIcon}
                    onClick={() => handleResultClose()}
                  >
                    <Image
                      id={`${parent_id}_topnavbar_log_image`}
                      style={styles.img}
                      src={backIcon}
                      alt="left"
                    />
                  </div>
                )}
                <Text
                  id={`${parent_id}_laborder_headerShow_text`}
                  // onClick={() =>
                  //   handleInputChange(
                  //     !labOrderEntry.headerShow,
                  //     "headerShow"
                  //   )
                  // }
                  inLineStyles={{
                    fontWeight: 600,
                    fontSize: "14px",
                    cursor: "pointer",
                    color: UIColor.primaryColor.color,
                    padding: "15px 0",
                  }}
                >
                  {Result ? "Lab Orders" : "Lab Orders"}
                </Text>
              </div>
              <div
                className="icon"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  paddingTop: "10px",
                }}
              >
                {(!Result && !Resulted) && (
                  <div >
                    <Chip size="small" label="Enter Result" variant="outlined" style={{ borderRadius: "1%", backgroundColor: "#fde5e5", padding: "0px , 0px" }} 
                    // onClick={() => handleResultOpen()} 
                    color="secondary" 
                    />
                  </div>
                )}
                {Resulted && (
                  <div style={{ marginRight: "10px" }}>
                    <Chip size="small" label="Resulted" variant="outlined" style={{ borderRadius: "1%", backgroundColor: "#d8fcd7", padding: "0px , 0px", color: "#00b007" }} />
                  </div>
                )}
                {Resulted && (
                  <div>
                    <Tooltip title={"View Result"} placement="top">
                      <Icons
                        id={`${parent_id}-view`}
                        fontIcon="eye"
                        ariaHidden="true"
                        size="small"
                        onClick={() => handleViewOpen()}
                        style={{ color: UIColor.secondaryButtonColor.color, marginRight: "10px" }}
                      />
                    </Tooltip>
                  </div>
                )}
                {Resulted && (
                  <div>
                    <Tooltip title={"View Result"} placement="top">
                      <Icons
                        id={`${parent_id}-view`}
                        fontIcon="pencil"
                        ariaHidden="true"
                        size="small"
                        // onClick={() => handleResultOpen()}
                        style={{ color: UIColor.secondaryButtonColor.color, marginRight: "10px" }}
                      />
                    </Tooltip>
                  </div>
                )}
                {/* {Resulted && (
                  <div>
                    <ReactToPrint
                      onBeforeGetContent={handleOnBeforeGetContent}
                      onAfterPrint={handleAfterPrint}
                      trigger={() => (
                        <Div
                          id={`${parent_id}-print`}
                          style={{
                            ...styles.flexRow,
                            cursor: "pointer",
                            padding: "0px 0px",
                          }}
                          onClick={() => handlePrint()}
                        >
                          <Icons
                            id={`${parent_id}confirmbooking_print_image`}
                            fontIcon="print"
                            ariaHidden="true"
                            size="small"
                            style={{ color: UIColor.secondaryButtonColor.color }}
                          />
                          <Text
                            id={`${parent_id}confirmbooking_print_text`}
                            className="pc_regular"
                            style={styles.iconSpan}
                          >
                            
                          </Text>
                        </Div>
                      )}
                      content={() => componentRef}
                    />
                  </div>
                )} */}
                {!Result && (
                  <div
                    onClick={() =>
                      handleInputChange(
                        !labOrderEntry.headerShow,
                        "headerShow"
                      )
                    }
                    style={{ marginLeft: "10px", marginTop: "4px" }}
                  >
                    <img src={Addicon} alt="Add" />
                  </div>
                )}
              </div>
            </div>
          )}{" "}
          <div
            id={`${parent_id}_laborder_add_button_div`}
            style={styles.flexFlow}
          >
            {/* {treatmentProcedureEntry.orderCatalog?.value &&
            treatmentProcedureEntry.dateTime &&
            treatmentProcedureEntry.nature.label &&
            treatmentProcedureEntry.priority.label ? ( */}
            {labOrderEntry.headerShow && (
              <div
                id={`${parent_id}_laborder_add_button_sub_div`}
                style={{ width: "100%", display: "flex", marginRight: "10px" }}
              >
                {/* add button */}
                {/* <Button
                  id={`${parent_id}_laborder_add_button`}
                  onClick={() => {
                    if (
                      treatmentProcedureEntry.orderCatalog?.value &&
                      treatmentProcedureEntry.dateTime &&
                      treatmentProcedureEntry.nature.label &&
                      treatmentProcedureEntry.priority.label
                    ) {
                      AddTreatmentProcedure();
                    } else {
                      return;
                    }
                  }}
                  variant={"text"}
                  style={{
                    color: UIColor.primaryColor.color,
                    fontWeight: 600,
                    minWidth: "30%",
                    padding: "5px",
                    marginLeft: "3px",
                  }}
                  size={"small"}
                >
                  <img src={Tick} alt="Add" />
                </Button> */}
                {/* Removing close action as per the requirement*/}
                {/* <Button
                  id={`${parent_id}_allergy_add_button`}
                  onClick={() => handleCloseForm()}
                  variant={"text"}
                  style={{
                    color: UIColor.primaryColor.color,
                    fontWeight: 600,
                    minWidth: "30%",
                    padding: "5px",
                  }}
                  size={"small"}
                >
                  <img src={Cancel} alt="Cancel" />
                </Button> */}
              </div>
            )}
            {/* ) : ( */}
            {/* <div
                id={`${parent_id}_laborder_add_div`}
                style={{ width: "100%" }}
              >
                <Text
                  id={`${parent_id}_laborder_add_text`}
                  className="pc_medium"
                  inLineStyles={{ color: "#b6b6b6", fontSize: 14 }}
                >
                  {"+ ADD"}
                </Text>
              </div> */}
            {/* )} */}
            {/* <Tooltip
              id={`${parent_id}_laborder_Insights_icon_tooltip`}
              title={"Insights"}
            >
              <div style={{ ...styles.infoBell, ...styles.icons }}>
                <InsightsIcon
                  id={`${parent_id}_laborder_Insights_icon`}
                  style={{ marginLeft: 5 }}
                  // color={"#0071F2"}
                />
              </div>
            </Tooltip> */}
          </div>
        </Col>

        {/* Treatment procedure list */}
        <div style={{width:"100%"}}>
        {!Result ? (
            visibleCurrentItems.length > 0 &&
          visibleCurrentItems.map((val, i) => {
            return (
              <Col
                id={`${parent_id}_laborder_${i}_list_col`}
                key={i}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={{
                  padding: "10px 8px",
                  borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                  overflow: "hidden",
                }}
                className={"allergyListHover_"}
              >
                <LabOrderList
                  parent_id={"lab_order" + i}
                  treatment_order={val?.orderCatalog?.label}
                  priority={val?.priority?.label}
                  nature={val?.nature?.label}
                  dateTime={val?.dateTime}
                  ordersData={labOrderEntry?.orders}
                  // onEditChart={props.onEditChart}
                  val={val}
                  infoAlertText={val.infoAlertText}
                  status={
                    val?.childRecurrent?.[0]?.statushistory?.length > 0
                      ? val?.childRecurrent?.[0]?.statushistory?.[
                          val?.childRecurrent?.[0]?.statushistory?.length - 1
                        ]?.new_status ?? "Draft"
                      : "Draft"
                  }
                  IndexValue={i}
                  // deleteList={deleteList}
                  data={val}
                  info={info}
                  getData={getData}
                  patientgetData={props?.patientgetData}
                  // recurrentData={val?.recurrentData}
                  saveLabOrders={saveLabOrders}
                  handleViewOpen={handleViewOpen}
                  queuestatus={val?.queuestatus?.[0]?.states}
                  getLabOrderRead={getLabOrderRead}
                  backDrop={props?.backDrop}
                />
              </Col>
            );
          })
          
        ) : (
          <Col
            id={`${parent_id}_laborder_list_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={{
              padding: "10px 8px",
              borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
              overflow: "hidden",
            }}
            className={"allergyListHover_"}
          >
            <AddResult
              TableBody={tableBody}
              TableHeader={tableHeader}
              BodyData={BodyData}
              parent_id={parent_id}
              // selectedLab={selectedValue}
              handleResultClose={handleResultClose}
              saveLabResult={saveLabResult}
              componentdata={compData}
              Resulted={Resulted}
              Result={Result}
              ButtonShow={buttonShow}
              labResult={labResult}
            />
          </Col>
        )}
          {list.length > 5 && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px 10px",
                borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
              }}
            >
              <Pagination
                count={Math.ceil(list.length / currentPageSize)}
                page={currentCurPage}
                onChange={handleCurrentPageChange}
              />
            </div>
          )}
        </div>
        {/* Treatment procedure History list */}
        <HistoryAccordion>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            {!historyResult ? (
              visibleItems.length > 0 &&
              visibleItems.map((order, i) => {
                return (
                  <Grid
                  item
                  xs={12}
                  style={{
                    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
                  }}
                >
                  <Grid container xs={12} justifyContent="space-between">
                    <Grid item xs={10} container direction="column" spacing={1}>
                      <Grid
                        item
                        style={{
                          color: UIColor.secondaryButtonColor.color,
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "pc_regular",
                        }}
                      >
                        {order.orderCatalog}
                      </Grid>
                      <Grid
                        item
                        style={{
                          fontFamily: "pc_regular",
                          fontSize: 12,
                          color: UIColor.tertiaryText.color,
                        }}
                      >
                        {order?.priority?.display} -{" "}
                        {order?.OrderNature?.display} - On{" "}
                        {formatDateFromEpoch(order?.occuranceStartDate)}
                        <span
                          style={order?.queuestatus?.[0]?.states==="Active Orders" ?  //queuestates/10093
                           {color:UIColor.secondaryColor.color,margin:"0px"}
                           : {color:UIColor.success.color,margin:"0px"}}>
                            &nbsp;&nbsp; {order?.queuestatus?.[0]?.states}
                        </span>
                        <span style={{color: UIColor.tertiaryText.color,margin:"0px",fontFamily: "pc_regular",fontSize: 12}}>
                         &nbsp;&nbsp;{order?.admindate === null ? "" : " - On " + utcTOLocal(order?.admindate, "YYYY-MM-DD,hh:mm A")}
                        
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
                    {isHovered ? (
                      <Grid container justifyContent="flex-end" alignItems="center">
                        <Grid item>
                          <IconButton onClick={() => handleOpenDoc(order?.resulturl)}>
                            <img src={printIcon} alt="Add" style={{height:"18px",weight:"18px"}} />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={() => handleViewOpen(order,true)}>
                            <img src={icons8eye} alt="Add" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ) : (
                      // Your first container content
                      <Grid container alignItems="flex-end" direction="column" spacing={1}
                       style={{ fontFamily: "pc_regular",
                                fontSize: 12,
                                paddingLeft: 6,
                                color: UIColor.tertiaryText.color}}>
                        <Grid item>On{" "} {formatDateFromEpoch(order?.orderDateTime).slice(0, 12)}</Grid>
                        <Grid item>{order?.updatedby?.name?.[0]?.fulname}</Grid>
                      </Grid>
                    )}
                  </Grid>
                  </Grid>
                </Grid>
                );
              })
            ) : (
              <Col
                id={`${parent_id}_laborder_list_col`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={{
                  padding: "10px 8px",
                  borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                  overflow: "hidden",
                }}
                className={"allergyListHover_"}
              >
              <Grid container justifyContent="space-between" direction="column">
              <Grid item container>
                <Grid item style={{ marginTop: "15px",aligntext: "center" }}>
               
                <Image
                  id={`${parent_id}_topnavbar_log_image`}
                  style={styles.img}
                  src={backIcon}
                  alt="left"
                  onClick={() => setHistoryResult(false)}
                  inLineStyles={{marginRight:"10px"}}
                />
           
                  <Text
                    id={`${parent_id}_laborder_headerShow_text`}
                    inLineStyles={{
                      fontWeight: 600,
                      fontSize: "14px",
                      cursor: "pointer",
                      color: UIColor.primaryColor.color,
                      alignItems: "center"
                    }}
                  >
                    Lab Order
                  </Text>
                </Grid>
              </Grid>
              <Grid item>
                <Divider orientation="horizontal" variant="fullWidth" />
              </Grid>
            </Grid>
                <AddResult
                  TableBody={tableBody}
                  TableHeader={tableHeader}
                  BodyData={BodyData}
                  parent_id={parent_id}
                  // selectedLab={selectedValue}
                  handleResultClose={handleResultClose}
                  saveLabResult={saveLabResult}
                  componentdata={compData}
                  Resulted={Resulted}
                  Result={Result}
                  ButtonShow={buttonShow}
                  labResult={labResult}
                />
              </Col>
            )}
           
          </Grid>
          {labHistory.length > 2 && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Pagination
                count={Math.ceil(labHistory.length / pageSize)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )}
        </HistoryAccordion>
      </Row>
      {/* {labOrderEntry.error && (
        <span
          id={`${parent_id}_laborder_filled_Recurrent_span`}
          style={styles.vitailsCarderr}
        >
          Please fill all the mandatory fields!
        </span>
      )} */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={index === 1 ? ()=>setAnchorEl(null) : null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.muiPopovers }}
      >
        {index === 0 && (
          <Div
            id={`${parent_id}_laborder_Recurrent_div`}
            style={{ padding: 15 }}
          >
            <Recurrent
              parent_id={"lab_order"}
              recurrentData={labOrderEntry?.recurrentData}
              saveButtonAction={saveRecurrentData}
              deleteButtonAction={handleCloseRec}
              width={500}
            />
          </Div>
        )}
        {index === 1 && (
          <CustomDatePicker
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            selectedDate={
              labOrderEntry?.dateTime
                ? new Date(labOrderEntry?.dateTime)
                : null
            }
            //minDate={new Date()}
            minTime={getCurrentMinTime(
              labOrderEntry?.dateTime
                ? new Date(labOrderEntry?.dateTime)
                : null
            )}
            maxTime={setHours(setMinutes(new Date(), 59), 23)}
            handleChange={(date, e) => {
              handleInputChange(date, "dateTime");
              if (e?.target === undefined) {
                setIndex(null);
                setAnchorEl(null);
              }
            }}
          />
        )}
      </Popover>
      {alertData.open && (
        <AlertsRestriction {...alertData} handleClose={closeAlertModal} />
      )}
    </div>
  );
};
export default withAllContexts(LabOrder);