import * as React from "react";
import {
  Button,
  Typography,
  Paper,
  Grid,
  TextField,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import theme from "../../../utils/theme";
import { DevTool } from "@hookform/devtools";
import { AlertProps } from "../../../utils";
import { AlertContext } from "../../../context";

const schema = yup.object().shape({
  Hepatatis: yup.string().required("Hepatatis is required"),
  HepatatisType: yup.mixed().when("Hepatatis", {
      is: (val) => val === "Yes",
      then: (schema) => schema.required("Hepatatis Type is required"),
      otherwise: (schema) => schema,
    }),
  TVA: yup
    .string()
    .required("TVA is required")
    .matches(/^[a-zA-Z0-9\s]+$/i, "Eg: !@#$%^&*() not allowed"),
  CCA: yup
    .string()
    .required("CCA is required")
    .matches(/^[a-zA-Z0-9\s]+$/i, "Eg: !@#$%^&*() not allowed"),
  PCS: yup
    .string()
    .required("PCS is required")
    .matches(/^[a-zA-Z0-9\s]+$/i, "Eg: !@#$%^&*() not allowed"),
  TimeArrived: yup.string().required("Time Arrived is required"),
  TimeStart: yup.string().required("Time Start is required"),
  TEMP: yup
    .number()
    .required("Temperature is required")
    .min(35, "value should be 35 to 42")
    .max(42, "value should be 35 to 42")
    .typeError("It must be a number"),
  BP: yup
    .string()
    .required("Blood Pressure is required")
    //.matches(/^\d+\/\d+$/, "Eg: Format should be 80/120")
    .test('valid-range', 'Ensure format is 80/120 and values are between 40 and 250', value => {
      if (!value) return false;
      console.log((value?.match(/\//g) || []).length,value,"(value.match&")
      if((value.match(/\//g) || []).length > 1) {
          return true
      }
      const parts = value.split('/');
      if (parts.length !== 2) return false;
      const [value1, value2] = parts.map(Number);
      const isValidRange = (val) => val >= 40 && val <= 250;
      const hasValidParts = isValidRange(value1) && isValidRange(value2);
      return hasValidParts;
    }),
  PULSE: yup
    .number()
    .required("Pulse is required")
    .min(50, "value should be at 50 to 250")
    .max(250, "value should be at 50 to 250")
    .typeError("It must be a number"),
  SS: yup
    .string()
    .required("SS is required")
    .matches(/^[a-zA-Z0-9\s]+$/i, "Eg: !@#$%^&*() not allowed"),
  StaffID: yup.mixed().required("Staff ID is required"),
});

const Predialysis = props => {
  const alertContext = React.useContext(AlertContext);
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    // reValidateMode: "onSubmit",
    defaultValues: {
      Hepatatis: "No",
      HepatatisType: "",
      TVA: "",
      CCA: "",
      PCS: "",
      TimeArrived: "",
      TimeStart: "",
      TEMP: null,
      BP: "",
      PULSE: null,
      SS: "",
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors ,isValid},
    setValue,
    control,
    getValues,
    reset,
    setError,
    watch,
    clearErrors,
    trigger,
  } = form;

  // const [open, setopen] = React.useState(true);
  const formValues = watch();

  const onSubmit = async (data) => {
    alertContext.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "Predialysis Saved",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
    console.log(data, "onSubmit");
    props?.setPreDialysis(data);
    props?.setisMasterSave(true);
    // props?.setisPreDialysis(true);
    // setopen(true);
  };

//   const BpCalculate = value => {
//     let min_range = 40;
//     let max_range = 250;
//     setValue("BP", value);
//     if (value.includes(" ") && !value.includes("/")) {
//       let val = getValues("BP");
//       let result = val.replace(/\s/g, "");
//       setValue("BP", result + "/");
//     }
//     if (value.includes("/")) {
//       let [value1, value2] = value.split("/");
//       if (value1 < min_range || value1 > max_range) {
//         setError("BP", {
//           type: "manual",
//           message: "Value should be 40 to 250",
//         });
//       } else {
//         if (value2 < min_range || value2 > max_range) {
//           setError("BP", {
//             type: "manual",
//             message: "Value should be 40 to 250",
//           });
//         } else {
//           clearErrors("BP");
//         }
//         clearErrors("BP");
//       }
//     } else {
//       setError("BP", {
//         type: "manual",
//         message: "Value should be 40 to 250",
//       });
//     }
//     // trigger("BP");
//   };

const BpCalculate = (value) => {
    const min_range = 40;
    const max_range = 250;
  
    // // Remove all non-numeric and non-slash characters
    // value = value.replace(/[^0-9/]/g, '');
  
    // // Replace first space with a slash if there's no slash yet
    // if (value.includes(" ") && !value.includes("/")) {
    //   value = value.replace(" ", "/");
    // }
    const parts = value.split("/");
    // if (parts.length > 2) {
    //   value = parts[0] + "/" + parts.slice(1).join('');
    // }
  
    setValue("BP", value);

    if (parts.length === 2) {
      const [value1, value2] = parts.map(Number);
      if (isNaN(value1) || isNaN(value2) || value1 < min_range || value1 > max_range || value2 < min_range || value2 > max_range) {
        setError("BP", {
          type: "manual",
          message: "Values should be between 40 and 250",
        });
      } else {
        clearErrors("BP");
      }
    } else {
      setError("BP", {
        type: "manual",
        message: "Incomplete value, expected format: 80/120",
      });
    }
  };

  React.useEffect(() => {
    if(props?.PreDialysis){
        console.log(props?.PreDialysis,"PreDialysisPreDialysis")
        Object.keys(props?.PreDialysis).forEach(key => {
            setValue(key, props?.PreDialysis[key]);
        });
    } else {
        if (props?.defaultValues) {
          const data = {
            TimeArrived: props?.defaultValues?.arrived_time?.value,
            TimeStart: props?.defaultValues?.start_time?.value,
          };
          Object.keys(data).forEach(key => {
            setValue(key, data[key]);
          });
          //   setValue("Hepatatis", "No");
        }
    }
  }, [props?.defaultValues,props?.PreDialysis]);

  console.log(formValues,errors,isValid, "PulsePulse");

  return (
    <Paper
      sx={{
        padding: "10px",
        margin: "20px",
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 6 }}
          margin={1}
        >
          <Grid item xs={2.3} marginLeft={2}>
            <Typography variant="h6">PRE DIALYSIS</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <TextField
              id="outlined-basic"
              label="Time Arrived"
              variant="outlined"
              size="small"
              type="time"
              {...register("TimeArrived")}
              InputLabelProps={{ shrink: true }}
              // disabled={open}
              error={errors?.TimeArrived ? true : false}
              helperText={
                errors?.TimeArrived?.type === "required" ||
                errors?.TimeArrived?.type === "optionality"
                  ? ""
                  : errors?.TimeArrived?.message
              }
              sx={{ width: 180, backgroundColor: "#FFFFFF" }}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              id="outlined-basic"
              label="HD Start Time"
              variant="outlined"
              size="small"
              type="time"
              {...register("TimeStart")}
              InputLabelProps={{ shrink: true }}
              // disabled={open}
              error={errors?.TimeStart ? true : false}
              helperText={
                errors?.TimeStart?.type === "required" ||
                errors?.TimeStart?.type === "optionality"
                  ? ""
                  : errors?.TimeStart?.message
              }
              sx={{ width: 180, backgroundColor: "#FFFFFF" }}
            />
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ padding: "10px", margin: "10px" }}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 1, md: 6 }}
                margin={1}
              >
                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="Hepatatis"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={["No", "Yes"]}
                        size="small"
                        getOptionLabel={v => v}
                        fullWidth
                        onChange={(_, data) => {
                            field.onChange(data)
                            }}
                        onBlurCapture={(_, data)=>{
                            data === "No" && setValue('HepatatisType',"")
                            trigger('HepatatisType')
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Hepatatis"
                            InputLabelProps={{ shrink: true }}
                            error={errors?.Hepatatis ? true : false}
                            helperText={
                              errors?.Hepatatis?.type === "required" ||
                              errors?.Hepatatis?.type === "optionality"
                                ? ""
                                : errors?.Hepatatis?.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <Controller
                    name="HepatatisType"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={["Hepatatis B", "Hepatatis C"]}
                        size="small"    
                        getOptionLabel={option => option}
                        fullWidth
                        disabled={getValues('Hepatatis') === "No" ? true : false}
                        onChange={(_, data) => field.onChange(data)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Hepatatis Type"
                            error={errors?.HepatatisType ? true : false}
                            helperText={
                              errors?.HepatatisType?.type === "required" ||
                              errors?.HepatatisType?.type === "optionality"
                                ? ""
                                : errors?.HepatatisType?.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <TextField
                    id="outlined-basic"
                    label="Type of Vascular Access"
                    variant="outlined"
                    size="small"
                    {...register("TVA")}
                    fullWidth
                    // disabled={open}
                    InputLabelProps={{ shrink: getValues('TVA') ? true : false }}
                    error={errors?.TVA ? true : false}
                    helperText={
                      errors?.TVA?.type === "required" ||
                      errors?.TVA?.type === "optionality"
                        ? ""
                        : errors?.TVA?.message
                    }
                  />
                </Grid>

                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <TextField
                    id="outlined-basic"
                    label="Condition of Cascular Access"
                    variant="outlined"
                    size="small"
                    {...register("CCA")}
                    fullWidth
                    // disabled={open}
                    InputLabelProps={{ shrink: getValues('CCA') ? true : false }}
                    error={errors?.CCA ? true : false}
                    helperText={
                      errors?.CCA?.type === "required" ||
                      errors?.CCA?.type === "optionality"
                        ? ""
                        : errors?.CCA?.message
                    }
                  />
                </Grid>

                <Grid item xs={2.4} style={{ paddingTop: 0 }}>
                  <TextField
                    id="outlined-basic"
                    label="Patient Condition/ Symptoms"
                    variant="outlined"
                    size="small"
                    // disabled={open}
                    {...register("PCS")}
                    fullWidth
                    InputLabelProps={{ shrink: getValues('PCS') ? true : false }}
                    error={errors?.PCS ? true : false}
                    helperText={
                      errors?.PCS?.type === "required" ||
                      errors?.PCS?.type === "optionality"
                        ? ""
                        : errors?.PCS?.message
                    }
                  />
                </Grid>

                <Grid item xs={2.4}>
                  <TextField
                    id="outlined-basic"
                    label="Temperature"
                    variant="outlined"
                    size="small"
                    {...register("TEMP")}
                    fullWidth
                    // disabled={open}
                    InputLabelProps={{ shrink: getValues('TEMP') ? true : false }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">°C</InputAdornment>
                      ),
                    }}
                    onBlur={() => trigger("TEMP")}
                    error={errors?.TEMP ? true : false}
                    helperText={
                      errors?.TEMP?.type === "required" ||
                      errors?.TEMP?.type === "optionality"
                        ? ""
                        : errors?.TEMP?.message
                    }
                  />
                </Grid>

                <Grid item xs={2.4}>
                  <TextField
                    id="outlined-basic"
                    label="Blood Pressure"
                    variant="outlined"
                    size="small"
                    {...register('BP')}
                    fullWidth
                    onChange={e => {
                    //   // setValue(e.target.value)
                    //   let val = getValues("BP").includes("/");
                    //   let pattern = val
                    //     ? /^[0-9]+(?:\/[0-9]*)?$/
                    //     : /^[0-9\/\s]*$/;
                    //   let result = pattern.test(e.target.value);
                    //   console.log(val, result, "()()()");
                    //   if (result || e.target.value === "") {
                        BpCalculate(e.target.value);
                    //   } else {
                    //     return;
                    //   }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mmHg</InputAdornment>
                      ),
                    }}
                    placeholder="Eg: 80/120"
                    InputLabelProps={{ shrink: true }}
                    // disabled={open}
                    error={errors?.BP ? true : false}
                    helperText={
                      errors?.BP?.type === "required" ||
                      errors?.BP?.type === "optionality"
                        ? ""
                        : errors?.BP?.message
                    }
                  />
                </Grid>

                <Grid item xs={2.4}>
                  <TextField
                    id="outlined-basic"
                    label="Pulse"
                    variant="outlined"
                    size="small"
                    {...register("PULSE")}
                    fullWidth
                    InputLabelProps={{
                      shrink: getValues("PULSE") ? true : false,
                    }}
                    // value={getValues('TargetDryWeight')}
                    // onBlur={() => trigger("TEMP")}
                    // disabled={open}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Bpm</InputAdornment>
                      ),
                    }}
                    error={errors?.PULSE ? true : false}
                    helperText={
                      errors?.PULSE?.type === "required" ||
                      errors?.PULSE?.type === "optionality"
                        ? ""
                        : errors?.PULSE?.message
                    }
                  />
                </Grid>

                <Grid item xs={2.4}>
                  <TextField
                    id="outlined-basic"
                    label="Start by SN"
                    variant="outlined"
                    size="small"
                    {...register("SS")}
                    fullWidth
                    // disabled={open}
                    InputLabelProps={{ shrink: getValues('SS') ? true : false }}
                    error={errors?.SS ? true : false}
                    helperText={
                      errors?.SS?.type === "required" ||
                      errors?.SS?.type === "optionality"
                        ? ""
                        : errors?.SS?.message
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={2.4}
                  alignContent={"center"}
                  alignItems={"center"}
                  alignSelf={"center"}
                >
                  <Controller
                    name="StaffID"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        size="small"
                        id="staffId"
                        options={props?.striptestList || []}
                        isOptionEqualToValue={(Option, value) =>
                          Option?._id === value?._id
                        }
                        // disabled={open}
                        InputLabelProps={{ shrink: getValues('StaffID') ? true : false }}
                        getOptionLabel={v =>
                          v?.name || v?.display || v?.value || v
                        }
                        fullWidth
                        value={field.value || null}
                        onChange={(_, data) => field.onChange(data?.name || data?.display || data?.value)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Staff Id"
                            error={errors?.StaffID ? true : false}
                            helperText={
                              errors?.StaffID?.type === "required" ||
                              errors?.StaffID?.type === "optionality"
                                ? ""
                                : errors?.StaffID?.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          gap={2}
          style={{ marginRight: "10px" }}
        >
          {/* {open ? (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setopen(false);
                //   props?.setisMasterSave(false);
                }}
              >
                Open
              </Button>
            </Grid>
          ) : ( */}
            <>
              <Grid item>
                <Button variant="outlined" onClick={() => reset()}>
                  Clear
                </Button>
              </Grid>
              {/* <Grid item>
                <Button variant="outlined" onClick={() => setopen(true)}>
                  Cancel
                </Button>
              </Grid> */}
              <Grid item>
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </Grid>
            </>
          {/* )} */}
        </Grid>
      </form>
      <DevTool control={control} />
    </Paper>
  );
};

export default Predialysis;
