import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { UIColor } from "../../themes/theme";
import { AxiosCall, urls, AlertProps, __tenantid__ } from "../../utils";
import withAllContexts from "../../hoc/withAllContexts";
import actions from "../../redux/actions";
import jwtDecode from "jwt-decode";
import axios from "axios";
import {
  Stack,
  Chip,
  Grid,
  Button,
  Autocomplete,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { AlertContext } from "../../context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CloseIcon from "@material-ui/icons/Close";

var debouncing;
const BillCancelReport = (props) => {
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const navigate = useNavigate();
  const [billCancelData, setBillCancelData] = useState({
    facility: [],
    startdate: "",
    enddate: "",
    user: [],
  });
  const [facilityNameData, setFacilityNameData] = useState([]);
  const [practitionerNameData, setPractitionerNameData] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [error, setError] = useState({});
  const { parent_id } = props;

  const setData = async (name, value) => { 
     let updatedData = { ...billCancelData };
    if (name === "startdate" || name === "enddate") {
        updatedData[name] = dayjs(value).format("YYYY-MM-DD");
      } else {
        updatedData[name] = value;
      }
      setBillCancelData(updatedData);
    if (
        updatedData?.facility &&
        updatedData?.startdate &&
        updatedData?.enddate
      ) {
        // const startDate = updatedData?.startdate;
        // const endDate = updatedData?.enddate;
        const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
        const startDate = new Date(updatedData?.startdate);
        startDate.setHours(0, 0, 0, 0);
        const startEpochTime = getEpochTime(startDate);
  
        // const startDate = billCancelData?.startdate;
        // const endDate = billCancelData?.enddate;
        const endDate = new Date(updatedData?.enddate);
        endDate.setHours(23, 59, 59, 999);
        const endEpochTime = getEpochTime(endDate);
        const facilityIdString =
          updatedData?.facility?.map((item) => item._id).join(",") || "";
        try {
          const response = await dispatch(
            actions.BILL_CANCEL_REPORT_PATIENTID({
              facilityid: facilityIdString,
              startdate: startEpochTime,
              enddate: endEpochTime,
            })
          );
          setPractitionerNameData(response?.payload?.data);
        } catch (error) {}
      }
  };
 
  const validation = () => {
    let data = {
      facility: [],
      startdate: "",
      enddate: "",
    };
    let validation = {
      error: false,
    };

    Object.keys(data).forEach((v) => {

      if (v === "startdate" || v === "enddate") {
        if (billCancelData[v] == null && billCancelData[v] == undefined) {
          validation.error = true;
          validation[v] = true;
        } else {
          if (!(dayjs(billCancelData[v]).isValid())) {
            validation.error = true;
            validation[v] = true;
          } else {
            validation[v] = false;
          }
        }
      }
     
      else if (Array.isArray(data[v])) {
        if (!billCancelData[v]?.length) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      } 
    });
    setError(validation);
    return validation;
  };
  
  const HandleChangeForSearch = async (e, v) => {
    setBillCancelData({ ...billCancelData, facility: v || [] });
  };
  const HandleChangeForUser = (e, v) => {
    setBillCancelData({ ...billCancelData, user: v || [] });
  };
  const GetFacilityId = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.name,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const GetPatientId = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.patientname,
        _id: item.patientid,
      };

      list.push(datatemp);
    }
    return list;
  };
  const facilityName = async () => {
    const response = await dispatch(
      actions.REPORTS_FACILITY_DROPDOWN({
        facilityid: decodedUserinfo?.facilityid || "",
        tenantid: decodedUserinfo?.tenantid,
      })
    );
    setFacilityNameData(response?.payload?.data);
    const defaultFacility = response?.payload?.data.filter(
        (item) => item?._id === decodedUserinfo?.facilityid
      );
      setBillCancelData((prevState) => ({
        ...prevState,
        facility: [
          {
            value: defaultFacility?.[0]?.name,
            _id: defaultFacility?.[0]?._id,
          },
        ],
        startdate: dayjs().format("YYYY-MM-DD"),
        enddate: dayjs().format("YYYY-MM-DD"),
      }));
  };
  const loadPatientData = async () => {
    if (
      billCancelData?.facility &&
      billCancelData?.startdate &&
      billCancelData?.enddate
    ) {
      const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
      const startDate = new Date(billCancelData?.startdate);
      startDate.setHours(0, 0, 0, 0);
      const startEpochTime = getEpochTime(startDate);
      const endDate = new Date(billCancelData?.enddate);
      endDate.setHours(23, 59, 59, 999);
      const endEpochTime = getEpochTime(endDate);
      const facilityIdString =
        billCancelData?.facility?.map((item) => item._id).join(",") || "";
      try {
        const response = await dispatch(
          actions.BILL_CANCEL_REPORT_PATIENTID({
            facilityid: facilityIdString,
            startdate: startEpochTime,
            enddate: endEpochTime,
          })
        );
        setPractitionerNameData(response?.payload?.data);
      } catch (error) {}
    }
  };
  useEffect(() => {
    facilityName();
  }, []);
  useEffect(() => {
    loadPatientData();
  }, [billCancelData?.facility, billCancelData?.startdate, billCancelData?.enddate]);

  function dateToEpoch(dateString) {
    const date = dayjs(dateString);
    date.startOf('day');
    const epochTime = date.valueOf();
    const epochTimeInSeconds = Math.floor(epochTime / 1000);
    return epochTimeInSeconds;
  }


  const generateAndOpenJasperReport = async () => {
    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };

    const startDate = new Date(billCancelData?.startdate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);

    const endDate = new Date(billCancelData?.enddate);
    endDate.setHours(23, 59, 59, 999);
    const endEpochTime = getEpochTime(endDate);

    const startdate = dayjs(billCancelData?.startdate);
    const enddate = dayjs(billCancelData?.enddate);
    if ( enddate < startdate) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "End date should be after the start date",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    let valid = validation()
    if (valid.error){
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Please fill mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    } else {
      const payload = {
        reportid: "d207ab46-2aa8-43ed-ba80-fc15ce6c689f",
        inputparams: {
          "@facilityid": `[${billCancelData?.facility?.map(item => `'${item._id}'`)}]` ?? [],
          "@startdate": startEpochTime,
          "@enddate": endEpochTime,
          "@patientid":  billCancelData?.user?.length > 0
          ? `['${billCancelData?.user?.map((item) => item._id).join("','")}']`
          : "[]",
        //   `[${billCancelData?.user.map(item => `'${item._id}'`)}]` ?? "[]",
        },
  
        result: [],
      };
      AxiosCall("post", urls.jasperReport, payload, header).then(async (res) => {
        if (res?.downloadUrl) {
          const response = await axios.get(res?.downloadUrl, {
            method: "GET",
            responseType: "blob",
          });
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfDataUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfDataUrl, "_blank");
          props?.handleModalClose();
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "There is no report available.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          props?.handleModalClose();
        }
      });
    }
   navigate(AppRoutes.dashboard)
  };
  useEffect(() => {
    if (downloadUrl) {
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.target = "_blank";
      downloadLink.download = "report.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [downloadUrl]);
  const helperTextMsg = "This field is required!"
  const errorMessage = React.useMemo(() => {
    if (error?.startdate || error?.enddate) {
       return 'Please select a date';
    }
  }, [error]);
  return (
    <div>
          <div
            style={{
              backgroundColor: "#fff",
              outline: "none",
              width: 500,
              padding: "10px",
              paddingLeft: "15px",
            }}
          >
            <IconButton
            aria-label="close"
            onClick={props?.handleModalClose}
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <CloseIcon />
          </IconButton>
            <Grid container spacing={2}>
            <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Typography variant="h5">Bill Cancel Report</Typography>
              </Grid>
              <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <div>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={GetFacilityId(facilityNameData) ?? []}
                    getOptionLabel={(option) => option?.value ?? ""}
                    limitTags={2}
                    hoverColor={UIColor.primaryColor.color}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.value}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        required={true}
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="search facility"
                        label="Facility"
                        hoverColor={UIColor.primaryColor.color}
                        sx={{
                          "& .MuiAutocomplete-inputRoot": {
                            borderRadius: "8px",
                            border: "1px  #E0E0E0",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={error?.facility}
                        helperText={error?.facility ? helperTextMsg : ""}
                      />
                    )}
                    onChange={(e, v) => {
                      HandleChangeForSearch(e, v);
                    }}
                    value={billCancelData?.facility || []}
                  />
                </div>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      value={
                        billCancelData?.startdate
                          ? dayjs(billCancelData?.startdate)
                          : billCancelData?.startdate || null
                      }
                      onChange={(e, v) => setData("startdate", e)}
                      onError={error?.startdate}
                      slotProps={{
                        textField: {
                          helperText: errorMessage,
                          error: error?.startdate ? true : false,
                          size: "small",
                          required: true,
                          fullWidth: true,
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      value={
                        billCancelData?.enddate
                          ? dayjs(billCancelData?.enddate)
                          : billCancelData?.enddate || null
                      }
                      onChange={(e) => setData("enddate", e)}
                      onError={error?.enddate}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                      slotProps={{
                        textField: {
                          helperText: errorMessage,
                          error: error?.enddate ? true : false,
                          size: "small",
                          required: true,
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <div>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={GetPatientId(practitionerNameData) ?? []}
                    getOptionLabel={(option) => option?.value ?? ""}
                    limitTags={2}
                    hoverColor={UIColor.primaryColor.color}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.value}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="search patient"
                        hoverColor={UIColor.primaryColor.color}
                        style={{ paddingBottom: "20px" }}
                        sx={{
                          "& .MuiAutocomplete-inputRoot": {
                            borderRadius: "8px",
                            border: "1px  #E0E0E0",
                          },
                        }}
                        label="Patient"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={error?.user}
                        helperText={error?.user ? helperTextMsg : ""}
                      />
                    )}
                    onChange={(e, v) => {
                      HandleChangeForUser(e, v);
                    }}
                    value={billCancelData?.user || []}
                  />
                </div>
              </Grid>
            </Grid>

            <Stack
              container
              md={12}
              lg={12}
              spacing={3}
              direction="row"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                size="medium"
                style={{
                  color: UIColor.primaryColor.color,
                  borderColor: UIColor.primaryColor.color,
                }}
                onClick={props?.handleModalClose}
              >
                Cancel
              </Button>

              <Button
                size="medium"
                style={{
                  color: "#fff",
                  background: UIColor.primaryColor.color,
                }}
                variant="contained"
                onClick={() => generateAndOpenJasperReport()}
              >
                Submit
              </Button>
            </Stack>
          </div>
    </div>
  );
};

export default withAllContexts(BillCancelReport);
