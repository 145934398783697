import React, { useContext, useEffect, useState } from "react";
import TableComponent from "../../cashierBillingDetails/components/tableComponent";
import actions from "../../../redux/actions";
import { AlertProps, AxiosCall, convertToWords, dbName, urls } from "../../../utils";
import { useDispatch } from "react-redux";
import { AlertContext } from "../../../context";
import { Button, Col, Div, H6, Row, TextInput } from "qdm-component-library";
import { UIColor } from "../../../themes/theme";
import axios from "axios";
import jwtDecode from "jwt-decode";
import DataGridComponent from "./tableComponent";
import { Select, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Box, Grid, Paper } from "@material-ui/core";
import { Selection } from "../../../components";
const dataType = [
  // { name: "checked", type: "CHECKBOX", header: "All" },
  // { name: "s.no", type: "INCREMENT", header: "S.No" },
  { name: "receiptno", type: "TEXT", header: "scroll", width: 100 },
  {
    name: "receiptdate",
    type: "EPOCH_DATE_DISPLAY",
    header: "scroll",
    width: 120,
  },
  { name: "advancetype", type: "TEXT", header: "scroll", width: 130 },
  { name: "advno", type: "TEXT", header: "scroll", width: 110 },
  { name: "advanceamount", type: "TEXT", header: "scroll", width: 130 },
  { name: "utilizedamt", type: "TEXT", header: "scroll", width: 110 },
  { name: "refundamt", type: "TEXT", header: "scroll", width: 110 },
  { name: "balanceamt", type: "TEXT", header: "scroll", width: 110 },
  {
    name: "advancereceipt",
    type: "ADVANCERECEIPT",
    header: "scroll",
    width: 130,
  },
  // {
  //   name: "balancereceipt",
  //   type: "BALANCERECEIPT",
  //   header: "scroll",
  //   width: 130,
  // },
];
let styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    border: "1px solid #E0E0E0",
  },
  containedButton: {
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    borderRadius: 8,
    padding: "8px 15px",
    minWidth: 100,
    fontFamily: "pc_medium !important",
    fontSize: 14,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "#FF0000",
  },
};
const AdvanceViewBill = (props) => {
  const userInfo = localStorage.getItem("UserInfo");
  const alertContext = useContext(AlertContext);
  const decodedUserinfo = jwtDecode(userInfo);
  const practitionerId = decodedUserinfo?.practitionerid;
  const Header = [
    // "All",
    // "S.No",
    "Receipt No.",
    "Receipt Date",
    "Advance Type",
    "Advance No",
    "Advance Amount ",
    "Utilization",
    "Refund Amount",
    "Balance",
    "Advance Receipt",
    // "Refund Receipt",
  ];
  const initalValue = {
    totalPayAmount: 0,
    Reason: ""
}
  const [advanceData, setAdvanceData] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [collectInfo, setCollectInfo] = useState(initalValue);
  const [initalLoad, setInitalLoad] = useState(true);

  const advanceDatas = async (_id) => {
    const patientId = _id;
    const data = await dispatch(actions.ADVANCE_PAYMENT_READ({ patientId }));

    if (data?.payload?.data?.length === 0) {
      alertContext?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "No Data Found",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setTimeout(() => {
        props?.setAdvanceOpen(false);
      }, 1000);
    }

    let listArrResp = [];
    let selModal = [];
    data?.payload?.data?.forEach((v, i) => {
      let obj = { ...v, isChecked: true };
      listArrResp.push(obj);
      let listCount = i + 1;
      selModal.push(listCount.toString());
    });

    setAdvanceData(listArrResp);
    setSelectionModel(selModal);
  };

  const generateSequentialIds = (length) => {
    return Array.from({ length }, (_, i) => (i + 1).toString());
  };

  const ids = generateSequentialIds(advanceData.length);

  const rowData = advanceData?.map((patient, i) => {
    return {
      id: ids[i],
      receiptno: patient?.receiptno,
      receiptdate: patient?.receiptdate,
      advanceid: patient?.advanceid,
      advanceamount: patient?.advanceamount,
      balanceamt:patient?.balanceamt,
      utilizedamt:patient?.utilizedamt,
      refundamt:patient?.refundamt,
      advno:patient?.advno,
      advancetype:patient?.advancetype?.display,
      isChecked: false,
    };
  });

  const dispatch = useDispatch();

  const alert = useContext(AlertContext);
  const handleSelectedPrint = (e, value, index) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const payload = {
      reportid: process.env.REACT_APP_ADVANCE_RECEIPT_REPORT_ID,
      inputparams: {
        "@advanceid": value?.advanceid,
        "@loginpract": practitionerId,
      },

      result: [],
    };
    AxiosCall("post", urls.jasperReport, payload, header)
      .then(async (res) => {
        const response = await axios.get(res?.downloadUrl, {
          method: "GET",
          responseType: "blob",
        });
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUrl, "_blank");
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: "No data found for the given inputs",
          severity: "error",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      });
  };
  React.useEffect(() => {
    if (props?.patientDetails?._id) {
      advanceDatas(props?.patientDetails?._id);
    }
  }, [props?.patientDetails]);
  const handleSelectionModelChange = (newSelection) => {
    let isNew = null;
    if (!newSelection?.length > 0) {
      isNew = true;
      let listArrResp = [];
      advanceData?.map((v, i) => {
        let obj = v;
        obj.isChecked = false;
        listArrResp.push(obj);
      });
      setSelectionModel(newSelection);
      setAdvanceData(listArrResp);
      setInitalLoad(false);
    } else if (isNew === true) {
      isNew = false;
      let listArrResp = [];
      advanceData?.map((v, i) => {
        let obj = v;
        obj.isChecked = true;
        listArrResp.push(obj);
      });
      setSelectionModel(newSelection);
      setAdvanceData(listArrResp);
      setInitalLoad(false);
    }
    if (newSelection?.length < selectionModel?.length) {
      //uncheck
      const deselectedRowIds = selectionModel?.filter(
        (id) => !newSelection.includes(id)
      );
      const deselectedRowData = deselectedRowIds?.map((rowId) =>
        rowData?.find((row) => row.id === rowId)
      );
      const updatedRows = advanceData?.map((row) => {
        const isDeselected = deselectedRowData?.some(
          (deselectedRow) => deselectedRow?.receiptno === row?.receiptno
        );
        if (isDeselected) {
          return { ...row, isChecked: false };
        }
        return row;
      });
      setSelectionModel(newSelection);
      setAdvanceData(updatedRows);
      setInitalLoad(false);
    } else if (newSelection?.length > selectionModel?.length) {
      //check
      let arr = [];
      advanceData?.filter((id, i) => {
        let val = i + 1;
        arr.push(val.toString());
      });
      const deselectedRowIds = arr?.filter((id) => newSelection.includes(id));
      const deselectedRowData = deselectedRowIds?.map((rowId) =>
        rowData?.find((row) => row.id === rowId)
      );
      const updatedRows = advanceData?.map((row) => {
        const isDeselected = deselectedRowData?.some(
          (deselectedRow) => deselectedRow?.receiptno === row?.receiptno
        );
        if (isDeselected) {
          return { ...row, isChecked: true };
        }
        return row;
      });
      setSelectionModel(newSelection);
      setAdvanceData(updatedRows);
      setInitalLoad(false);
    }
  };
  function calculateTotalCollection(data) {
    const totalPayment = data
      .filter((item) => item.isChecked)
      .reduce((total, item) => total + Number(item.balanceamt), 0);
   setCollectInfo(  
      (prevState) => ({
        ...prevState,      
        totalPayAmount:totalPayment
      })); 
      return totalPayment;
     }
      

  function calculateTotalBalance(data) {
    let totalBalanceAmount = 0;
    const totalPayment = data.reduce(
      (total, item) => total + Number(item.advanceamount),
      0
    );
    totalBalanceAmount = (props.totCollectAmt - totalPayment).toFixed(2);
    return totalBalanceAmount;
  }

  const setData = (name, value, total) => {
    let updatedData = { ...collectInfo };
    const totColamt = calculateTotalCollection(advanceData);
    if (name === "totalPayAmount" && Number(value) > totColamt) {  
        alert.setSnack({
          ...alert,
          open: true,
          msg: "Payment Amount not greater then paid amount",
          severity: "error",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
    } else {
      updatedData[name] = value;
    }
    setCollectInfo({ ...updatedData });
  };
  
  
  React.useEffect(() => {
    if (advanceData.length > 0 && !initalLoad) {
      calculateTotalCollection(advanceData);
      setInitalLoad(true);
    }
  }, [advanceData,initalLoad]);
  const handlesubmit = async (value) => {
    
    if (collectInfo?.Reason === "") {
      alert.setSnack({
        ...alert,
        open: true,
        msg: "Please fill the cancellation reason",
        severity: "error",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      if (collectInfo?.totalPayAmount=== 0) {
        alert.setSnack({
          ...alert,
          open: true,
          msg: "Please fill the Payment amount",
          severity: "error",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }}
     else if(advanceData.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        msg: "Please select a Receipt for cancellation !",
        severity: "error",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      let doc = [];
      advanceData.forEach((v) => {
        if (v?.isChecked) {
          doc.push({
              receiptno: v?.receiptno,
              receiptdate: v?.receiptdate,
              advanceamount: v?.advanceamount,
              advanceid: v?.advanceid,
              advancetype: v?.advancetype?._id,
              balanceamt: v?.balanceamt,
              utilizedamt: v?.utilizedamt,
              refundamt: v?.refundamt,
          });
      }
      });

      let data = await dispatch(
        actions.ADVANCE_REFUND({
          tenantid: decodedUserinfo?.tenantid,
          facilityid: decodedUserinfo?.facilityid,
          patientid: props?.patientDetails?._id,
          totalRefundAmount: collectInfo?.totalPayAmount,
          amtinlocal: convertToWords(Number(collectInfo?.totalPayAmount)),
          amtineng: convertToWords(Number(collectInfo?.totalPayAmount), true),
          advanceDetails: doc,
        })
      );
      advanceDatas(props?.patientDetails?._id)
      props?.setAdvanceOpen(false);
      if(data?.payload?.data?.response?.response?.Code == 201){
        alert.setSnack({
          ...alert,
          open: true,
          msg: data?.payload?.data?.response?.message,
          severity: "success",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else if (data?.payload?.data?.error) {
        if (data?.payload?.data?.[0]?.error) {
          alert.setSnack({
            ...alert,
            open: true,
            msg: data?.payload?.data?.[0]?.message,
            severity: "error",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            msg: data?.response?.message,
            severity: "success",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
      }
    }
};
const {parent_id} = props
   return (
    <Grid container spacing={2}>
      <Box width="100%" display="flex" justifyContent="center">
        <Paper
          id={`${parent_id}referral_parent_parent_paper`}
          className="pi-paper"
        >
          <Grid container item xs={12} sm={12} justify="center" spacing={2}>
            <div
              style={{
                maxHeight: "80vh",
                overflow: "auto",
                width: "100%",
              }}
            >
              <Typography variant="h5" style={{ padding: 5 }}>
                Advance Receipt/Print
              </Typography>
              <DataGridComponent
                tableData={rowData}
                Header={Header}
                dataType={dataType}
                handleSelectedPrint={handleSelectedPrint}
                handleSelectionModelChange={handleSelectionModelChange}
                selectionModel={selectionModel}
              />
            </div>
            <Grid container item xs={5} sm={5} justify="center" spacing={2}>
              <Grid item xs={12} sm={5}>
                <Typography
                  className={"pc_regular"}
                  style={{
                    color: "#646464",
                    fontSize: 17,
                    margin: 0,
                    marginBottom: 5,
                  }}
                >
                  Payment Amount <span style={{ color: "red" }}>*</span> 
                  {/* (
                  {decodedUserinfo?.currencyinfo?.currencysymbol ||
                    decodedUserinfo?.currencyinfo?.currencycode}
                  ){" "} */}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  variant={"outlined"}
                  hoverColor={UIColor.primaryColor.color}
                  placeholder="amount"
                  style={{
                    borderRadius: 8,
                    borderColor: UIColor.lineBorderFill.color,
                    height: 36,
                    padding: "5px 10px",
                  }}
                  isRequired
                  value={collectInfo?.totalPayAmount}
                  onChange={(e) => setData("totalPayAmount", Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography
                  className={"pc_regular"}
                  style={{
                    color: "#646464",
                    fontSize: 17,
                    margin: 0,
                    marginBottom: 5,
                  }}
                >
                  Cancel Reason <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  variant={"outlined"}
                  hoverColor={UIColor.primaryColor.color}
                  placeholder="Add reason"
                  style={{
                    borderRadius: 8,
                    borderColor: UIColor.lineBorderFill.color,
                    height: 36,
                    padding: "5px 10px",
                  }}
                  isRequired
                  value={collectInfo?.Reason}
                  onChange={(e) => setData("Reason", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                <Button
                  name={"Refund"}
                  type="button"
                  className="pc_medium"
                  variant="outlined"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "8px 16px",
                    marginBottom: "10px",
                    backgroundColor: UIColor?.primaryColor?.color,
                    border: "none",
                    borderRadius: 8,
                  }}
                  onClick={handlesubmit}
                >
                  Refund
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Grid>
  );
};

export default AdvanceViewBill;
