import {
  Div,
  Avatar,
  Text,
  Button,
  TermsAndConditions,
} from "qdm-component-library";
import { UIColor } from "../../themes/theme";
import { getImgUrl, getUserInfo, utcTOLocal } from "../../utils";
import moment from "moment";
import { useStyles } from "./styles";
import DoctorAndAppointmentSlot from "./components/appointmentDetails/doctorAndAppointmentSlot/doctorAndAppointmentSlot";
import AppointmentReason from "./components/appointmentDetails/reasonForAppointment";
import Reasonforappointment from "../../assets/img/svg/reason_for_appointment.svg";
import ModifyAppointment from "../../assets/img/svg/modify_appointment.svg";
import ReportAndAdditionalServices from "./components/appointmentDetails/reportAndAdditionalServices";
import BillSummary from "./components/appointmentDetails/billSummary";
import AppointmentInstructions from "./components/appointmentDetails/appointmentInstructions";
import { createRef, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//import { actions } from "primarycare-binder";
import actions from "../../redux/actions";
import { useDispatch } from "react-redux";
import { AlertProps } from "../../utils";
import { AppRoutes } from "../../router/routes";
import {
  checkWithCasbin,
  getMobileNumber,
  checkError,
  ValidateEmail,
  uploadImage,
  mobileNumberValidation,
  upsertPerson,
  filterListingData,
} from "../../utils";
import PatientDetailCard from "./components/appointmentDetails/patientDetailCard";
import {
  AlertContext,
  BackdropContext,
  PatientDetailsContext,
  SetPatientDetailsContext,
} from "../../context";
import jwtDecode from "jwt-decode";
const casbinjs = require("casbin.js");

const AppointmentDetails = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const overlayNode = createRef();
  const patientDetail = useContext(PatientDetailsContext);
  const liveLocation = useLocation();
  console.log("liveLocation", liveLocation);
  const location = props.location;
  console.log("location", location);
  const { state, setState } = props;
  const alert = useContext(AlertContext);
  const patientDetails = useContext(PatientDetailsContext);
  const classes = useStyles();
  const setPatientDetailsContext = useContext(SetPatientDetailsContext);
  const info = getUserInfo();
  const IsModifyUser = patientDetails?.bookFlag?.modifyBook;
  const newBook = props?.location?.state?.newBook;
  const backdrop = useContext(BackdropContext);
  // Functions
  const handleModalOpen = async () => {
    let personId_ = location?.state?.selectedUser?.userId;
    if (!personId_) {
      let mobileNo = location?.state?.selectedUser?.mobile;
      const persondata = await dispatch(
        actions.READ_PERSON({
          // phone: mobileNo,
          username: mobileNo,
          tenantid: "",
          facilityid: "",
        })
      );
      personId_ = persondata?.payload?.data?.pid;
    }
    let start_ = state.selectedSlot?.info?.dateStart ?? 0;
    let res = await dispatch(
      actions.CHECK_APPOINTMENT_AGAINST_PATIENT({
        personid: personId_,
        start: start_,
        tenantid: "",
        facilityid: "",
      })
    );
    if (res?.payload?.data?.Code === 201) {
      if (
        Array.isArray(res?.payload?.data?.result) &&
        res?.payload?.data?.result?.length > 0
      ) {
        if (
          res?.payload?.data?.result?.[0]?.allowToBookAppointment === false &&
          res?.payload?.data?.result?.[0]?.doctorName?.length > 0
        ) {
          setState({
            ...state,
            modalOpen: true,
            doctorName: res?.payload?.data?.result?.[0]?.doctorName ?? "",
            doctorCount: res?.payload?.data?.result?.length,
          });
        }
      } else {
        handleBooking();
      }
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };

  const handleModalClose = () => {
    setState({
      ...state,
      modalOpen: false,
    });
  };
  const handleMultiAppointmentChange = (val, key1, key2, key3, key4) => {
    const statedata = { ...state };
    console.log(val, key1, key2, key3, key4, "MultiP90");
    if (key4) {
      if (key4 === "deleteAllDays") {
        statedata["multiApppointmentInfo"][key1][key2][key3] = [];
      }
    }
    if (key2) {
      if (key3) {
        statedata["multiApppointmentInfo"][key1][key2][key3] = val;
      } else {
        statedata["multiApppointmentInfo"][key1][key2] = val;
      }
    } else {
      statedata["multiApppointmentInfo"][key1] = val;
      if (key1 === "info") {
        statedata["multiApppointmentInfo"]["slotObj"] = {};
      }
    }

    let stateCons = statedata;

    setState({
      ...statedata,
    });
  };

  const updateMultiSlots = (val) => {
    setState({
      ...state,
      fromListing: false,
      isMultipleAppointment: val,
    });
  };

  const getPathName = (dateStart) => {
    const walkInCondition =
      moment()
        .startOf("day")
        .diff(moment(dateStart * 1000), "d") === 0 &&
      state.selectedSlot?.info?.maxwalkin > 0;
    let isWalkin = state?.permissionData?.write?.indexOf("walkIn") > -1;
    let returnPath = !walkInCondition
      ? AppRoutes.confirmBooking
      : isWalkin
      ? AppRoutes.visitRegistration
      : AppRoutes.confirmBooking;
    if (returnPath === AppRoutes.visitRegistration) {
      if (!location.state?.patientId) {
        returnPath = AppRoutes.confirmBooking;
      }
    }
    return returnPath;
  };

  const handleBooking = async () => {
    handleModalClose();

    if (location?.state?.modifyUser && !location?.state?.newBook) {
      if (!state.modifyAppointmentReason?.value) {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please select reason for modification",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return;
      }
    }
    changeState("meageget", {});
    if (
      (!location?.state?.modifyUser &&
        state?.permissionData?.write?.indexOf(
          "appointmentBookingConfirmation"
        ) > -1 &&
        !location?.state?.unregister?.is_Unregister) ||
      (state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") >
        -1 &&
        location?.state?.newBook)
    ) {
      let newMNum = location?.state?.userNo;
      if (location?.state?.modifyUser && location?.state?.newBook) {
        const appointmentData = await dispatch(
          actions.APPOINTMENT_READ({
            appointmentid: location?.state?.appointMentInfo?.id,
            tenantid: "",
            facilityid: "",
          })
        );
        const appointMentInfo = JSON.parse(
          JSON.stringify(appointmentData?.payload?.data)
        );
        changeState("oldSlot", {
          ...state.oldSlot,
          appInfoFetched: appointMentInfo,
        });
        newMNum = appointMentInfo?.AppointmentNotification?.[0]?.mobile;
      }
      changeScreen();

      return;
    }

    if (location?.state?.unregister?.is_Unregister) {
      await dispatch(
        actions.REGISTER_USERS({
          mobileNo: getMobileNumber(location?.state?.unregister?.mobileNo),
          firstName: location?.state?.unregister?.patientName,
          lastName: "",
          emailId: location?.state?.unregister?.emailId,
        })
      );
      const { isError, errMsg } = checkError(props.registerUsers);
      if (isError) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      } else {
        changeScreen();
      }
    } else {
      let UploadedFiles = [];
      let { uploadFiles } = state;
      UploadedFiles = await uploadImage(uploadFiles);
      console.log(state.selectedSlot, "selectedSlot");
      if (state.selectedSlot?.info) {
        if (!!location?.state?.modifyUser) {
          if (location?.state?.newBook && !state.callcen) {
            const userInfo = getUserInfo();
            if (userInfo.type === "Token") {
              if (state.iagree) {
                const {
                  SpecialtyID = [],
                  PersonID = [],
                  practitionerID = [],
                  AppointmentNotification,
                  HealthcareServiceID,
                } = location?.state?.appointMentInfo;
                const { info = {}, hospitalInfo = {} } = state.selectedSlot;
                console.log("info", info);
                const appointMentInput = {
                  slotId: [info.value || 0], //FIXME - no value
                  start: info.dateStart || "",
                  end: info.dateEnd || "",
                  orgid: hospitalInfo?.org_Id,
                  orgtype: hospitalInfo?.orgType,
                  resourcetype: info.resourcetype || "",
                  resourcerole: info.resourcerole || "",
                  // resourcecode: info.resourcecode || "",
                  resourcecode: info?.resourcecode?.[0], //FIXME - check araay or not array
                  info: state.appointmentReason,
                  specialtyID: SpecialtyID, //FIXME -
                  personID: PersonID, //FIXME -
                  practitionerId: practitionerID || info?.resourcecode?.[0], //FIXME -
                  mobile: AppointmentNotification?.[0]?.mobile || "", //FIXME -
                  email: AppointmentNotification?.[0]?.patientEmail || "", //FIXME -
                  consultationMode: state.consultationMode.value, //FIXME
                  healthCareId: HealthcareServiceID, //FIXME
                  report_docs: UploadedFiles, //FIXME
                  patientInstruction: state.patientInstruction, //FIXME
                  // doubt values
                  AppointmentNotification: [
                    {
                      practitionerEmail: "",
                      mobile: "+917566756675",
                      patientEmail: "faiha@gmail.com",
                    },
                  ],
                  identifier: [],
                  status: true,
                  cancelationReason: [],
                  reasonCode: [],
                  reasonReference: [],
                  priority: 1,
                  supportingInformation: [],
                  appointmentReason: [],
                  basedOn: [],
                  participant: [],
                  patientInstruction: "",
                  requestedPeriod: [
                    {
                      start: 1697102100,
                      end: 1697103000,
                    },
                  ],
                  bookedby: "",
                  //FIXME
                };
                if (
                  moment()
                    .startOf("day")
                    .diff(moment(info.dateStart * 1000), "d") === 0 &&
                  state.selectedSlot?.info?.maxwalkin > 0
                ) {
                  appointMentInput["appointmentType"] = "walkin";
                }
                const appInfo = await dispatch(
                  actions.APPOINTMENT_CREATE(appointMentInput)
                );
                const { isError, errMsg } = checkError(appInfo.payload);
                if (isError) {
                  alert?.setSnack?.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: errMsg,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                    tone: true,
                  });
                } else {
                  if (location?.state?.isFollowUp) {
                    await dispatch(
                      actions.SET_FOLLOWUP_SERVICE({
                        data: {
                          status: "booked",
                          editId: location?.state?.followUpId,
                        },
                      })
                    );
                  }
                  const appointmentId =
                    appInfo.payload?.data?.appointment_response?.appointment
                      ?.Result?.[0]?.properties?.doc?.id || 0;
                  setState({ ...state, sidebar: false }, () => {
                    // navigate("/confirm_booking");
                    props.visitData.setVisitDetails({
                      encounter: {
                        mode:
                          state?.consultationMode?.value ===
                          "Direct Consultation"
                            ? "direct"
                            : state?.consultationMode?.value ===
                              "Video Consultation"
                            ? "video"
                            : "direct",
                      },
                      practitioner: {},
                      device: {},
                      helthcare: {},
                      accompained: {},
                    });
                    navigate(getPathName(info.dateStart), {
                      state: {
                        fromListing: true,
                        personId: location?.state?.userId,
                        mobileNo: location?.state?.mobileNo,
                        appointmentId: appointmentId,
                        patientId: location?.state?.patientId,
                        from:
                          moment()
                            .startOf("day")
                            .diff(moment(info.dateStart * 1000), "d") === 0 &&
                          state.selectedSlot?.info?.maxwalkin > 0
                            ? 1
                            : null,
                        walkin: !(
                          moment()
                            .startOf("day")
                            .diff(moment(info.dateStart * 1000), "d") === 0 &&
                          state.selectedSlot?.info?.maxwalkin > 0
                        ),
                      },
                    });
                  });
                }
              }
            }
            return;
          }
          const appointmentData = await dispatch(
            actions.APPOINTMENT_READ({
              appointmentid: location?.state?.appointMentInfo?.id,
              tenantid: "",
              facilityid: "",
            })
          );
          const appointMentInfo = JSON.parse(
            JSON.stringify(appointmentData?.payload?.data)
          );
          changeState("oldSlot", {
            ...state.oldSlot,
            appInfoFetched: appointMentInfo,
          });
          const status = checkError(appointmentData?.payload);
          if (status.isError) {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: status.errMsg,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          } else {
            changeScreen();
          }
          return;
        }
        const userInfo = getUserInfo();
        if (userInfo.type === "Token") {
          if (state.iagree) {
            let personId = location?.state?.selectedUser?.userId;
            if (!personId) {
              let mobileNo = location?.state?.selectedUser?.mobile;
              const persondata = await dispatch(
                actions.READ_PERSON({
                  //phone: mobileNo,
                  username: mobileNo,
                  tenantid: "",
                  facilityid: "",
                })
              );
              personId = persondata?.payload?.data?.pid;
            }
            const { info = {}, hospitalInfo = {} } = state.selectedSlot;
            if (state.isMultipleAppointment) {
              var appointMentInputArr = [];
              const promArr = state.multiApppointmentInfo.data.map(
                (singleSlot) => {
                  appointMentInputArr.push({
                    slotId: [singleSlot.id || 0],
                    slotStart: singleSlot.start || "",
                    slotEnd: singleSlot.end || "",
                    ...singleSlot.resourceInfo,
                    orgId: hospitalInfo?.org_Id,
                    orgType: hospitalInfo?.orgType,
                    info: state.appointmentReason,
                    specialtyID:
                      location?.state?.specialities?.map(
                        (spec) => spec?.value
                      ) || [],
                    personID: [personId],
                    practitionerId: hospitalInfo?.isClinic
                      ? []
                      : [hospitalInfo?.id],
                    mobile: location?.state?.selectedUser?.mobile || "",
                    email: location?.state?.selectedUser?.email || "",
                    consultationMode: state.consultationMode.value,
                    healthCareId: info.healthCareId,
                    report_docs: UploadedFiles,
                    patientInstruction: state.patientInstruction,
                  });
                }
              );
              let resArr = [];
              const resMultiData = await MultiBookingAppointmentLoopigUpdate(
                appointMentInputArr,
                0,
                resArr
              );
              const appResponse = await Promise.all(resMultiData);
              const { isError, errMsg } = checkError(appResponse?.[0]?.payload);
              if (isError) {
                alert?.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: errMsg,
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                  tone: true,
                });
              } else {
                setState({ ...state, sidebar: false }, () => {
                  navigate(AppRoutes.confirmBooking, {
                    state: {
                      // personId: location?.state?.userId,
                      // mobileNo: location?.state?.mobileNo,
                      // appointmentId: appResponse,
                      appointmentInfo: appResponse,
                      // patientId: location?.state?.patientId,
                      // walkin: location?.state?.walkin,
                      isMulti: true,
                      fromListing: location?.state?.unregister?.is_Unregister
                        ? false
                        : true,
                    },
                  });
                });
              }
            } else {
              console.log("info1", info);
              const appointMentInput = {
                slotId: [info.value || 0],
                slotStart: info.dateStart || "",
                slotEnd: info.dateEnd || "",
                orgId: hospitalInfo?.org_Id,
                orgType: hospitalInfo?.orgType,
                resourcetype: info.resourcetype || "",
                resourcerole: info.resourcerole || "",
                resourcecode: hospitalInfo?.slots[0]?.resource_id,
                info: state.appointmentReason,
                specialtyID:
                  location?.state?.specialities?.map((spec) => spec?.value) ||
                  [],
                personID: [personId],
                practitionerId: hospitalInfo?.isClinic
                  ? []
                  : [hospitalInfo?.id],
                mobile: location?.state?.selectedUser?.mobile || "",
                email: location?.state?.selectedUser?.email || "",
                consultationMode: state.consultationMode.value,
                healthCareId: info.healthCareId,
                report_docs: UploadedFiles,
                patientInstruction: state.patientInstruction,
              };
              if (
                moment()
                  .startOf("day")
                  .diff(moment(info.dateStart * 1000), "d") === 0 &&
                state.selectedSlot?.info?.maxwalkin > 0
              ) {
                appointMentInput["appointmentType"] = "walkin";
              }

              const appInfo = await dispatch(
                actions.APPOINTMENT_CREATE(appointMentInput)
              );
              const { isError, errMsg } = checkError(appInfo.payload);
              if (isError) {
                alert?.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: errMsg,
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                  tone: true,
                });
              } else {
                if (location?.state?.referral) {
                  const payload = [
                    {
                      filter: { _id: location?.state?.refferalId },
                      doc: {
                        recipientBookingReqd: false,
                      },
                    },
                  ];
                  await dispatch(
                    actions.ORGANIZATION_REFERRAL_REGISTRATION({
                      payloaddata: payload[0],
                    })
                  );
                }
                const appointmentId =
                  appInfo.payload?.data?.appointment_response?.appointment
                    ?.Result?.[0]?.properties?.doc?.id || 0;
                setState({ ...state, sidebar: false }, () => {
                  // navigate("/confirm_booking");
                  props.visitData.setVisitDetails({
                    encounter: {
                      mode:
                        state?.consultationMode?.value === "Direct Consultation"
                          ? "direct"
                          : state?.consultationMode?.value ===
                            "Video Consultation"
                          ? "video"
                          : "direct",
                    },
                    practitioner: {},
                    device: {},
                    helthcare: {},
                    accompained: {},
                  });
                  navigate(getPathName(info.dateStart), {
                    state: {
                      personId: location?.state?.userId,
                      mobileNo: location?.state?.mobileNo,
                      appointmentId: appointmentId,
                      fromListing: true,
                      patientId: location?.state?.patientId,
                      from:
                        moment()
                          .startOf("day")
                          .diff(moment(info.dateStart * 1000), "d") === 0 &&
                        state.selectedSlot?.info?.maxwalkin > 0
                          ? 1
                          : null,
                      walkin: !(
                        moment()
                          .startOf("day")
                          .diff(moment(info.dateStart * 1000), "d") === 0 &&
                        state.selectedSlot?.info?.maxwalkin > 0
                      ),
                      appointmentReason: state.appointmentReason,
                      // Ispatient: true
                    },
                  });
                });
              }
            }
          }
        }
      }
    }
  };

  const MultiBookingAppointmentLoopigUpdate = async (arr, index, resArr) => {
    let Data = [];
    let res = await dispatch(actions.APPOINTMENT_CREATE(arr[index]));
    Data.push(res);
    if (arr[index + 1]) {
      let resarr = await MultiBookingAppointmentLoopigUpdate(arr, index + 1);
      Data = [...Data, ...resarr];
    }
    return Data;
  };

  const changeGuestInfo = (type, field, val) => {
    const state = state;
    state[type][field] = val;
    setState(state);
  };

  const changeState = (key, value) => {
    if (key === "data") {
      setState({
        ...state,
        [key]: value,
        loading: false,
      });
    } else {
      setState({
        ...state,
        [key]: value,
      });
    }
  };

  const getDocterListingDetails = async ({
    hospital,
    specialities,
    latitude,
    longtitude,
    date,
    doctorId,
    apptypecode
  }) => {
    const promArr = [
      dispatch(
        actions.DOCTER_LISTING({
          hospital: hospital,
          specialities,
          latitude,
          longtitude,
          date,
          doctorId,
          apptypecode
        })
      ),
      dispatch(actions.GET_ALL_LANGS()),
    ];
    await Promise.all(promArr);
    setState(
      {
        ...state,
        data: [],
        Tempdata: [],
      },
      () => {
        changeState("allLang", props?.allLanguages?.data || []);
        changeState("data", props?.docterListing?.data || []);
        changeState("Tempdata", props?.docterListing?.data || []);
      }
    );
  };

  async function start() {
    await dispatch(actions.GET_COUNTRY_CODE_LIST());
    let permissionData_ = await checkWithCasbin(["appointmentconfirmation"]);
    let permissionData__ = await checkWithCasbin(["bookAppointment"]);
    setState({
      ...state,
      permissionDa: permissionData_,
      permissionData: permissionData__,
    });
    const Arr = localStorage.getItem("role_data");
    const arr1 = JSON.parse(atob(Arr))[localStorage.getItem("role_name")]
      .access;
    const permission = { callcenter: arr1 };
    const authorizer = new casbinjs.Authorizer("manual", permission);
    authorizer.setPermission(permission);
    const result = await authorizer.can("callcenter", "4");
    setState({ ...state, callcen: result });

    const patientID = location?.state?.patientId || 0;
    const patientdata = await dispatch(
      actions.ORGANIZATION_FILTER_BY_PATIENT({
        patientid: location?.state?.patientId,
      })
    );
    const referral_patientdata = await dispatch(
      actions.GET_FERERRAL({
        patientid: location?.state?.patientId,
        reffId: location?.state?.refferalId,
      })
    );
    setState({
      ...state,
      Persondata: patientdata?.payload?.data,
      referralData: referral_patientdata?.payload?.data,
    });
    if (actions.GET_FINANCIAL) {
      const fin_data = await dispatch(
        actions.GET_FINANCIAL({ patientId: patientID })
      );
      if (fin_data?.payload?.data) {
        const fin_DATA = fin_data?.payload?.data?.map((v) => {
          const setdata = v?.payersprioritycoverage?.[0];
          return {
            pic: "images/Group 90507.svg",
            name: null,
            care_type: setdata?.guarantorid,
            title_name: setdata?.receivertype + ", " + setdata?.policyid,
          };
        });
        setState({
          ...state,
          selectOption: fin_DATA[0],
          fin_data: fin_DATA,
        });
      }
    }
    await dispatch(actions.REASON_FOR_APPOINTMENT());
    const { payload } = await dispatch(actions.GET_ALL_SPECIALITY_2());
    setState({ ...state, allSpeciality: payload.data });
    const userInfo = getUserInfo();
    const info = {
      age: 0,
      gender: "",
    };
    if (userInfo.type === "Token") {
      let mobileNo = userInfo.mobile;
      if (
        location?.state?.selectedUser &&
        location?.state?.selectedUser?.birthDay
      ) {
        info["age"] = moment().diff(
          moment(location?.state?.selectedUser?.birthDay, "DD/MM/YYYY"),
          "years"
        );
        info["gender"] = location?.state?.selectedUser?.gender;
        changeState("sideInfo", info);
      } else {
        dispatch(
          actions.READ_PERSON({
            username: mobileNo,
            tenantid: "",
            facilityid: "",
          })
        ).then((val) => {
          info["age"] = val?.payload?.data?.birthDay
            ? moment().diff(
                moment(val?.payload?.data?.birthDay, "DD/MM/YYYY"),
                "years"
              )
            : "";
          info["gender"] = val?.payload?.data?.gender;
          changeState("sideInfo", info);
        });
      }
    } else {
      info["age"] = moment().diff(
        moment(userInfo.info.age.val, "DD/MM/YYYY"),
        "years"
      );
      info["gender"] = userInfo.info.gender?.value || "";
      changeState("sideInfo", info);
    }
    if (location?.state && !location?.state?.modifyUser) {
      const {
        hospital = [],
        specialities = [],
        location = {} /*symptoms, location*/,
        doctors = [],
      } = location?.state;
      console.log("doctors", doctors);
      console.log("specialities", specialities);
      // getDocterListingDetails({ hospital: hospital.value, specialities: [{ value: 1 }] })
      if (!doctors?.[0]?.value ? specialities.length > 0 : true) {
        //alert("getDocterListingDetails");
        getDocterListingDetails({
          hospital: hospital?.map((l) => l?.value),
          specialities: specialities.map((spec) => spec.value),
          latitude: location?.latitude || 0,
          longtitude: location?.longitude || 0,
          // date: undefined,
          doctorId: doctors?.[0]?.value,
          apptypecode:props?.apptypecode,
        });
      } else {
        alert("else");
        window.location.href = "/";
        return;
      }
    } else {
      appointmentRead();
      dispatch(
        actions.REASON_FOR_APPT_CANCEL_MODIFY({ type: "APPMODIFYBYUSER" })
      );
    }
  }

  const handleClick = (e) => {
    if (overlayNode?.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setState({
      ...state,
      openCloseHeader: false,
      indexToShow: null,
    });
  };
  async function appointmentRead() {
    const { Slots, resourcecode, slotID, resourcetype } =
      location?.state?.appointMentInfo;
    let start = Slots?.[0]?.start;
    if (location?.state?.newBook) {
      start = Math.floor(Date.now() / 1000);
    }
    if (location?.state?.fromCloseConsultation) {
      start = Slots?.[0]?.start;
    }
    const data = await dispatch(
      actions.APPOINTMENT_UPDATE_READ({
        start,
        id: [resourcecode?._id],
        slotID,
        HealthcareServiceID: [resourcecode?._id],
        type: resourcetype === "Practitioner" ? "practionerid" : "clinicid",
      })
    );
    changeState("data", data?.payload?.data || []);
    const IsModifyUser = location?.state?.modifyUser;
    const newBook = location?.state?.newBook;
    if (IsModifyUser && !newBook) {
      const selected = props.reasonForAppointment?.data?.filter((d) => {
        if (
          location?.state?.appointMentInfo?.appointmentReason?.includes(
            d?.value
          )
        ) {
          return true;
        }
        return false;
      });
      const uploadInfo = location?.state?.appointMentInfo?.report_docs?.map(
        (f) => {
          return {
            name: f.fileName,
            type: f.filetype,
            fileUrl: getImgUrl(f.fileid),
            size: 0,
            originalData: f,
          };
        }
      );
      changeState("uploadFiles", uploadInfo || []);
      changeState("appointmentReason", selected);
      changeState(
        "patientInstruction",
        location?.state?.appointMentInfo?.patientInstruction || ""
      );
    }
  }

  const toggleSideBar = (boolean) => {
    setState({ ...state, sidebar: boolean });
  };

  const handleOpen = async (index) => {
    if (index === 1) {
      // await  dispatch(actions.REGISTER_USERS();
      // const { data } = props?.docterListingBookingFor
    }
    setState({
      ...state,
      collpaseOpen: index === state.collpaseOpen ? null : index,
    });
  };

  const onHeaderClicked = (index) => {
    setState({
      ...state,
      openCloseHeader: !state.openCloseHeader,
      indexToShow: index ?? null,
    });
  };

  const rightSideAllCardfun = (val, info) => {
    console.log("value", val);
    console.log("infoooo", info);
    if (info && val) {
      setState({
        ...state,
        rightSideAllCard: val,
        DoctorAndAppointmentSlotCard: val,
        selectedSlot: info,
        resetTimeSlot: null,
        iagree: false,
        consultationMode: info.info.isdirect
          ? {
              value: process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE,
              label: "Direct Consultation",
            }
          : info.info.isvideo
          ? {
              value: process.env.REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE,
              label: "Video Consultation",
            }
          : {},
      });
    } else {
      setState({
        ...state,
        rightSideAllCard: val,
        DoctorAndAppointmentSlotCard: val,
        resetTimeSlot: null,
        iagree: false,
        isMultipleAppointment: false,
        multiApppointmentInfo: {
          appointments: {
            startsOn: {
              date: "",
              slotSelect: {},
              milli: 0,
            },
            preferrence: {
              preferredDays: [],
              preferredWeeks: [],
            },
            occurence: {
              occurrenceHandle: "NoOfDays",
              occurrenceValue: 1,
            },
            reccurance: {
              reccuranceValue: 1,
              reccuranceType: {
                label: "Days",
                value: process.env.REACT_APP_FREQUENCY_UOM_DAYS,
              },
            },
          },
          info: {},
          data: [],
          slotObj: {},
        },
      });
    }
  };

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];

  const relationshipOptions = [
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Brother", label: "Brother" },
    { value: "Sister", label: "Sister" },
    { value: "Others", label: "Others" },
  ];

  const goBack = () => {
    if (props?.guestInfo?.data?.type === 0) {
      setState({
        ...state,
        sidebar: true,
        showOtpVerification: false,
        collpaseOpen: 0,
      });
    } else {
      setState({
        ...state,
        showOtpVerification: false,
        collpaseOpen: 1,
      });
    }
  };

  const handleMySelfSignUp = () => {
    const state = { ...state };
    state.guestBookerInfo.mobileNo = state.guestInfo.mobileNo;
    state.guestBookerInfo.name = state.guestInfo.name;
    state.guestBookerInfo.mailId = state.guestInfo.mailId;
    state.guestBookerInfo.dob = state.guestInfo.age.val;
    state.guestBookerInfo.gender = state.guestInfo.gender.value;
    const check = checkValidationsForMyself(state.guestBookerInfo);
    if (check) {
      setState({ ...state, error: {}, meageget: {} }, () => {
        showVerification(null, true);
      });
    }
  };

  const checkValidationsForMyself = (guestBookerInfo) => {
    let {
      name = "",
      dob = "",
      gender = "",
      mobileNo = {},
      mailId = "",
      relationship = "",
    } = guestBookerInfo;
    if (typeof relationship === "object") {
      relationship = Object.keys(relationship)?.length === 0 && "";
    }
    if (
      name &&
      (props.guestInfo.data.type === 0 ? (dob ? true : false) : true) &&
      (props.guestInfo.data.type === 0 ? (gender ? true : false) : true) &&
      // mobileNo?.mobileNo?.length > 9 &&
      // mobileNo?.countryData?.callingCodes &&
      (mailId ? ValidateEmail(mailId) : true) &&
      (props.guestInfo.data.type === 1 ? (relationship ? true : false) : true)
    ) {
      let res__ = mobileNumberValidation(
        mobileNo?.mobileNo,
        mobileNo?.countryData?.callingCodes
      );

      if (res__ === "Invalid Country code") {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: res__,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
      } else if (res__ === false) {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter valid mobile number",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
      }
      return true;
    } else {
      setState({
        ...state,
        error: {
          guestBookerInfo: {
            mobileNo: mobileNo?.mobileNo?.length > 9 ? false : true,
            dob:
              props.guestInfo.data.type === 0
                ? dob?.length > 0
                  ? false
                  : true
                : false,
            callingCodes: mobileNo?.countryData?.callingCodes ? false : true,
            name: name?.length > 0 ? false : true,
            gender:
              props.guestInfo.data.type === 0 ? (gender ? false : true) : false,
            mailId: mailId ? (ValidateEmail(mailId) ? false : true) : false,
            relationship:
              props.guestInfo.data.type === 1
                ? relationship.length > 0
                  ? false
                  : true
                : false,
          },
        },
      });
      return false;
    }
  };

  const checkMobileValidations = (mobileNo) => {
    if (mobileNo && Object.keys(mobileNo)?.length) {
      // if (
      // 	mobileNo?.mobileNo?.length > 9 &&
      // 	mobileNo?.countryData?.callingCodes
      // ) {
      // 	return true;
      // } else {
      // 	return false;
      // }
      let res__ = mobileNumberValidation(
        mobileNo?.mobileNo,
        mobileNo?.countryData?.callingCodes
      );

      if (res__ === "Invalid Country code") {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: res__,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
      } else if (res__ === false) {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter valid mobile number",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const checkValidationsForOthers = () => {
    const {
      name = "",
      age = {},
      gender = {},
      mailId = "",
      mobileNo = {},
    } = state.guestInfo;

    if (
      name &&
      age?.val &&
      gender?.label &&
      (mailId ? ValidateEmail(mailId) : true) &&
      checkMobileValidations(mobileNo)
      // (mobileNo?.mobileNo ? mobileNo?.mobileNo?.length > 9 ? true : false : true) &&
      // (mobileNo?.countryData?.callingCodes ? mobileNo?.countryData?.callingCodes ? true : false : true)
    ) {
      setState({ ...state, error: { guestInfo: {} } }, () => handleOpen(1));
    } else {
      setState({
        ...state,
        error: {
          guestInfo: {
            name: name?.length > 0 ? false : true,
            dob: age?.val?.length > 0 ? false : true,
            gender: gender?.label ? false : true,
            mailId: mailId ? (ValidateEmail(mailId) ? false : true) : false,
            mobileNo:
              mobileNo?.mobileNo?.length > 9 &&
              mobileNo?.countryData?.callingCodes
                ? false
                : mobileNo?.mobileNo || mobileNo?.countryData?.callingCodes
                ? mobileNo?.mobileNo?.length > 9
                  ? false
                  : true
                : false,
            callingCodes:
              mobileNo?.countryData?.callingCodes && mobileNo?.mobileNo
                ? false
                : mobileNo?.countryData?.callingCodes || mobileNo?.mobileNo
                ? mobileNo?.countryData?.callingCodes
                  ? false
                  : true
                : false,
          },
        },
      });
      return false;
    }
  };

  const sendOTPandConfirm = () => {
    const check = checkValidationsForMyself(state.guestBookerInfo);
    if (check) {
      setState({
        ...state,
        error: { guestBookerInfo: {} },
        meageget: {},
      });
      showVerification(null, true);
    }
  };

  const showVerification = async (index, value) => {
    if (!index && value) {
      const { mobileNo, name, mailId } = state.guestBookerInfo;
      await dispatch(
        actions.REGISTER_USERS({
          mobileNo: `+${mobileNo?.countryData?.callingCodes ?? "00"}${
            mobileNo.mobileNo
          }`,
          firstName: name.split(" ")[0],
          lastName: name.split(" ")[1] || "",
          emailId: mailId,
        })
      );
      const status = checkError(props.registerUsers);
      if (status.isError) {
        if (status.errMsg === "User already exists.") {
          await dispatch(
            actions.GENERATE_OTP({
              mobileNo: `+${mobileNo?.countryData?.callingCodes ?? "00"}${
                mobileNo.mobileNo
              }`,
            })
          );
          const otpStatus = checkError(props.generateOTP);
          if (!otpStatus.isError) {
            console.log("Add member only ...");
            setState({
              ...state,
              collpaseOpen: index,
              showOtpVerification: value,
              addBookBy: false,
            });
          } else {
            console.error(status.errMsg);
          }
        } else {
          console.error(status.errMsg);
        }
      } else {
        console.log("Add member and user only ...");
        setState({
          ...state,
          collpaseOpen: index,
          showOtpVerification: value,
        });
      }
    }
  };

  const resetMulti = () => {
    setState({
      ...state,
      isMultipleAppointment: false,
      multiApppointmentInfo: {
        appointments: {
          startsOn: {
            date: "",
            slotSelect: {},
            milli: 0,
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          },
          occurence: {
            occurrenceHandle: "NoOfDays",
            occurrenceValue: 1,
          },
          reccurance: {
            reccuranceValue: 1,
            reccuranceType: {
              label: "Days",
              value: process.env.REACT_APP_FREQUENCY_UOM_DAYS,
            },
          },
        },
        info: {},
        data: [],
        slotObj: {},
        filterData: {},
        uploadFiles: [],
      },
    });
  };

  const changeScreen = async () => {
    let UploadedFiles = [];
    let { uploadFiles } = state;
    const { info = {}, hospitalInfo = {} } = state.selectedSlot;
    UploadedFiles = await uploadImage(uploadFiles);
    const { mobileNo } = state.guestBookerInfo;
    let number =
      !location?.state?.unregister?.is_Unregister &&
      state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") >
        -1
        ? location?.state?.userNo
        : getMobileNumber(location?.state?.unregister?.mobileNo || mobileNo);
    if (state.appointmentUpdate) {
      number = state.otpNumber;
    } else {
      number =
        !location?.state?.unregister?.is_Unregister &&
        state?.permissionData?.write?.indexOf(
          "appointmentBookingConfirmation"
        ) > -1
          ? location?.state?.userNo
          : getMobileNumber(location?.state?.unregister?.mobileNo);
    }
    let d = await dispatch(
      actions.SIGN_IN({
        mobileNo: number,
        otp: state.otp,
      })
    );
    const status = checkError(props.signIn);
    let appointmentId = 0;
    let bookerId = 0;
    if (status.isError) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: status.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      if (state.appointmentUpdate && !location?.state?.fromCloseConsultation) {
        let UploadedFiles = [];
        let { uploadFiles } = state;
        UploadedFiles = await uploadImage(uploadFiles);
        const appointMentInfo = state.oldSlot.appInfoFetched;
        appointMentInfo["consultationMode"] = state.consultationMode.value;
        appointMentInfo["slotID"] = [state.selectedSlot.info?.value];
        appointMentInfo["start"] = state.selectedSlot.info?.dateStart;
        appointMentInfo["end"] = state.selectedSlot.info?.dateEnd;
        appointMentInfo["cancelationReason"] =
          [state.modifyAppointmentReason?._id] ?? [];
        appointMentInfo["requestedPeriod"] = [
          {
            start: state.selectedSlot.info?.dateStart,
            end: state.selectedSlot.info?.dateEnd,
          },
        ];
        appointMentInfo["oldStart"] =
          location?.state?.appointMentInfo?.start || 0;
        appointMentInfo["oldEnd"] =
          location?.state?.appointMentInfo?.start || 0;
        appointMentInfo.orgid = appointMentInfo["orgid"]._id;
        appointMentInfo.orgtype = appointMentInfo.orgtype?._id;
        appointMentInfo.resourcecode = appointMentInfo.resourcecode._id;
        appointMentInfo.appointmentReason = state.appointmentReason?.map(
          (i) => i?.value
        );
        appointMentInfo.report_docs = UploadedFiles;
        appointMentInfo.patientInstruction = state.patientInstruction;
        const updatedData = await dispatch(
          actions.APPOINTMENT_UPDATE(appointMentInfo)
        );
        const updateStatus = checkError(updatedData?.payload);
        if (updateStatus.isError) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: updateStatus.errMsg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        } else {
          setState({ ...state, sidebar: false }, () => {
            const appointmentId =
              updatedData.payload?.data?.appointment_response?.appointment
                ?.Result?.[0]?.properties?.doc?.id || 0;
            // navigate("/confirm_booking");

            navigate(AppRoutes.confirmBooking, {
              state: {
                personId: location?.state?.userId,
                mobileNo: location?.state?.mobileNo,
                appointmentId: appointmentId,
                modfieded: location?.state?.fromCloseConsultation
                  ? false
                  : true,
                patientId: location?.state?.patientId,
                unregister: location?.state?.unregister?.is_Unregister,
                walkin: !(
                  moment()
                    .startOf("day")
                    .diff(moment(info?.dateStart * 1000), "d") === 0 &&
                  state.selectedSlot?.info?.maxwalkin > 0
                ),
              },
            });
          });
        }
        return;
      }
      if (
        location?.state?.unregister?.is_Unregister ||
        state?.permissionData?.write?.indexOf(
          "appointmentBookingConfirmation"
        ) > -1 ||
        location?.state?.fromCloseConsultation
      ) {
        if (
          !location?.state?.unregister?.is_Unregister ||
          location?.state?.fromCloseConsultation
        ) {
          console.log("info2", info);
          const appointMentInput = {
            slotId: [info.value || 0],
            slotStart: info.dateStart || "",
            slotEnd: info.dateEnd || "",
            orgId: hospitalInfo?.org_Id,
            orgType: hospitalInfo?.orgType,
            resourcetype: info.resourcetype || "",
            resourcerole: info.resourcerole || "",
            resourcecode: hospitalInfo?.slots[0]?.resource_id,
            info: state.appointmentReason,
            specialtyID: location?.state?.fromCloseConsultation
              ? location?.state?.specialities
              : location?.state?.specialities?.map((spec) => spec?.value) || [],
            personID: [location.state.userId || 0],
            practitionerId: hospitalInfo?.isClinic ? [] : [hospitalInfo?.id],
            mobile:
              !location?.state?.unregister?.is_Unregister &&
              !location?.state?.fromCloseConsultation &&
              state?.permissionData?.write?.indexOf(
                "appointmentBookingConfirmation"
              ) > -1
                ? location?.state?.userNo
                : location?.state?.fromCloseConsultation
                ? location?.state?.mobNoo
                : getMobileNumber(location?.state?.unregister?.mobileNo),
            email: state.guestInfo?.mailId || location?.state?.emaill,
            consultationMode: state.consultationMode.value,
            healthCareId: info.healthCareId,
            report_docs: UploadedFiles,
            patientInstruction: state.patientInstruction,
          };
          if (
            moment()
              .startOf("day")
              .diff(moment(info.dateStart * 1000), "d") === 0 &&
            state.selectedSlot?.info?.maxwalkin > 0
          ) {
            appointMentInput["modeOfAppointment"] = "walkin";
          }

          const appInfo = await dispatch(
            actions.APPOINTMENT_CREATE(appointMentInput)
          );
          const { isError, errMsg } = checkError(appInfo.payload);
          if (isError) {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: errMsg,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          } else {
            const appointmentId =
              appInfo.payload?.data?.appointment_response?.appointment
                ?.Result?.[0]?.properties?.doc?.id || 0;
            setState({ ...state, sidebar: false }, () => {
              // navigate("/confirm_booking");
              navigate(AppRoutes.confirmBooking, {
                state: {
                  personId: location?.state?.userId,
                  mobileNo: location?.state?.mobileNo,
                  appointmentId: appointmentId,
                  patientId: location?.state?.patientId,
                  fromListing: location?.state?.unregister?.is_Unregister
                    ? false
                    : true,
                  walkin: !(
                    moment()
                      .startOf("day")
                      .diff(moment(info.dateStart * 1000), "d") === 0 &&
                    state.selectedSlot?.info?.maxwalkin > 0
                  ),
                  unregister:
                    state?.permissionData?.write?.indexOf(
                      "appointmentBookingConfirmation"
                    ) > -1
                      ? false
                      : location?.state?.unregister?.is_Unregister,
                },
              });
            });
          }

          return;
        } else {
          const guestBookerAdd = {
            phone: getMobileNumber(location?.state?.unregister?.mobileNo),
            dob: location?.state?.unregister?.dob || "01/01/2000",
            firstName: location?.state?.unregister?.patientName,
            lastName: "",
            gender: location?.state?.unregister?.gender?.value || "Male",
            email: location?.state?.unregister?.emailId || "",
          };
          const {
            error,
            data: bookerData,
            getData,
          } = await upsertPerson(
            props?.READ_PERSON,
            props?.UPSERT_PERSON,
            guestBookerAdd
          );
          if (error && bookerData.includes("User Already Exists ")) {
            bookerId = getData.payload?.data?.id || 0;
          } else if (error) {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: bookerData,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
            return;
          } else {
            bookerId = bookerData.payload?.data?.id || 0;
          }
          console.log("info3", info);
          const appointMentInput = {
            slotId: [info.value || 0],
            slotStart: info.dateStart || "",
            slotEnd: info.dateEnd || "",
            orgId: hospitalInfo?.org_Id,
            orgType: hospitalInfo?.orgType,
            resourcetype: info.resourcetype || "",
            resourcerole: info.resourcerole || "",
            resourcecode: hospitalInfo?.slots[0]?.resource_id,
            info: state.appointmentReason,
            specialtyID:
              location?.state?.specialities?.map((spec) => spec?.value) || [],
            personID: [bookerId || 0],
            practitionerId: hospitalInfo?.isClinic ? [] : [hospitalInfo?.id],
            mobile: getMobileNumber(location?.state?.unregister?.mobileNo),
            email: state.guestInfo?.mailId,
            consultationMode: state.consultationMode.value,
            healthCareId: info.healthCareId,
            report_docs: UploadedFiles,
            patientInstruction: state.patientInstruction,
          };
          if (
            moment()
              .startOf("day")
              .diff(moment(info.dateStart * 1000), "d") === 0 &&
            state.selectedSlot?.info?.maxwalkin > 0
          ) {
            appointMentInput["appointmentType"] = "walkin";
          }

          const appInfo = await dispatch(
            actions.APPOINTMENT_CREATE(appointMentInput)
          );
          const { isError, errMsg } = checkError(appInfo.payload);
          if (isError) {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: errMsg,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          } else {
            const appointmentId =
              appInfo.payload?.data?.appointment_response?.appointment
                ?.Result?.[0]?.properties?.doc?.id || 0;
            setState({ ...state, sidebar: false }, () => {
              // navigate("/confirm_booking");
              navigate(AppRoutes.confirmBooking, {
                state: {
                  personId: location?.state?.userId,
                  mobileNo: location?.state?.mobileNo,
                  appointmentId: appointmentId,
                  patientId: location?.state?.patientId,
                  fromListing: location?.state?.unregister?.is_Unregister
                    ? false
                    : true,
                  walkin: !(
                    moment()
                      .startOf("day")
                      .diff(moment(info.dateStart * 1000), "d") === 0 &&
                    state.selectedSlot?.info?.maxwalkin > 0
                  ),
                  unregister: location?.state?.unregister?.is_Unregister,
                },
              });
            });
          }

          return;
        }
      }

      if (props?.signIn?.data?.access_token) {
        localStorage.removeItem("guestInfo");
        localStorage.setItem("Token", props?.signIn?.data?.access_token);
      }
      if (state.addBookBy) {
        const guestBookerAdd = {
          phone: getMobileNumber(state.guestBookerInfo.mobileNo),
          dob: state.guestBookerInfo.dob || "01/01/2000",
          firstName: state.guestBookerInfo.name.split(" ")[0],
          lastName: state.guestBookerInfo.name.split(" ")[1] || "",
          gender: state.guestBookerInfo.gender || "Male",
          email: state.guestBookerInfo?.mailId || "",
        };
        const {
          error,
          data: bookerData,
          getData,
        } = await upsertPerson(
          props?.READ_PERSON,
          props?.UPSERT_PERSON,
          guestBookerAdd
        );
        if (error && bookerData.includes("User Already Exists ")) {
          bookerId = getData.payload?.data?.id || 0;
        } else if (error) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: bookerData,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          return;
        } else {
          bookerId = bookerData.payload?.data?.id || 0;
        }
      }

      const bookData = await dispatch(
        actions.READ_PERSON({
          // phone: getMobileNumber(state.guestBookerInfo.mobileNo),
          username: getMobileNumber(state?.guestBookerInfo?.mobileNo),
          tenantid: "",
          facilityid: "",
        })
      );
      if (props?.guestInfo?.data?.type === 0) {
        if (!state.addBookBy) {
          bookerId = bookData?.payload?.data?.id || 0;
          localStorage.setItem("idval", bookerId);
        }
      } else {
        const guestInfoAdd = {
          phone: getMobileNumber(state.guestInfo.mobileNo),
          dob: state.guestInfo.age.val,
          firstName: state.guestInfo.name.split(" ")[0],
          lastName: state.guestInfo.name.split(" ")[1] || "",
          gender: state.guestInfo?.gender?.value,
          email: state.guestInfo?.mailId || "",
        };
        const { error, data } = await upsertPerson(
          props?.READ_PERSON,
          props?.UPSERT_PERSON,
          guestInfoAdd
        );
        if (error) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: data,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          return;
        } else {
          await dispatch(
            actions.ADD_RELATED_PERSON({
              ...guestInfoAdd,
              relationType: state.guestBookerInfo?.relationship?.value || "",
              relationPersonId: [bookData?.payload?.data?.id || 0],
              personId: data.payload?.data?.id || 0,
            })
          );
          bookerId = data.payload?.data?.id || 0;
        }
      }
      console.log("info4", info);
      const appointMentInput = {
        slotId: [info.value || 0],
        slotStart: info.dateStart || "",
        slotEnd: info.dateEnd || "",
        orgId: hospitalInfo?.org_Id,
        orgType: hospitalInfo?.orgType,
        resourcetype: info.resourcetype || "",
        resourcerole: info.resourcerole || "",
        resourcecode: hospitalInfo?.slots[0]?.resource_id,
        info: state.appointmentReason,
        specialtyID:
          location?.state?.specialities?.map((spec) => spec?.value) || [],
        personID: [bookerId || 0],
        practitionerId: hospitalInfo?.isClinic ? [] : [hospitalInfo?.id],
        mobile: getMobileNumber(state.guestInfo?.mobileNo),
        email: state.guestInfo?.mailId,
        consultationMode: state.consultationMode.value,
        healthCareId: info.healthCareId,
        report_docs: UploadedFiles,
        patientInstruction: state.patientInstruction,
      };
      if (
        moment()
          .startOf("day")
          .diff(moment(info.dateStart * 1000), "d") === 0 &&
        state.selectedSlot?.info?.maxwalkin > 0
      ) {
        appointMentInput["appointmentType"] = "walkin";
      }

      const appInfo = await dispatch(
        actions.APPOINTMENT_CREATE(appointMentInput)
      );
      const { isError, errMsg } = checkError(appInfo.payload);
      if (isError) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      } else {
        const appointmentId =
          appInfo.payload?.data?.appointment_response?.appointment?.Result?.[0]
            ?.properties?.doc?.id || 0;
        setState({ ...state, sidebar: false }, () => {
          // navigate("/confirm_booking");
          props.visitData.setVisitDetails({
            encounter: {
              mode:
                state?.consultationMode?.value ===
                process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE
                  ? "direct"
                  : state?.consultationMode?.value ===
                    process.env.REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE
                  ? "video"
                  : "direct",
            },
            practitioner: {},
            device: {},
            helthcare: {},
            accompained: {},
          });
          navigate(getPathName(info.dateStart), {
            state: {
              personId: location?.state?.userId,
              mobileNo: location?.state?.mobileNo,
              appointmentId: appointmentId,
              patientId: location?.state?.patientId,
              walkin: !(
                moment()
                  .startOf("day")
                  .diff(moment(info.dateStart * 1000), "d") === 0 &&
                state.selectedSlot?.info?.maxwalkin > 0
              ),
              fromListing: true,
            },
          });
        });
      }
    }
  };

  const booking = async () => {
    //debugger
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Booking your appointment !",
    });
    if (patientDetail?.bookFlag?.multiBook) {
      await multiBooking();
    } else if (location?.state?.modifyUser) {
      window.alert("bcey");
    } else {
      await singleBooking();
    }
    backdrop.setBackDrop({ ...backdrop, open: false, message: "" });

    setPatientDetailsContext((prevstate) => ({ ...prevstate, bookFlag: {} }));
  };
  const multiBooking = async () => {
    let isSignUpUser= localStorage.getItem("RoleName")==="pc_signupuser" 
    let isPatient =localStorage.getItem("RoleName")==="pc_patient" 
    let userinfo = jwtDecode(localStorage.getItem("UserInfo"));
    let UploadedFiles = [];
    let { uploadFiles } = state;
    const { info = {}, hospitalInfo = {} } = state.selectedSlot;
    UploadedFiles = await uploadImage(uploadFiles);
    var appointMentInputArr = [];
    state.multiApppointmentInfo.data.forEach((singleSlot) => {
      let appointMentInput = {
        consultationMode: state.consultationMode.value,
        status: true,
        cancelationReason: [],
        reasonCode: [],
        priority: 1, //REVIEW -  always 1
        start: singleSlot.start || "",
        end: singleSlot.end || "",
        minutesDuration: 15, //FIXME -
        comment: "comments",
        appointmentReason: state?.appointmentReason,
        patientInstruction: state.patientInstruction,
        slotID: [singleSlot._id || 0],
        ServiceCategoryID: [],
        ServiceTypeID: [],
        SpecialtyID:
          location?.state?.specialities?.map((spec) => spec?.ID) ?? [],
        resourcetype: singleSlot?.resourceInfo?.resourcetype || "",
        resourcerole: singleSlot?.resourceInfo?.resourcerole || "",

        resourcecode: Array.isArray(singleSlot?.resourceInfo?.resourcecode?.[0])
          ? singleSlot?.resourceInfo?.resourcecode?.[0]
          : singleSlot?.resourceInfo?.resourcecode,
        orgid: hospitalInfo?.org_Id,
        orgtype: hospitalInfo?.orgType,
        PersonID: location?.state?.userId || 0,
        report_docs: UploadedFiles,
        appno: "", //REVIEW - for this scenario "" only,
        patient_id:isSignUpUser ? "": patientDetail?.patientId || location?.state?.patientId,
        AppointmentNotification: [
          {
            practitionerEmail: "",
            mobile: location.state?.selectedUser?.mobile, //REVIEW
            patientEmail: location.state?.selectedUser?.email, //REVIEW
          },
        ],
        // appointmentType: "CodingMaster/11365",
        bookedby: (isSignUpUser ||isPatient) ? location.state.userId : userinfo?.practitionerid,
        appointmentTypeID: singleSlot?.appointmentType || [],
        facilityid: userinfo?.facilityid,
        tenantid: userinfo?.tenantid,
      };
      if (
        moment()
          .startOf("day")
          .diff(moment(info.dateStart * 1000), "d") === 0 &&
        state.selectedSlot?.info?.maxwalkin > 0
      ) {
        appointMentInput["modeofappointment"] =
          process.env.REACT_APP_MODE_OF_APPOINTMENT_WALKIN; //walkin
      } else {
        appointMentInput["modeofappointment"] =
          process.env.REACT_APP_MODE_OF_APPOIMENT_GENERAL; //general
      }

      appointMentInputArr.push(appointMentInput);
    });

    const appInfo = await dispatch(
      actions.APPOINTMENT_CREATE({ doc: appointMentInputArr })
    );

    let appointmentid =
      appInfo?.payload?.data?.[0]?.response?.appointment_response?.appointment
        ?.Result?.[0]?.properties?.doc?._id;
    console.log({ appInfo });

    if (appointmentid) {
      navigate(AppRoutes.confirmBooking, {
        state: {
          personId: location?.state?.userId,
          mobileNo: location.state?.selectedUser?.mobile,
          appointmentId: appointmentid,
          // modfieded: location?.state?.fromCloseConsultation ? false : true,
          patientId: location?.state?.patientId,
          unregister: location?.state?.unregister?.is_Unregister ?? false,
          walkin: !(
            moment()
              .startOf("day")
              .diff(moment(info?.dateStart * 1000), "d") === 0 &&
            state.selectedSlot?.info?.maxwalkin > 0
          ),
          fromListing: true,
        },
      });
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: appInfo?.payload?.data?.[0]?.message || "something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };
  const singleBooking = async () => {
   let isSignUpUser= localStorage.getItem("RoleName")==="pc_signupuser" 
   let isPatient =localStorage.getItem("RoleName")==="pc_patient" 
    let userinfo = jwtDecode(localStorage.getItem("UserInfo"));
    let UploadedFiles = [];
    let { uploadFiles } = state;
    const { info = {}, hospitalInfo = {} } = state.selectedSlot;
    console.log({info})
    UploadedFiles = await uploadImage(uploadFiles);
    const appointMentInput = {
      consultationMode: state.consultationMode.value,
      status: true, //REVIEW -  true
      cancelationReason: [],
      reasonCode: [],
      priority: 1, //REVIEW -  always 1
      start: info.dateStart || "",
      end: info.dateEnd || "",
      minutesDuration: 15, //FIXME -
      comment: "comments",
      appointmentReason: state?.appointmentReason,
      patientInstruction: state.patientInstruction,
      slotID: [info._id || 0],
      ServiceCategoryID: [],
      ServiceTypeID: [],
      SpecialtyID: location?.state?.specialities?.map((spec) => spec?.ID) ?? [],
      appointmentTypeID: info?.appointmentType || [],
      resourcetype: info.resourcetype || "",
      resourcerole: info.resourcerole || "",
      resourcecode: Array.isArray(info?.resourcecode)
        ? info?.resourcecode?.[0]
        : info?.resourcecode ?? "",
      //hospitalInfo?.slots[0]?.resource_id,
      orgid: hospitalInfo?.org_Id, //REVIEW -
      orgtype: hospitalInfo?.orgType, //REVIEW -
      PersonID: location.state.userId || 0,
      report_docs: UploadedFiles,
      appno: "", //REVIEW - for this scenario "" only,
      patient_id:isSignUpUser ? "": patientDetail?.patientId || location?.state?.patientId,
      facilityid:  hospitalInfo?.org_Id || userinfo?.facilityid,
      tenantid: userinfo?.tenantid,
      AppointmentNotification: [
        {
          practitionerEmail: "",
          mobile: location.state?.selectedUser?.mobile, //REVIEW
          patientEmail: location.state?.selectedUser?.email, //REVIEW
        },
      ],
      //  appointmentType: "CodingMaster/11365", //NOTE - can be given below
      bookedby: (isSignUpUser ||isPatient) ? location.state.userId : userinfo?.practitionerid,
    };
    if (
      moment()
        .startOf("day")
        .diff(moment(info.dateStart * 1000), "d") === 0 &&
      state.selectedSlot?.info?.maxwalkin > 0
    ) {
      appointMentInput["modeofappointment"] =
        process.env.REACT_APP_MODE_OF_APPOINTMENT_WALKIN; //walkin
    } else {
      appointMentInput["modeofappointment"] =
        process.env.REACT_APP_MODE_OF_APPOIMENT_GENERAL; //general
    }

    const appInfo = await dispatch(
      actions.APPOINTMENT_CREATE({ doc: [appointMentInput] })
    );
    
    let appointmentid =
      appInfo?.payload?.data?.[0]?.response?.appointment_response?.appointment
        ?.Result?.[0]?.properties?.doc?._id;
    console.log({ appInfo });
    if (appointmentid) {
      navigate(AppRoutes.confirmBooking, {
        state: {
          personId: location?.state?.userId,
          mobileNo: location.state?.selectedUser?.mobile,
          appointmentId: appointmentid,
          // modfieded: location?.state?.fromCloseConsultation ? false : true,
          patientId: location?.state?.patientId,
          unregister: location?.state?.unregister?.is_Unregister ?? false,
          walkin: !(
            moment()
              .startOf("day")
              .diff(moment(info?.dateStart * 1000), "d") === 0 &&
            state.selectedSlot?.info?.maxwalkin > 0
          ),
          fromListing: true,
        },
      });
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: appInfo?.payload?.data?.[0]?.message || "something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };
  const modifyBooking = async () => {
    let UploadedFiles = [];
    let { uploadFiles } = state;
    const { info = {}, hospitalInfo = {} } = state.selectedSlot;
    let userinfo = jwtDecode(localStorage.getItem("UserInfo"));
    UploadedFiles = await uploadImage(uploadFiles);
    const appointMentInput = {
      consultationMode: state.consultationMode.value,
      status: true, //REVIEW -  true
      cancelationReason: [],
      reasonCode: [],
      priority: 1, //REVIEW -  always 1
      start: info.dateStart || "",
      end: info.dateEnd || "",
      minutesDuration: 15, //FIXME -
      comment: "comments",
      appointmentReason: state?.appointmentReason,
      patientInstruction: state.patientInstruction,
      slotID: [info._id || 0],
      ServiceCategoryID: [],
      ServiceTypeID: [],
      SpecialtyID: location?.state?.specialities?.map((spec) => spec?.ID) ?? [],
      appointmentTypeID: info?.appointmentType || [],
      resourcetype: info.resourcetype || "",
      resourcerole: info.resourcerole || "",
      resourcecode: Array.isArray(info?.resourcecode)
        ? info?.resourcecode?.[0]
        : info?.resourcecode ?? "",
      //hospitalInfo?.slots[0]?.resource_id,
      orgid: hospitalInfo?.org_Id, //REVIEW -
      orgtype: hospitalInfo?.orgType, //REVIEW -
      PersonID: location.state.userId || 0,
      report_docs: UploadedFiles,
      appno: "", //REVIEW - for this scenario "" only,
      patient_id: patientDetail?.patientId || location?.state?.patientId,
      AppointmentNotification: [
        {
          practitionerEmail: "",
          mobile: location.state?.selectedUser?.mobile, //REVIEW
          patientEmail: location.state?.selectedUser?.email, //REVIEW
        },
      ],
      //  appointmentType: "CodingMaster/11365", //NOTE - can be given below
      bookedby: userinfo?.practitionerid,
      facilityid: userinfo?.facilityid,
      tenantid: userinfo?.tenantid,
    };
    if (
      moment()
        .startOf("day")
        .diff(moment(info.dateStart * 1000), "d") === 0 &&
      state.selectedSlot?.info?.maxwalkin > 0
    ) {
      appointMentInput["modeofappointment"] =
        process.env.REACT_APP_MODE_OF_APPOINTMENT_WALKIN; //walkin
    } else {
      appointMentInput["modeofappointment"] =
        process.env.REACT_APP_MODE_OF_APPOIMENT_GENERAL; //general
    }

    const appInfo = await dispatch(
      actions.APPOINTMENT_CREATE({
        doc: [appointMentInput],
        filter: { _id: "" },
      })
    );
    let appointmentid =
      appInfo?.payload?.data?.[0]?.response?.appointment_response?.appointment
        ?.Result?.[0]?.properties?.doc?._id;
    console.log({ appInfo });
    if (appointmentid) {
      navigate(AppRoutes.confirmBooking, {
        state: {
          personId: location?.state?.userId,
          mobileNo: location.state?.selectedUser?.mobile,
          appointmentId: appointmentid,
          // modfieded: location?.state?.fromCloseConsultation ? false : true,
          patientId: location?.state?.patientId,
          unregister: location?.state?.unregister?.is_Unregister ?? false,
          walkin: !(
            moment()
              .startOf("day")
              .diff(moment(info?.dateStart * 1000), "d") === 0 &&
            state.selectedSlot?.info?.maxwalkin > 0
          ),
          fromListing: true,
        },
      });
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: appInfo?.payload?.data?.[0]?.message || "something went wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const handleOpenProfile = async () => {
    await dispatch(actions.DOCTER_LISTING_PROFILE());
    const { data } = props?.docterListingProfile;
    setState({
      ...state,
      profileDetails: data,
    });
  };
  const handleresendOTP = async () => {
    const number = state.otpNumber;
    const meageget = await dispatch(
      actions.RESEND_OTP({
        mobileNo: number,
      })
    );
    changeState("meageget", meageget);
  };
  const handlepapover = (e) => {
    if (state.opencalendar) {
      handlepapoverclose();
    } else {
      setState({ ...state, opencalendar: e.currentTarget });
    }
  };
  const handlepapoverclose = (val) => {
    if (val) {
      setState({ ...state, opencalendar: null });
    }
  };
  const filterCalendar = (e) => {
    setState({
      ...state,
      openCloseCalendar: e.currentTarget,
    });
  };
  const handleClose = (val) => {
    setState({
      ...state,
      openCloseCalendar: val,
    });
  };
  const mainfilter = () => {
    setState({
      ...state,
      filter: !state.filter,
    });
  };
  const onKeyDown = (e) => {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        e.keyCode === 8 ||
        e.keyCode === 46 ||
        e.keyCode === 37 ||
        e.keyCode === 39
      )
    ) {
      e.preventDefault();
    }
  };

  async function handleApply() {
    let { monthCalendarInputValue, selectedDate } = state;
    const {
      hospital = [],
      specialities = [],
      location = {},
      doctors = [],
    } = location?.state;
    let date = moment(selectedDate.val, "DD-MM-YYYY");
    if (monthCalendarInputValue) {
      date = moment(
        new Date(
          moment(date, "MM-DD-YYYY").add(
            parseInt(monthCalendarInputValue),
            "days"
          )
        )
      ).format("MM-DD-YYYY");
    }
    setState({ ...state, applyedDate: date }, () => {
      getDocterListingDetails({
        hospital: hospital?.map((l) => l?.value),
        specialities: specialities.map((spec) => spec.value),
        latitude: location?.latitude || 0,
        longtitude: location?.longitude || 0,
        date: date,
        doctorId: doctors?.[0]?.value,
        apptypecode: props?.apptypecode,
      });
      handleClose(false);
    });
  }

  const ApplyFilter = (filterData) => {
    changeState("data", []);
    const { consultmode, speciality, gender, lang } =
      filterListingData(filterData);
    const {
      hospital = [],
      location = {},
      doctors = [],
      /*symptoms, location*/
    } = location?.state;
    dispatch(
      actions.DOCTER_LISTING({
        hospital: hospital?.map((l) => l?.value),
        specialities: speciality || [],
        latitude: location?.latitude || 0,
        longtitude: location?.longitude || 0,
        date: undefined,
        doctorId: doctors?.[0]?.value,
        consultmode,
        gender,
        lang,
      })
    ).then((d) => {
      changeState("data", d?.payload?.data || []);
      setState(
        {
          ...state,
          filterData,
        },
        () => mainfilter()
      );
    });
  };

  const uploadFun = (files) => {
    setState({
      ...state,
      uploadFiles: files,
    });
  };
  console.log("apptmntdetails", state, props);
  return (
    <Div
      id="doctor_listing_avatar_parent_div"
      style={{
        height: "calc(100vh - 82px)",
        overflowY: "auto",
        padding: "0px 16px",
      }}
    >
      <PatientDetailCard
        state={state}
        setState={setState}
        location={location}
      ></PatientDetailCard>

      <Div
        id="doctor_listing_DoctorAndAppointmentSlot_div"
        style={{ marginBottom: "8px" }}
      >
        <DoctorAndAppointmentSlot
          is_unregister={props.location?.state?.unregister?.is_Unregister}
          parent_id={"doctor_listing"}
          loginType={info.type}
          newBook={newBook}
          fromListing={state.fromListing}
          IsModifyUser={IsModifyUser}
          name={state.selectedSlot?.hospitalInfo?.name || ""}
          date={state.selectedSlot?.info?.label}
          info={state.selectedSlot?.info}
          hospitalInfo={state.selectedSlot?.hospitalInfo}
          changeState={changeState}
          handleMultiAppointmentChange={handleMultiAppointmentChange}
          multiApppointmentInfo={state.multiApppointmentInfo}
          isMultipleAppointment={state.isMultipleAppointment}
          updateMultiSlots={updateMultiSlots}
          oldSlotInfo={state.oldSlot}
          consultationMode={state.consultationMode}
          time={
            state.selectedSlot?.info?.date
              ? utcTOLocal(state.selectedSlot?.info?.date / 1000, "Do MMM YYYY")
              : ""
          }
          handleCollapse={() =>
            changeState("slotSelected", !state.slotSelected)
          }
          DoctorAndAppointmentSlotCard={state.rightSideAllCard}
          rightSideAllCardfun={rightSideAllCardfun}
          {...props}
        />
      </Div>
      {state.rightSideAllCard ? (
        <>
          <AppointmentReason
            parent_id={"doctor_listing"}
            handleChange={(data) => changeState("appointmentReason", data)}
            info={state.appointmentReason}
            options={props.reasonForAppointment?.data ?? []}
            title="Reason for Appointment"
            label="Add your health issues here"
            img={Reasonforappointment}
            // multiple
            // masterSearch
          />

          {state.consultationMode.value !==
            process.env.REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE && (
            <ReportAndAdditionalServices
              parent_id={"doctor_listing"}
              uploadFun={uploadFun}
              IsModifyUser={IsModifyUser}
              changeState={changeState}
              patientInstruction={state.patientInstruction}
              upload={state.uploadFiles}
              is_Unregister={props.location?.state?.unregister?.is_Unregister}
              permissionData={state.permissionData}
            />
          )}

          <BillSummary parent_id={"doctor_listing"} />

          {IsModifyUser && !newBook && (
            <Div style={{ marginBottom: "8px" }}>
              <AppointmentReason
                parent_id={"doctor_listing"}
                handleChange={(data) =>
                  changeState("modifyAppointmentReason", data)
                }
                info={state.modifyAppointmentReason}
                options={props?.reasonForApptCancelModify ?? []}
                title="Reason for Modification"
                label="Choose your reason"
                img={ModifyAppointment}
              />
            </Div>
          )}

          <AppointmentInstructions parent_id={"doctor_listing"} />

          <>
            <Div
              id="doctor_listing_agree_instruction_div"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "0px",
                width: "100%",
                marginTop: "6%",
              }}
            >
              <TermsAndConditions
                value={state.iagree}
                checkboxTickColor={"#fff"}
                activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
                checkboxBorderColor={UIColor.lineBorderFill.color}
                checkboxBackgroundColor={
                  UIColor.differentiationBackground.color
                }
                activeCheckboxBackgroundColor={
                  UIColor.secondaryButtonColor.color
                }
                id="doctor_listing_agree_instruction_terms"
                // value={true}
                // key={"4"}
                lable={
                  <span
                    id="doctor_listing_agree_instruction_span"
                    style={{
                      fontSize: "12px",
                      color: "#101010",
                      marginBottom: "4px",
                      marginTop: "-10px",
                    }}
                    className="pc_medium"
                  >
                    {"I agree that I've read the instructions "}{" "}
                    <span
                      id="doctor_listing_agree_instruction_star_span"
                      style={{ color: "red" }}
                    >
                      *
                    </span>
                  </span>
                }
                inLineStyles={{ marginBottom: 16 }}
                className={classes.termsandcondition}
                onChange={() =>
                  setState({
                    ...state,
                    iagree: !state.iagree,
                  })
                }
              />
            </Div>
            <Div
              style={{ textAlign: "center" }}
              id="doctor_listing_confirm_div"
            >
              {state.iagree ? (
                <Button
                  id="doctor_listing_confirm_book_button"
                  name={
                    !!props?.upsertAppointment?.loading ||
                    props.updateAppointment?.loading ||
                    props?.registerUsers?.loading ||
                    props?.signIn?.loading ||
                    props?.readPerson?.loading ||
                    props?.upsertPerson?.loading ||
                    !!props?.checkAppointmentAgainstPatient?.loading
                      ? "Booking..."
                      : "Confirm Booking"
                  }
                  onClick={booking}
                  disabled={
                    !!props?.upsertAppointment?.loading ||
                    !!props?.checkAppointmentAgainstPatient?.loading
                  }
                  style={{
                    backgroundColor: UIColor.primaryColor.color,
                    borderColor: UIColor.primaryColor.color,
                    color: "#fff",
                    marginTop: "3%",
                    padding: "6px 16px",
                    borderRadius: "8px",
                    marginBottom: "4%",
                  }}
                  className="pc_medium"
                />
              ) : (
                <Button
                  id="doctor_listing_disabled_confirm_book_button"
                  name="Confirm Booking"
                  disabled={true}
                  style={{
                    backgroundColor: UIColor.lineBorderFill.color,
                    borderColor: UIColor.lineBorderFill.color,
                    color: UIColor.tertiaryText.color,
                    marginTop: "3%",
                    padding: "6px 16px",
                    borderRadius: "8px",
                    marginBottom: "4%",
                  }}
                  className="pc_medium"
                />
              )}
            </Div>
          </>
        </>
      ) : null}
    </Div>
  );
};

export default AppointmentDetails;
