import React, { useContext, useState } from "react";
import {
  Typography,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Collapse,
  Grid,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import CustomListing from "./components/customList";
import { handleCollapse, listClick } from "./handlers";
import useStyles from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import { AppRoutes } from "../../../router/routes";
import PatientReviewChartReport from "../../reports/patientReviewChartReport";
import {
  AlertProps,
  axiosCall,
  getFacilityId,
  query_ids,
  urls,
} from "../../../utils";
import { AlertContext } from "../../../context";
import PicReviewSheet from "../../reports/picReviewSheetReport";
import PanelReport from "../../reports/panelReport";
import EpoConsumptionReport from "../../reports/epoConsumptionReport";
import CashierCollectionSummary from "../../reports/cashCollectionsReport";
import MonthwiseAttendanceReport from "../../reports/monthwiseAttendanceReport";
import ObservationReport from "../../reports/observationReport";
import { Box, Modal } from "@mui/material";
import BillCancelReport from "../../reports/billCancelReport";
import RecieptCancelReport from "../../reports/recieptCancelReport";

export const CustomDrawer = (props) => {
  const alertContext = useContext(AlertContext);
  const { list } = props;
  const classes = useStyles(props);
  const navigate = useNavigate();
  const [state, setState] = useState({
    index: null,
  });
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalPicReviewSheet, setModalPicReviewSheet] = React.useState(false);
  const [panelmodel, setPanelModel] = React.useState(false);
  const [modalOpenforPatientChart, setModalOpenforPatientChart] =React.useState(false);
  const [modalOpenforEpoConsumptionReport, setModalOpenforEpoConsumptionReport] =React.useState(false);
  const [monthwiseAttendanceReport, setMonthwiseAttendanceReport] =React.useState(false);
  const [observationReport, setObservationReport] =React.useState(false);
  const [billCancelReport, setBillCancelReport] =React.useState(false);
  const [recieptCancelReport, setRecieptCancelReport] =React.useState(false);

  // const [ireports, setireports] = React.useState({
  //   modal : null,
  //   name : "",
  //   ilink : ""
  // });
  const [PractList, setPractList] = React.useState([]);

  const handleModalClose = () => {
    setModalOpen(false);
    setModalOpenforPatientChart(false);
    setModalPicReviewSheet(false);
    setPanelModel(false);
    setMonthwiseAttendanceReport(false);
    setObservationReport(false);
    setModalOpenforEpoConsumptionReport(false);
    setBillCancelReport(false);
    setRecieptCancelReport(false);
  };
  const onClickListing = async (data, index, parentIndex, navigate, props) => {
    if (data?.key_ === "Cash Collection Summary report") {
      setModalOpen(true);
      // navigate(AppRoutes.cashCollectionReport);
    } else if (data?.key_ === "Obervation Report") {
      // navigate(AppRoutes.observationReport);
      setObservationReport(true);
    } else if (
      data?.key_ === "NursingProcedureSummary" ||
      data?.key_ === "PatientattendanceSummary" ||
      data?.key_ === "BillRegister" ||
      data?.key_ === "Claim Summary Report" ||
      data?.key_ === "attendanceReport1" ||
      data?.key_ === "Patient Report" ||
      data?.key_ === "Cancelled and No Show Report"||
      data?.key_ === "HqDashboard" ||
      data?.key_ === "picdashboard" ||
      data?.key_ === "nursedashboard" ||
      data?.key_ === "pbnursedashboard" ||
      data?.key_ === "nephrologistdashboard"
    ) {
      navigate(AppRoutes.iframes, {
        state: {
          link: data?.ilink,
          name: data?.label,
        },
      });
      props?.onClose();
    } else if (data?.link) {
      navigate({
        pathname: AppRoutes?.iframes,
        state: data.ilink,
      });
      props?.onClose && props?.onClose();
    } 
    // else if (data?.key_ === "PICReviewSheetReport") {
    //   navigate(AppRoutes.picReviewSheet);
    // }
     else if (data?.key_ === "Panel Report") {
      // navigate(AppRoutes.panelReports);
      setPanelModel(true);
    } else if (data?.key_ === "Monthwise Attendance Report") {
      setMonthwiseAttendanceReport(true);
      // navigate(AppRoutes.monthwiseAttendanceReport);
    } else if (data?.key_ === "Patient Review Chart Report") {
      setModalOpenforPatientChart(true);
      // navigate(AppRoutes.patientReviewChartReport);
    }else  if (data?.key_ === "PIC Review Report") {
      setModalPicReviewSheet(true);
    }else  if (data?.key_ === "Bill Cancel Report") {
      setBillCancelReport(true);
    }else  if (data?.key_ === "Receipt Cancel Report") {
      setRecieptCancelReport(true);
    }
    else if (data?.key_ === "EPO Consumption Report") {
      // navigate(AppRoutes.epoConsumptionReport);
      setModalOpenforEpoConsumptionReport(true);
    }else if (data?.key_ === "mainMenu") {
      navigate(AppRoutes.dashboardLanding);
    }
  };
  return (
    <div className={classes.root}>
      {list?.length &&
        list?.map((_, index) => {
          return (
            <div className="border">
              <div
                key={index}
                className={classes.rowCenterBtn}
                onClick={() => listClick(_, index, state, setState, navigate)}
              >
                <CustomListing label={_?.label} />
                {_?.children?.length ? (
                  <div
                    className={clsx(classes.expand, {
                      [classes.expandOpen]:
                        state.index === index ? true : false,
                    })}
                  >
                    <ExpandLessIcon />
                  </div>
                ) : null}
              </div>

              {_?.children && (
                <Collapse
                  id={`${props?.parent_id}_collapse_parent_collapse`}
                  in={state.index === index ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  <div className={classes.children}>
                    {_?.children?.map((child, i) => {
                      return (
                        <div className="child">
                          <CustomListing
                            icon={child?.icon}
                            label={child?.label}
                            color={child?.color}
                            backgroundColor={child?.backgroundColor}
                            onClick={() =>
                              onClickListing(child, i, index, navigate, props)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </Collapse>
              )}
            </div>
          );
        })}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          // zIndex: 1500,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            p: 4,
          }}
        >
          <CashierCollectionSummary handleModalClose={handleModalClose} />
        </Box>
      </Modal>
      <Modal
        open={modalPicReviewSheet}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }} // Center the modal
      >
         <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            p: 4,
          }}
        >
          <PicReviewSheet
            handleModalClose={handleModalClose}
            PractList={PractList}
          />
        </Box>
      </Modal>
      <Modal
        open={modalOpenforPatientChart}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }} // Center the modal
      >
         <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            p: 4,
          }}
        >
          <PatientReviewChartReport
            handleModalClose={handleModalClose}
            PractList={PractList}
          />
        </Box>
      </Modal>
      <Modal
        open={monthwiseAttendanceReport}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }} // Center the modal
      >
         <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            p: 4,
          }}
        >
          <MonthwiseAttendanceReport
            handleModalClose={handleModalClose}
          />
        </Box>
      </Modal>
      <Modal
        open={observationReport}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }} // Center the modal
      >
         <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            p: 4,
          }}
        >
          <ObservationReport
            handleModalClose={handleModalClose}
          />
        </Box>
      </Modal>
      <Modal
        open={panelmodel}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }} // Center the modal
      >
       <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            p: 4,
          }}
        >
          <PanelReport handleModalClose={handleModalClose} />
        </Box>
      </Modal>
      <Modal
        open={modalOpenforEpoConsumptionReport}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }} // Center the modal
      >
       <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            p: 4,
          }}
        >
          <EpoConsumptionReport handleModalClose={handleModalClose} />
        </Box>
      </Modal>
      <Modal
        open={billCancelReport}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          // zIndex: 1500,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            p: 4,
          }}
        >
          <BillCancelReport handleModalClose={handleModalClose} />
        </Box>
      </Modal>
      <Modal
        open={recieptCancelReport}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: "flex",
          justifyContent: "center",
          // zIndex: 1500,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            p: 4,
          }}
        >
          <RecieptCancelReport handleModalClose={handleModalClose} />
        </Box>
      </Modal>

      {/* <Dialog onClose={()=>{
        setireports({
          modal:false,
          name:"",
          ilink:""
        })
      }} aria-labelledby="customized-dialog-title" open={ireports?.modal} fullWidth maxWidth="lg" > 
        <Grid container justifyContent="space-between" alignItems="center" style={{padding:"5px 25px"}}>
          <Grid item>
            <Typography component="h2" variant="h6" style={{fontWeight:"600",color:"#01205C"}} > {ireports?.name}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={()=>{
              setireports({
                modal:false,
                name:"",
                ilink:""
              })
            }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Iframe src={ireports?.ilink} allowfullscreen height={600}/>
      </Dialog> */}
    </div>
  );
};

CustomDrawer.defaultProps = {
  list: [],
};
