import { Button, Grid, IconButton, TextareaAutosize, Typography } from "@material-ui/core";
import { Div, TextInput } from "qdm-component-library";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import withAppBar from "../../hoc/withAppBar";
import moment from "moment";
import { AlertContext, BackdropContext } from "../../context";
import { AlertProps, calculateAge, getCalculatedAge, makeName } from "../../utils";
import PatientCard from "../patientDashboard/components/patientCard";
import PatientCareTypeCard from "../patientDashboard/components/patientCareTypeCard";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import jwtDecode from "jwt-decode";
import { convertToWords } from "../../utils";
let styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "15px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    border: "1px solid #E0E0E0",
    backgroundColor: 'white'
  },

  errorborderStyle: {
    borderRadius: 8,
    borderColor: "#FF0000",
  },
};
function AdvancePayment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const [state, setState] = useState({
    advanceNo: "",
    date: "",
    amount: "",
    remarks: "",
  });
  // async function getData() {
  //   dispatch(actions.GET_ADVANCE_NO());
  // }
  // React.useEffect(() => {
  //   getData();
  // }, []);

  const handleChange = (key, value) => {
    if(key == "amount"){
      const d = value.split(".");
      if(value === "" || (0 < value && d[0]?.length <= 10 && (d[1]?.length ?? 0) <= 2)){
        setState({ ...state, [key]: value });
      } else {
        return;
      }
    }
    else{
      setState({ ...state, [key]: value });
    }
  };
  const save = async () => {

    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "saving the wallet .. Please wait",
    });
    let paylaod = [
      {
        _id: "",
        advdate: moment().unix(),
        patientid: location?.state?.patientid,
        amount: Number(state.amount),
        remarks: state?.remarks || "",
        advancepaymentdtls: [],
        amtineng: convertToWords(Number(state.amount)),
        amtinlocal: convertToWords(Number(state.amount), true),
      },
    ];
    if (state?.amount) {
      if(state?.amount <= 0){
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.warning,
          msg: "Please enter valid amount !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
      else
      try {
        let data = await dispatch(actions.SAVE_ADVANCE_PAYMENT(paylaod));
        if (!data?.payload?.data?.error && !data.payload.error) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "advance amount saved",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          navigate(AppRoutes.paymentCollections, {
            state: {
              patientid: location?.state?.patientid,
              _id: data?.payload?.data?.response?.[0]?.PatientAdvance?._id,
              isAdvancePayment: true,
            },
          });
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: data?.payload?.data?.message || "something went wrong !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
      } catch {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "something went wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
    } else {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Please enter the amount !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
  };
  const dashState = location?.state?.state;
  return (
    <Div
      id="patient_dashboard_parent_div"
      style={{ backgroundColor: "#F4F5F8" }}
    >
      <Div
        style={{
          display: "block",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 176px)"
        }}
      >
        <Grid
          id="patient_dashboard_patientCardGrid"
          container
          spacing={2}
          style={{
            padding: "10px 20px",
            backgroundImage: "linear-gradient(to right, #F7ECF4, #DAEAF8)",
          }}
        >
          <Grid item xs={6} id="patient_dashboard_grid_patientCard">
            <PatientCard
              permissionData={dashState?.permissionData}
              nameDetails={makeName(dashState?.personDetails?.name?.[0] || {})}
              parent_id={"patient_dashboard"}
              patientDetails={dashState?.patientDetails}
              newPatientDetails={dashState?.newPatientDetails}
              mrnVal={dashState?.personDetails?.alias}
              notOpen
              info={dashState?.loggedIndashState}
              // age={
              //   calculateAge(
              //     new Date(
              //       moment(dashState?.patientDetails.birthDay, "DD/MM/YYYY")
              //     )
              //   ) + " Yrs"
              // }
              age={getCalculatedAge(dashState?.newPatientDetails?.birthdate)
                // calculateAge(
                //   new Date(
                //     moment(
                //       dashState?.newPatientDetails?.birthdate,
                //       "DD/MM/YYYY"
                //     )
                //   )
                // ) + " Yrs"
              }
              // isAddBtn
              memberList={dashState?.choosemember}
              // addMember={toggleSideBar}
              active={dashState?.personDetails?.active}
              activeData={
                dashState?.personDetails?.isdeceased?.display &&
                dashState?.personDetails.active &&
                dashState?.personDetails?.isdeceased?.display
              }
              noedit={
                !(dashState?.permission?.write?.indexOf("modifyPatient") > -1)
              }
              // handleEdit={handleEdit}
              OutstandingData={dashState?.OutstandingData}

              walletData={dashState?.advanceAmount}
            />
          </Grid>
          {/* {state?.OutstandingData >= 0 && (
             
            )} */}

          <Grid
            item
            // xs={state?.OutstandingData >= 0 ? 3 : 6}
            xs={6}
            id="patient_dashboard_grid_patientTypeCard"
          >
            <PatientCareTypeCard
              parent_id={"Referral_Registration"}
              careList={[...(dashState?.fin_data || [])]}
              selected={dashState?.selectOption}
              padding
              isDetailed = {true}
            // onChange={(data) => changeState('selectOption', data)}
            />
          </Grid>
        </Grid>
        <div
          style={{
            height: "calc(100vh - 176px)", // 176px = 72px + 104px // 72 - topheader , 104 - PatientDashboardNavBar
            // overflow: "auto",
          }}
        >
          <Grid container spacing={3} alignItems="center" style={{ padding: "10px", backgroundColor: "linear-gradient(to right, rgb(247, 236, 244), rgb(218, 234, 248))" }}>
            <Grid container item xs={5} alignItems="center" style={{ padding: "10px 10px 10px 22px" }}>
              <IconButton onClick={() => navigate(-1)} ><ArrowBackIcon /></IconButton>
              <Typography style={{
                fontSize: "18px",
                color: "#001C3C",
                fontWeight: 600,
                borderRight: "1px solid #DFE5EB",
              }}>Advance Payment</Typography>
            </Grid>
            <Grid container spacing={3} xs={12} alignItems="center" style={{ margin: "10px", }} >
              <Grid item xs={3}>
                <TextInput
                  onChange={(e) => handleChange("amount", e.target.value)}
                  value={state?.amount}
                  label={`Amount (${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`}
                  placeholder="0"
                  labelStyle={styles.labelStyle}
                  style={styles.borderStyle}
                  hoverColor="#0071F2"
                  type="number"
                  disabled={false}
                  isRequired={true}
                />
              </Grid>
            </Grid>
            {/* <Grid item>
        <TextInput
          onChange={(e) => handleChange("date", e.target.value)}
          value={state?.date}
          label="Date"
          labelStyle={styles.labelStyle}
          style={styles.borderStyle}
          hoverColor="#0071F2"
          type="date"
          disabled={true}
          isRequired={true}
        />
      </Grid> */}
            <Grid container spacing={3} xs={12} alignItems="center" style={{ margin: "10px", }} >
              <Grid item xs={3}>
                <p
                  style={{
                    fontWeight: "500",
                    margin: "0px 0px 6px",
                    color: "rgb(111, 111, 111)",
                    fontSize: "15px",
                    fontFamily: "pc_regular !important",
                  }}
                >
                  Remarks
                </p>
                <TextareaAutosize
                  maxRows={8}
                  style={{
                    width: "100%",
                    height: "50px",
                    outline: "1px solid rgb(222, 229, 236) ",
                    border: "0px",
                    borderRadius: "8px",
                    padding: "5px",
                  }}
                  value={state?.remarks ?? ""}
                  onChange={(e) => handleChange("remarks", e.target.value)}
                  placeholder="Enter the remarks"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} xs={12} alignItems="center" style={{ margin: "10px", }} >
              <Grid container justifyContent="center" item xs={3}>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={save}
                >
                  save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Div>
    </Div >
  );
}

export default withAppBar(AdvancePayment);
