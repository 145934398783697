import React, { useContext, useEffect } from "react";
import actions from "../redux/actions";
import {
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Div,
  Text,
  Divider,
  TextArea,
  Button,
} from "qdm-component-library";
import { AlertProps } from "../utils";
import { UIColor } from "../themes/theme";
import { Selection } from "../components";
import { useDispatch } from "react-redux";
import withAllContexts from "../hoc/withAllContexts";
import { AlertContext } from "../context";

const styles = (theme) => ({
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 30,
    borderColor: "#E0E0E0",
  },
  select: {
    marginTop: 16,
    "& fieldset": {
      borderColor: "#E0E0E0",
      borderRadius: 8,
    },
    "& input": {
      height: 19,
    },
  },
});

const CommonSideBar = ({
  reasonType = "",
  headingName = "",
  cancellationType = {},
  Comp_SideBar_HandleSubmit = () => null,
  ...props
}) => {
  const alert = useContext(AlertContext)
  const dispatch = useDispatch();
  const [dd, setDd] = React.useState([]);
  const [state, setState] = React.useState({
    cacellationType: "single",
    reason: {},
    otherReason: "",
    
  });
  useEffect(() => {
    onLoad();
  }, [reasonType]);
  const Comp_SideBar_HandleInputChange=(key, value)=> {
    setState({ ...state, [key]: value });
  }
  const onLoad = async () => {
    const cancelReasonList = await dispatch(
      actions.GET_REASON_MASTERS({
        type: [
         reasonType || "CANCELREASON"
        ],
      })
    );
        
    setDd(cancelReasonList?.payload?.data?.[reasonType || "CANCELREASON"])
  };

  const { parent_id, classes } = props;
  return (
    <div id={`${parent_id}_cancelbook_parent_div`} style={{width:"100%"}}>
      <Container
        id={`${parent_id}_cancelbook_parent_container`}
        key={"0"}
        name="container"
        fluid={true}
        style={{
          padding: "20px",
        }}
      >
        <Text
          id={`${parent_id}_cancelbook_appointment_text`}
          key={"0"}
          className="pc_semibold"
          style={{ fontSize: 20, color: "#101010" }}
          name={headingName}
        ></Text>
        <Divider
          id={`${parent_id}_cancelbook_bottom_divider`}
          key={"1"}
          textWidth={"50"}
          textColor={"#000"}
          borderColor={"#f0f0f0"}
          borderWidth={"1"}
          orientation={"horizontal"}
          variant={"middle"}
          style={{ marginLeft: 0, marginRight: 0 }}
        ></Divider>

        {cancellationType?.isCancellationType && (
          <Div>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {cancellationType?.headingName || "Cancellation Type"}
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={state?.cacellationType}
                onChange={(e) =>
                  Comp_SideBar_HandleInputChange("cacellationType",e.target.value)
                }
              >
                {cancellationType.list.map((v) => (
                  <FormControlLabel
                    value={v?.value}
                    control={<Radio />}
                    label={v?.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Div>
        )}
        <Text
          id={`${parent_id}_cancelbook_reason_text`}
          className="pc_regular"
          name={`Reason for ${headingName}`}
          style={{ fontSize: "14px", color: "#6F6F6F" }}
        />

        <Div
          id={`${parent_id}_cancelbook_select_div`}
          key={"2"}
          className={classes.select}
        >
          <Selection
            showArrow
            options={dd}
            onChange={(e, v) => Comp_SideBar_HandleInputChange("reason",v)}
            value={state?.reason}
            placeholder="select..."
            labelStyle={styles.labelStyle}
            inLineStyles={{ ...styles.borderStyle }}
            hoverColor={UIColor.primaryColor.color}
          />

         { ["others", "Others", "other"].includes(state?.reason?.label)&&
      
         <TextArea
            id={`${parent_id}_cancelbook_reason_manually_textarea`}
            placeholder="Enter your reason manually"
            value={state?.otherReason || ""} // for both cancell and no show
            onChange={(e) => Comp_SideBar_HandleInputChange ("otherReason",e.target.value)}
            style={{
              marginTop: "20px",
              padding: 14,
              resize: "none",
              height: "14vh",
            }}
            maxLength={3000}
          />}
        </Div>
        <Button
          id={`${parent_id}_cancelbook_buttom`}
          key={"1"}
          type="button"
          className="pc_medium"
          variant="outlined"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "20px",
            fontSize: "14px",
            padding: "8px 16px",
            marginBottom: "10px",
            backgroundColor: UIColor?.primaryColor?.color,
            border: "none",
            borderRadius: 8,
          }}
          onClick={async () => {
            if (!state.reason?.label) {
              alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please select reason for cancellation",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              return;
            } else {
              Comp_SideBar_HandleSubmit(state);
              setState({cacellationType:"",reason:{},otherReason:""})
            }
          }}
        >
          Submit
        </Button>
      </Container>
    </div>
  );
};

export default withStyles(styles)(CommonSideBar);
