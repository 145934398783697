import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Radio,
  Divider,
  Tooltip,
  Pagination,
  Grid,
  useTheme,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import QueueCard from "../queueCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NewCard from "../newCard";
import { getUtcTime } from "../../utils/common/function";
import moment from "moment";

const QueueTabpanel = (props) => {
  const theme = useTheme();
  const [FilteredUsers, setFilteredUsers] = React.useState([]);
  const [state, setState] = React.useState();
  const [selectedTicket, setselectedTicket] = React.useState([]);
  const [SelectedTicketValue, setSelectedTicketValue] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dateType, setDateType] = React.useState("Today");
  const [duplicateCheck, setduplicateCheck] = React.useState("");

  const [currentCurPage, setCurrentCurPage] = React.useState(1);

  const currentPageSize = props?.itemPerPage || 5;
  // const totalFilteredItems = FilteredUsers?.length;

  const startIndex = Math.max(0, (currentCurPage - 1) * currentPageSize);

  const visibleCurrentItems = FilteredUsers?.slice(
    startIndex,
    startIndex + currentPageSize
  );

  if (visibleCurrentItems?.length === 0 && FilteredUsers?.length > 4) {
    setCurrentCurPage(1);
  }
  const handleCurrentPageChange = (event, value) => {
    setCurrentCurPage(value);
  };
  if (visibleCurrentItems?.length === 0 && startIndex > 0) {
    setCurrentCurPage(1);
  }
  const searchResult = (evt) => {
    const searchTerm = evt.target.value;
    setState(searchTerm);
    const lowercasedValue = evt.target.value.toLowerCase().trim();
    const filteredData = props?.data?.[0]?.data?.filter((item) => {
      const val = Object.keys(item).some((key) => {
        let checkVal = typeof item[key];
        if (checkVal === "string") {
          return item[key].toLowerCase().includes(lowercasedValue);
        }
      });
      return val;
    });
    setFilteredUsers(filteredData);
  };

  const setSelected = (tickets, value) => {
    setselectedTicket(tickets);
    setSelectedTicketValue(value || []);
  };

  const clearDateFilter = () => {
    setToDate(null);
    setFromDate(null);
    setDateType("Today");
    // let start = getUtcTime(moment().startOf("day"));
    // let end = getUtcTime(moment().endOf("day"));
    // let val = {
    //   fromDate: start,
    //   toDate: end,
    //   dateType: "Today",
    //   searchText: state,
    //   tab_id: props?.tab_id,
    // };
    //props?.filterClick(val);
    // setDialogOpen(false);
  };

  const multiActionClick = (action) => {
    props?.CardBtnClick(selectedTicket, action, SelectedTicketValue);
    setselectedTicket([]);
    setSelectedTicketValue([]);
  };

  const FilterRange = () => {
    let start = 0;
    let end = 0;
    let val = {};
    if (dateType === "Today") {
      start = getUtcTime(moment().startOf("day"));
      end = getUtcTime(moment().endOf("day"));
      val = {
        fromDate: start,
        toDate: end,
        dateType: dateType,
        searchText: state,
        tab_id: props?.tab_id,
      };
    } else if (dateType === "Tomorrow") {
      start = getUtcTime(moment().add(1, "day").startOf("day"));
      end = getUtcTime(moment().add(1, "day").endOf("day"));
      val = {
        fromDate: start,
        toDate: end,
        dateType: dateType,
        searchText: state,
        tab_id: props?.tab_id,
      };
    } else if (dateType === "Next_Week") {
      start = getUtcTime(moment(new Date()).weekday(7).startOf("day"));
      end = getUtcTime(moment(new Date()).weekday(13).endOf("day"));
      val = {
        fromDate: start,
        toDate: end,
        dateType: dateType,
        searchText: state,
        tab_id: props?.tab_id,
      };
    } else if (dateType === "single_date") {
      if (!fromDate) {
        props?.PopupAlert("error", "date cannot be Empty");
      } else {
        start = getUtcTime(moment(fromDate).startOf("day"));
        end = getUtcTime(moment(fromDate).endOf("day"));
        val = {
          fromDate: start,
          toDate: end,
          dateType: dateType,
          searchText: state,
          tab_id: props?.tab_id,
        };
      }
    } else if (dateType === "range_wise") {
      if (!fromDate || !toDate) {
        props?.PopupAlert("error", "From date or to date cannot be Empty");
      } else {
        start = getUtcTime(moment(fromDate).startOf("day"));
        end = getUtcTime(moment(toDate).endOf("day"));
          val = {
          fromDate: start,
          toDate: end,
          dateType: dateType,
          searchText: state,
          tab_id: props?.tab_id,
        };
      }
    }

    props.filterClick(val);
    setDialogOpen(false);
    // setDateCheck(!dateCheck);
  };

  React.useEffect(() => {
    
    if (props?.data?.[0]?.data)
      setFilteredUsers(props?.data?.[0]?.data ?? []);
  }, [props?.data?.[0]?.data]);
  return (
    <div id={`${1}_sidemenu_search_input_div`} style={{ marginTop: "10px" }}>
      <Grid
        id="dashboard_parent_row"
        container
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid id="dashboard_parent_layout_col" xs={10} item>
          <TextField
            id={`${"statesname"}_sidemenu_search_input_textinput`}
            value={state}
            onChange={searchResult}
            size="small"
            variant={"outlined"}
            label=""
            placeholder="Search"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor:
                    props?.queueColor || theme.palette.secondary.main,
                },
              },
            }}
          />
        </Grid>
        <Grid id="dashboard_parent_layout_col" xs={1} item>
          <div style={{ padding: "3px 15px" }}>
            <Tooltip title="Filter" placement="right">
              <IconButton
                size="small"
                style={{
                  borderRadius: "8px",
                  padding: "8px",
                  backgroundColor:
                    props?.queueColor || theme.palette.secondary.main,
                }}
                onClick={() => setDialogOpen((pre) => !pre)}
              >
                <FilterListIcon style={{ color: theme.palette.common.white }} />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <Grid
        id="dashboard_parent_row"
        style={{
          display: "flex",
          height: "auto",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          {selectedTicket?.length > 0 &&
            FilteredUsers?.length > 0 &&
            FilteredUsers?.[0]?.action?.map((li) => {
              return (
                <Button
                  className={"reassign"}
                  style={{
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    multiActionClick(li);
                  }}
                  variant={li.type === "action" ? "contained" : "outlined"}
                  size="small"
                >
                  {li?.action}
                </Button>
              );
            })}
        </div>
      </Grid>
      {props?.isSelQueueDatas ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 250,
            marginLeft: 800,
          }}
        >
          <CircularProgress style={{ color: "#EC6A49" }} size={60} />
        </div>
      ) : (
        <div
          style={{
            height: "calc(100vh - 17rem)",
            // overflow: "auto",
          }}
        >
          <Grid container mt={1}>
            <Grid
              item
              container
              xs={0.5}
              //height={"calc(100vh - 7rem)"}
              alignItems={"center"}
            >
              <IconButton
                onClick={() => {
                  setCurrentCurPage(currentCurPage - 1);
                }}
                disabled={!(currentCurPage > 1)}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Grid>
            <Grid item container xs={11} spacing={1} height={0}>
              {visibleCurrentItems?.map((l, i) => {
                return (
                  <>
                    {props?.selectedQueue?.queueId || l?.queueid === "queue/10022" ? (
                      <Grid item xs={props?.colValue ? props?.colValue : 12}>
                        <NewCard
                          patient={l}
                          actionButtonClick={props?.CardBtnClick}
                        />
                      </Grid>
                    ) : (
                      // props?.loadCustomCard(l)
                      <Grid item xs={12}>
                        <QueueCard
                          parent_id={"dashboard_queuecard_pending" + i}
                          id={l?.token}
                          CardBtnClick={props?.CardBtnClick}
                          type="All"
                          // showBtn={l?.showBringtoTop}
                          fieldList={l?.fieldList}
                          avatar={l?.avatar}
                          isMultiple={props?.isMultiple}
                          isDuplicateCheck={props?.isDuplicateCheck}
                          name={l?.name}
                          isEmergency={l.isEmergency}
                          totalData={l}
                          action={l.action}
                          ticketId={l?.ticketId}
                          personid={l.personid}
                          //   role_={l.type}
                          //   queue={l?.queueMetaData?.queuename?.queuename}
                          //   queueId={l?.queueid}
                          selectedTicket={selectedTicket}
                          setSelected={setSelected}
                          //   statesid={l?.statesid}
                          FilteredUsers={FilteredUsers}
                          SelectedTicketValue={SelectedTicketValue}
                          //   sampleCollectOpen={sampleCollectOpen}
                          //   queueList={props?.queueList}
                          setduplicateCheck={setduplicateCheck}
                          duplicateCheck={duplicateCheck}
                          PopupAlert={props?.PopupAlert}
                          dataName={props?.dataName}
                          avatarType={props?.avatarType}
                          field1={l?.field1}
                          field2={l?.field2}
                          field3={l?.field3}
                          field4={l?.field4}
                          field5={l?.field5}
                          field6={l?.field56}
                          field7={l?.field7}
                          field8={l?.field8}
                          field9={l?.field9}
                          field10={l?.field10}
                          field11={l?.field11}
                          field12={l?.field12}
                          date={l?.date}
                        />
                      </Grid>
                    )}
                  </>
                );
              })}
            </Grid>
            <Grid
              item
              container
              xs={0.5}
              justifyContent={"flex-end"}
              height={"calc(100vh - 17rem)"}
              alignItems={"center"}
            >
              <IconButton
                onClick={() => {
                  setCurrentCurPage(currentCurPage + 1);
                }}
                disabled={
                  !(
                    currentCurPage <
                    Math.ceil(FilteredUsers?.length / currentPageSize)
                  )
                }
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )}
      {/* <Grid container justifyContent="center" style={{ padding: "12px" }}>
        <Pagination
          count={Math.ceil(FilteredUsers.length / currentPageSize)}
          page={currentCurPage}
          onChange={handleCurrentPageChange}
          showFirstButton
          showLastButton
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root.Mui-selected": {
              color: "white", // Change active page number color
              backgroundColor:
                props?.queueColor || theme.palette.secondary.main,
            },
          }}
        />
      </Grid> */}

      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        onClose={() => setDialogOpen((pre) => !pre)}
      >
        <div>
          <DialogTitle id="alert-dialog-slide-title">
            <Typography variant="body1">FILTER BY DATE</Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item container lg={12} sm={12} md={12}>
                <Grid item xs={4}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("Today")}
                  >
                    <Radio
                      checked={dateType === "Today"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="Today"
                      sx={{
                        "&.MuiRadio-root": {
                          color: "gray",
                        },
                        "&, &.Mui-checked": {
                          color:
                            props?.queueColor || theme.palette.secondary.main,
                        },
                      }}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Today</Typography>
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("Tomorrow")}
                  >
                    <Radio
                      checked={dateType === "Tomorrow"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="Tomorrow"
                      sx={{
                        "&.MuiRadio-root": {
                          color: "#7f7f7f",
                        },
                        "&, &.Mui-checked": {
                          color:
                            props?.queueColor || theme.palette.secondary.main,
                        },
                      }}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Tomorrow</Typography>
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("Next_Week")}
                  >
                    <Radio
                      checked={dateType === "Next_Week"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="Next_Week"
                      sx={{
                        "&.MuiRadio-root": {
                          color: "#7f7f7f",
                        },
                        "&, &.Mui-checked": {
                          color:
                            props?.queueColor || theme.palette.secondary.main,
                        },
                      }}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Next Week</Typography>
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("single_date")}
                  >
                    <Radio
                      checked={dateType === "single_date"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="single_date"
                      sx={{
                        "&.MuiRadio-root": {
                          color: "#7f7f7f",
                        },
                        "&, &.Mui-checked": {
                          color:
                            props?.queueColor || theme.palette.secondary.main,
                        },
                      }}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Single Date</Typography>
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("range_wise")}
                  >
                    <Radio
                      checked={dateType === "range_wise"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="range_wise"
                      sx={{
                        "&.MuiRadio-root": {
                          color: "#7f7f7f",
                        },
                        "&, &.Mui-checked": {
                          color:
                            props?.queueColor || theme.palette.secondary.main,
                        },
                      }}
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Range Wise</Typography>
                  </span>
                </Grid>
              </Grid>
              {(dateType === "range_wise" || dateType === "single_date") && (
                <Grid item lg={6} sm={6} md={6}>
                  <Typography>
                    {dateType === "range_wise" ? "From" : "Custom Date"}
                  </Typography>
                  <TextField
                    value={fromDate}
                    type="date"
                    onChange={(e) => setFromDate(e.target.value)}
                    // defaultValue="2017-05-24"
                    className={"pc_regular"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor:
                            props?.queueColor || theme.palette.secondary.main,
                        },
                      },
                    }}
                  />
                </Grid>
              )}
              {dateType === "range_wise" && (
                <Grid item lg={6} sm={6} md={6}>
                  <Typography>To</Typography>
                  <TextField
                    value={toDate}
                    type="date"
                    onChange={(e) => setToDate(e.target.value)}
                    // defaultValue="2017-05-24"
                    className={"pc_regular"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor:
                            props?.queueColor || theme.palette.secondary.main,
                        },
                      },
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              onClick={() => setDialogOpen(false)}
              sx={{
                borderColor: props?.queueColor || theme.palette.secondary.main,
                color: props?.queueColor || theme.palette.secondary.main,
                "&:hover": {
                  borderColor:
                    props?.queueColor || theme.palette.secondary.main,
                },
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            {(fromDate !== null || toDate !== null) && (
              <Button
                onClick={() => clearDateFilter()}
                sx={{
                  borderColor:
                    props?.queueColor || theme.palette.secondary.main,
                  color: props?.queueColor || theme.palette.secondary.main,
                  "&:hover": {
                    borderColor:
                      props?.queueColor || theme.palette.secondary.main,
                  },
                }}
                variant="outlined"
              >
                Reset
              </Button>
            )}
            <Button
              onClick={() => FilterRange()}
              sx={{
                backgroundColor:
                  props?.queueColor || theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor:
                    props?.queueColor || theme.palette.secondary.main,
                },
              }}
              variant="contained"
              disabled={dateType === "range_wise" && (!toDate || !fromDate)}
            >
              Apply
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
export default QueueTabpanel;
