import React from "react";
import QueueTabpanel from "./queueTabpanel";
import QueueSelector from "./queueTabList/queueSelector";
import {
  Grid,
  Typography,
  useTheme,
  Paper,
  Skeleton,
} from "@mui/material";
import { UIColor } from "../../../../themes/theme";
import { Tab ,Tabpanel } from "qdm-component-library";
function setTabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function WorkFlowComponent({
  queueList = [],
  selectQueue = "",
  HandleQueueSelect = () => null,
  queueColor = "", //FIXME -
  showTabs = true,
  tabsColor = "", //FIXME
  tabList = [],
  data = [],
  loadCustomCard = () => <></>,
  CardBtnClick = () => null,
  PopupAlert = () => null, //FIXME -
  filterClick = () => null,
  dataName = "", //FIXME
  colValue = "",
  avatarType = "",
  itemPerPage = 0,
  isgetQueueListData = false,
  isSelQueueDatas = false,
  selectedQueue = "",
  tabValue = 0,
  handleTabChange = () => null,
}) {
  const theme = useTheme();

  return (
    <div>
      <QueueSelector
        queueList={queueList}
        selectedQueue={selectedQueue}
        queueColor={queueColor}
        isgetQueueListData={isgetQueueListData}
        HandleQueueSelect={HandleQueueSelect}
      />
      {isgetQueueListData ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          {/* <CircularProgress style={{ color: "#EC6A49" }} size={20} /> */}
          <Skeleton variant="rounded" width={1650} height={40} />
        </div>
      ) : (
        <Paper sx={{ margin: "10px 20px 0 20px", marginTop: "10px" }} elevation={0} >
             <Tab
                id="sidemenu_parent_tab"
                activetabs={tabValue}
                variant="contained"
                borderColor={UIColor.differentiationBackground.color}
                size="small"
                activeColor={UIColor.secondaryColor.color}
                labelStyle={{
                  fontFamily: "pc_medium !important",
                  fontSize: 14,
                  border: "none",
                  padding: "6px 8px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                backgroundColor={"#FFECE8"}
                onChange={handleTabChange}
              >
                {tabList?.length > 0 &&
                  tabList?.map((v, i) => {

                    let svg = v?.icon;
                    let blob = new Blob([svg], { type: "image/svg+xml" });
                    let url = URL.createObjectURL(blob);
                    return (
                      <Tabpanel
                        title={v?.statesname}
                        id={`${v?.statesname}_sidemenu_tab_tabpanel`}
                        //icon={<CloseIcon />}
                        label={v?.value}
                        number={
                          <Typography variant="subtitle" fontSize={9}>{v?.data?.length > 0 ? `${v?.data?.length}` : "0"}</Typography>
                        }
                        inLineStyles={{
                          border: "none",
                          whiteSpace: "nowrap",
                          overflowY: "scroll",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <QueueTabpanel
                          data={showTabs ? data?.filter((d) => d.id === v.id) : data}
                          tab_id={v.id}
                          isMultiple={v.isMultiple}
                          isDuplicateCheck={v.isDuplicateCheck}
                          CardBtnClick={CardBtnClick}
                          PopupAlert={PopupAlert}
                          filterClick={filterClick}
                          dataName={dataName}
                          loadCustomCard={loadCustomCard}
                          colValue={colValue}
                          avatarType={avatarType}
                          itemPerPage={itemPerPage}
                          queueColor={queueColor}
                          selectedQueue={selectedQueue}
                          isSelQueueDatas={isSelQueueDatas}
                        />
                      </Tabpanel>
                    );
                  })}
             </Tab>
        </Paper>
      )}
    </div>
  );
}

export default WorkFlowComponent;
