import React from "react";
import {
  Typography,
  Button,
  Checkbox,
  Paper,
  Grid,
  Avatar,
  Badge,
  useTheme,
} from "@mui/material";
import { severity } from "../../utils/common/constentValue";
import { styled } from "@mui/material/styles";

const QueueCard = (props) => {
  const theme = useTheme();
  const {
    fieldList,
    avatar,
    isEmergency,
    name,
    queue,
    parent_id,
    isMultiple,
    totalData,
    action,
    ticketId,
    queueId,
    statesid,
    FilteredUsers,
    CardBtnClick,
    avatarType,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8, field9, field10, field11, field12, field13,date
  } = props;
  const { classes } = props;
  const handleChange = (event, ticketId) => {
    if (event.target.checked) {
      let Arr = props?.selectedTicket ? [...props?.selectedTicket] : [];
      Arr.push(ticketId);
      let ArrayValue = props?.SelectedTicketValue
        ? [...props?.SelectedTicketValue]
        : [];
      let value = FilteredUsers.filter((li) => li?.ticketId === ticketId);
      if (
        ArrayValue.length > 0 &&
        (!props?.isDuplicateCheck ||
          props?.duplicateCheck === value?.[0]?.personid)
      ) {
        ArrayValue.push(value?.[0]);
        props?.setSelected(Arr, ArrayValue);
      } else if (ArrayValue.length === 0) {
        ArrayValue.push(value?.[0]);
        props?.setSelected(Arr, ArrayValue);
        props?.setduplicateCheck(value?.[0]?.personid);
      } else {
        props?.PopupAlert(
          severity.error,
          "Kindly select same " +
          (props?.dataName ? props?.dataName.toLowerCase() : "Item"),
          
        );
      }
    } else {
      let Arr = props?.selectedTicket.filter((item) => item !== ticketId);
      let value = props?.SelectedTicketValue.filter(
        (li) => li?.ticketId !== ticketId
      );
      props?.setSelected(Arr, value);
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.main,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const actionClick = (ticketId, TotActionData, data) => {
    let ticketIds = [];
    let datas = [];
    ticketIds.push(ticketId);
    datas.push(TotActionData?.action === process.env.REACT_APP_LABORDER_SPECIMENTCOLLECTED ? data?.totalData : data);
    CardBtnClick( ticketIds, TotActionData, datas);
  };
  const getEpochTIme = (date) => {
    const unixMilliSeconds = date * 1000;
    const myDate = new Date(unixMilliSeconds);
    return myDate.toLocaleString();
  };
  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{
        margin: "10px 5px 0 5px",
      }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        p={2}
      >
        <Grid item>
          <Grid container alignItems={"center"} gap={2}>
            <Grid item>
              {isMultiple && (
                <Checkbox
                  id={ticketId}
                  checked={props?.selectedTicket?.includes(ticketId)}
                  onChange={(e) => handleChange(e, ticketId)}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                />
              )}
            </Grid>
            <Grid item>
              {isEmergency ? (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    src={field9}
                    variant={avatarType}
                    sx={{
                      width: 56,
                      height: 56,
                    }}
                  >
                    <Typography color={"primary"}>{name?.[0]}</Typography>
                  </Avatar>
                </StyledBadge>
              ) : (
                <Avatar
                  src={field9}
                  variant={avatarType}
                  sx={{
                    width: 56,
                    height: 56,
                    backgroundColor: theme.palette.grey[200],
                  }}
                >
                  <Typography color={"primary"}>{name?.[0]}</Typography>
                </Avatar>
              )}
            </Grid>
            <Grid item pb={1}>
              <Grid item>
                <Typography variant="body1">
                  {field1 ? field1 : "Name is Undefined"}
                </Typography>
              </Grid>
              <Grid item container gap={1}>
                {field2 && <Grid item>
                  <Typography variant="caption">{field2}</Typography>&nbsp;|
                </Grid>}
                {field3 && <Grid item>
                  <Typography variant="caption">{field3}</Typography>&nbsp;|
                </Grid>}
                {field4 && <Grid item>
                  <Typography variant="caption">{field4}</Typography>&nbsp;|
                </Grid>}
                {field5 && <Grid item>
                  <Typography variant="caption">{field5}</Typography>&nbsp;|
                </Grid>}

                {field6 && <Grid item>
                  <Typography variant="caption">{field6}</Typography>&nbsp;|
                </Grid>}
                {field7 && <Grid item>
                  <Typography variant="caption">{field7}</Typography>&nbsp;|
                </Grid>}
                {field8 && <Grid item>
                  <Typography variant="caption">{field8}</Typography>&nbsp;|
                </Grid>}              

                {field10 && <Grid item>
                  <Typography variant="caption">{field10}</Typography>&nbsp;|
                </Grid>}

                {field11 && <Grid item>
                  <Typography variant="caption">{field11}</Typography>&nbsp;|
                </Grid>}
                {field12 && <Grid item>
                  <Typography variant="caption">{field12}</Typography>&nbsp;|
                </Grid>}
                {date && <Grid item>
                  <Typography variant="caption">{date ? getEpochTIme(date) : "" }</Typography>
                </Grid>}
                {/* {date1 && <Grid item>
                  <Typography variant="caption">{date1 !== "" ? "Administered on " + getEpochTIme(date1) : ""}</Typography>
                </Grid>} */}

                {totalData?.role ? (
                  <Grid item>
                    <Typography variant="caption">
                      {" "}
                      {totalData?.role ? totalData?.role : ""}
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container gap={1}>
            {props?.selectedTicket?.length === 0 &&
              action?.map((l, i) => {
                return (
                  <Button
                    variant={l?.type === "action" ? "contained" : "outlined"}
                    onClick={() => {
                      actionClick(ticketId, l, props);
                    }}
                    size="small"
                  >
                    {l.action}
                  </Button>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default QueueCard;
